import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {getCurrentDateTime} from "../../../utils/utilsDate";
import {refreshPage, checkValue, navToAdminPanel, navToVehiclesPage, checkRequestStatus, navToViewVehicle, isElectricMotorVehicle} from "../../../utils/utilsGeneral";
import {IVehicle} from "../../../redux-data/vehicle/vehicleTypes";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {AutoComplete} from "../../../components/MainPage/AutoComplete/AutoComplete";
import RegCities from "../../../components/Forms/common/RegCities";
import SwitchComponent from "react-switch";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";
import VehicleCategoryInfo from "./VehicleCategoryInfo";
import VehicleTypeInfo from "./VehicleTypeInfo";

const EditVehiclePage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, stateNumber, vehicleID} = useParams<{
        id: string;
        externalID: string;
        stateNumber: string;
        vehicleID: string;
    }>();
    const [editVehicleData, setVehicleDataEdited] = useState<IVehicle>({
        engineVolume: 0,
        year: 0,
        vin: "",
        category: "",
        kind: "",
        brand: "Назва - Skoda",
        model: "Octavia A7",
        modelText: "",
        stateNumber: "",
        dontHaveVIN: false,
        lastModified: "",
        autoColor: "",
        isForeignReg: false,
        grossWeight: 0,
        curbWeight: 0,
        seatingCapacity: 0,
        electricMotorPower: 0,
        registrationPlace: {
            id: 0,
            placeCode: 0,
            zone: 0, 
            nameFull: "", 
            country: "",
            name: "",
            nameRus: "", 
            nameFullRus: "", 
            cdbMtibu: false,
            cdbMtibuCode: 0,
            lastModified: ""
        }
    });
    const [vehicleErrorMsg, setVehicleErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);
    const editEngineVolumeInput = useRef<HTMLInputElement>(null);
    const editYearInput = useRef<HTMLInputElement>(null);
    const editVinInput = useRef<HTMLInputElement>(null);
    const editCategoryInput = useRef<HTMLInputElement>(null);
    const editKindInput = useRef<HTMLInputElement>(null);
    const editBrandInput = useRef<HTMLInputElement>(null);
    const editModelInput = useRef<HTMLInputElement>(null);
    const editModelTextInput = useRef<HTMLInputElement>(null);
    const editStateNumberInput = useRef<HTMLInputElement>(null);
    const editAutoColorInput = useRef<HTMLInputElement>(null);
    const editRegPlaceIDInput = useRef<HTMLInputElement>(null);
    const editRegPlaceCodeInput = useRef<HTMLInputElement>(null);
    const editVehicleRegLocationInput = useRef<HTMLInputElement>(null);
    const editGrossWeightInput = useRef<HTMLInputElement>(null);
    const editCurbWeightInput = useRef<HTMLInputElement>(null);
    const editSeatingCapacityInput = useRef<HTMLInputElement>(null);
    const editElectricMotorPowerInput = useRef<HTMLInputElement>(null);
    const [city, setCity] = useState("");
    const [touch, setTouch] = useState(false);
    const [regPlaceID, setRegPlaceID] = useState("");
    const [regPlaceCode, setRegPlaceCode] = useState("");
    const [editIsForeign, setForeignEdited] = useState(false);

    useEffect(() => {
        fetchVehicle();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const fetchVehicle = async () => {
        try {
            const vehicleData = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(stateNumber!.toUpperCase())}/${encodeURIComponent(vehicleID!)}`)
            const regPlaceData = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(vehicleData.data[0].reg_place_id)}/${encodeURIComponent(vehicleData.data[0].reg_place_code)}`);
            const dbVehicleData = vehicleData.data[0];
            const dbRegPlaceData = regPlaceData.data[0];

            if (dbVehicleData) {
                setVehicleDataEdited((prev) => ({...prev, engineVolume: dbVehicleData.engine_volume, year: dbVehicleData.year, vin: dbVehicleData.vin, category: dbVehicleData.category, kind: dbVehicleData.kind, brand: dbVehicleData.brand, 
                                                 model: dbVehicleData.model, modelText: dbVehicleData.model_text, stateNumber: dbVehicleData.state_number, dontHaveVIN: dbVehicleData.dont_have_vin, lastModified: dbVehicleData.last_modified, 
                                                 autoColor: dbVehicleData.auto_color, isForeignReg: Boolean(dbVehicleData.is_foreign), grossWeight: dbVehicleData.gross_weight, curbWeight: dbVehicleData.curb_weight, 
                                                 seatingCapacity: dbVehicleData.seating_capacity, electricMotorPower: dbVehicleData.electric_motor_power}));
                setForeignEdited(Boolean(dbVehicleData.is_foreign));
                setVehicleErrorMsg("");
            } else {
                setVehicleDataEdited((prev) => ({...prev, engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: ""}));
                setForeignEdited(false);
                setVehicleErrorMsg("Немає такого транспортного засобу. Ви можете додати ТЗ через панель адміністратора.");
            }

            if (dbVehicleData && dbVehicleData.reg_place_id && dbVehicleData.reg_place_code && dbRegPlaceData) {
                setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                    id: dbRegPlaceData.id,
                    placeCode: dbRegPlaceData.place_code,
                    zone: dbRegPlaceData.zone, 
                    nameFull: dbRegPlaceData.name_full, 
                    country: dbRegPlaceData.country,
                    name: dbRegPlaceData.name,
                    nameRus: dbRegPlaceData.name_rus,
                    nameFullRus: dbRegPlaceData.full_rus, 
                    cdbMtibu: dbRegPlaceData.cdb_mtibu,
                    cdbMtibuCode: dbRegPlaceData.cdb_mtibu_code,
                    lastModified: dbRegPlaceData.last_modified
                }}));
                setCity(dbRegPlaceData.name_full);
                setRegPlaceID(dbRegPlaceData.id);
                setRegPlaceCode(dbRegPlaceData.place_code);
            } else {
                setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                    id: 0,
                    placeCode: 0,
                    zone: 0, 
                    nameFull: "", 
                    country: "",
                    name: "",
                    nameRus: "", 
                    nameFullRus: "", 
                    cdbMtibu: false,
                    cdbMtibuCode: 0,
                    lastModified: ""
                }}));
                setCity("");
                setRegPlaceID("");
                setRegPlaceCode("");
            }
        } catch (error: any) {
            setVehicleDataEdited({engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: "", isForeignReg: false, 
                                  grossWeight: 0, curbWeight: 0, seatingCapacity: 0, electricMotorPower: 0, registrationPlace: {
                                    id: 0,
                                    placeCode: 0,
                                    zone: 0, 
                                    nameFull: "", 
                                    country: "",
                                    name: "",
                                    nameRus: "", 
                                    nameFullRus: "", 
                                    cdbMtibu: false,
                                    cdbMtibuCode: 0,
                                    lastModified: ""
                                }});
            setCity("");
            setRegPlaceID("");
            setRegPlaceCode("");
            setVehicleErrorMsg("Помилка БД! Не вдалося завантажити дані транспортного засобу.");
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
        setVehicleDataEdited({engineVolume: 0, year: 0, vin: "", category: "", kind: "", brand: "Назва - Skoda", model: "Octavia A7", modelText: "", stateNumber: "", dontHaveVIN: false, lastModified: "", autoColor: "", isForeignReg: false, 
                              grossWeight: 0, curbWeight: 0, seatingCapacity: 0, electricMotorPower: 0, registrationPlace: {
                                id: 0,
                                placeCode: 0,
                                zone: 0, 
                                nameFull: "", 
                                country: "",
                                name: "",
                                nameRus: "", 
                                nameFullRus: "", 
                                cdbMtibu: false,
                                cdbMtibuCode: 0,
                                lastModified: ""
                            }});
        setCity("");
        setRegPlaceID("");
        setRegPlaceCode("");
        setForeignEdited(false);

        if (editEngineVolumeInput.current) {
            editEngineVolumeInput.current.value = "";
        }

        if (editYearInput.current) {
            editYearInput.current.value = "";
        }

        if (editVinInput.current) {
            editVinInput.current.value = "";
        }

        if (editCategoryInput.current) {
            editCategoryInput.current.value = "";
        }

        if (editKindInput.current) {
            editKindInput.current.value = "";
        }

        if (editBrandInput.current) {
            editBrandInput.current.value = "";
        }

        if (editModelInput.current) {
            editModelInput.current.value = "";
        }

        if (editModelTextInput.current) {
            editModelTextInput.current.value = "";
        }

        if (editStateNumberInput.current) {
            editStateNumberInput.current.value = "";
        }

        if (editAutoColorInput.current) {
            editAutoColorInput.current.value = "";
        }

        if (editRegPlaceIDInput.current) {
            editRegPlaceIDInput.current.value = "";
        }

        if (editRegPlaceCodeInput.current) {
            editRegPlaceCodeInput.current.value = "";
        }

        if (editGrossWeightInput.current) {
            editGrossWeightInput.current.value = "";   
        }

        if (editCurbWeightInput.current) {
            editCurbWeightInput.current.value = "";
        }

        if (editSeatingCapacityInput.current) {
            editSeatingCapacityInput.current.value = "";
        }

        if (editElectricMotorPowerInput.current) {
            editElectricMotorPowerInput.current.value = "";
        }
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editVehicleData.engineVolume.toString().trim().length > 0 && editVehicleData.year.toString().trim().length > 0 && editVehicleData.category.trim().length > 0 && editVehicleData.kind.trim().length > 0 && editVehicleData.brand.trim().length > 0 && 
            editVehicleData.model!.trim().length > 0 && editVehicleData.modelText!.trim().length > 0 && editVehicleData.stateNumber.trim().length > 0 && editVehicleData.lastModified.trim().length > 0) {
            try {
                const res = await dbAxiosInstance.put(`/edit-vehicle/${encodeURIComponent(stateNumber!.toUpperCase())}/${encodeURIComponent(vehicleID!)}`, editVehicleData);
                const editStatus = res.status;
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/vehicle/${stateNumber!.toLowerCase()}/${vehicleID}/item/edited`, {
                    state: {
                        isSuccess: checkRequestStatus(editStatus),
                        status: editStatus,
                        successMsg: res.data,
                        errorMsg: ""
                    }
                });
                setVehicleErrorMsg("");
                setSuccessNotificationHidden(false);
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setVehicleErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setVehicleErrorMsg("Будь ласка, заповніть усі поля, щоб оновити транспортний засіб!");
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const editEngineVolumeChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, engineVolume: checkValue(event.target.value)}));
    };

    const editYearChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, year: checkValue(event.target.value)}));
    };

    const editVINChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, vin: checkValue(event.target.value), dontHaveVIN: event.target.value.length <= 0 ? true : false}));
    };

    const editCategoryChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, category: checkValue(event.target.value)}));
    };

    const editKindChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, kind: checkValue(event.target.value)}));
    };

    const editBrandChange = (event) => {
        const vehicleBrand = checkValue(event.target.value);
        setVehicleDataEdited((prev) => ({...prev, brand: vehicleBrand, modelText: `${vehicleBrand} ${editVehicleData.model}`}));
    };

    const editModelChange = (event) => {
        const vehicleModel = checkValue(event.target.value);
        setVehicleDataEdited((prev) => ({...prev, model: vehicleModel, modelText: `${editVehicleData.brand} ${vehicleModel}`}));
    };

    const editStateNumberChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, stateNumber: checkValue(event.target.value), lastModified: getCurrentDateTime()}));
    };

    const editDontHaveVINChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, dontHaveVIN: event.target.checked}));
    };

    const editAutoColorChange = (event) => {
        event.target.value = event.target.value.toUpperCase();
        setVehicleDataEdited((prev) => ({...prev, autoColor: checkValue(event.target.value)}));
    };

    const handleEditVechileRegPlace = (event, {newValue, method}) => {
        setCity(newValue);
        
        if (newValue !== "") {
            setTouch(true);
        } else {
            setTouch(false);
        }
    };

    const handleEditVechileRegContentBlur = (event, {newValue, method}) => {
        setTouch(true);
    };

    const setVechicleRegCity = async (event) => {
        const dataSuggestion = event.target.dataset.suggestion;

        if (editVehicleRegLocationInput.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
            editVehicleRegLocationInput.current.value = event.target.value;
            setCity(editVehicleRegLocationInput.current.value);
            setTouch(true);
            const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
            const regPlace = place.data[0];
            setVehicleRegLocation(regPlace);
        } else {
            setCity("");
            setTouch(false);
        }
    };

    const handleEditRegPlaceSuggestion = (suggestion) => {
        setVehicleRegLocation(suggestion);
    };

    const setVehicleRegLocation = (location) => {
        if (location) {
            setRegPlaceID(location.id);
            setRegPlaceCode(location.place_code);
            setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                id: location.id,
                placeCode: location.place_code,
                zone: location.zone, 
                nameFull: location.name_full, 
                country: location.country,
                name: location.name,
                nameRus: location.name_rus, 
                nameFullRus: location.name_full_rus, 
                cdbMtibu: location.cdb_mtibu,
                cdbMtibuCode: location.cdb_mtibu_code,
                lastModified: location.last_modified
            }}));
        } else {
            setRegPlaceID("");
            setRegPlaceCode("");
            setVehicleDataEdited((prev) => ({...prev, registrationPlace: {
                id: 0,
                placeCode: 0,
                zone: 0, 
                nameFull: "", 
                country: "",
                name: "",
                nameRus: "", 
                nameFullRus: "", 
                cdbMtibu: false,
                cdbMtibuCode: 0,
                lastModified: ""
            }}));
        }
    };

    const handleEditForeignStateChange = (state) => {
        setForeignEdited(state);
        setVehicleDataEdited((prev) => ({...prev, isForeignReg: state}));
    };

    const handleEditGrossWeightChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, grossWeight: checkValue(event.target.value)}));
    };

    const handleEditCurbWeightChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, curbWeight: checkValue(event.target.value)}));
    };

    const handleEditSeatingCapacityChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, seatingCapacity: checkValue(event.target.value)}));
    };

    const editElectricMotorPowerChange = (event) => {
        setVehicleDataEdited((prev) => ({...prev, electricMotorPower: checkValue(event.target.value)}));
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати транспортний засіб &#8212; {stateNumber?.toUpperCase()}</h1>
                        <div className="edit-dashboard-item-form">
                            <CustomInput type="number" name="engineVolume" label="Редагувати об'єм двигуна (см3)" placeholder="Редагувати об'єм двигуна - 1598" onDataChange={editEngineVolumeChange} ref={editEngineVolumeInput} value={editVehicleData.engineVolume.toString()} />
                            <CustomInput type="number" name="year" label="Редагувати рік" placeholder="Редагувати рік - 2021" onDataChange={editYearChange} ref={editYearInput} value={editVehicleData.year.toString()} />
                            <CustomInput type="text" name="vin" label="Редагувати VIN код" placeholder="Редагувати VIN код - TVBAN5NE7LB018349" onDataChange={editVINChange} ref={editVinInput} value={editVehicleData.vin} />
                            <CustomInput type="text" name="category" label="Редагувати категорію" placeholder="Редагувати категорію - B1, B2..." onDataChange={editCategoryChange} ref={editCategoryInput} value={editVehicleData.category} />
                            <VehicleCategoryInfo />
                            {isElectricMotorVehicle(editVehicleData.category) && 
                                <CustomInput 
                                    type="number" 
                                    name="electricMotorPower" 
                                    label="Редагувати потужність електродвигуна (кВт)" 
                                    placeholder="Редагувати потужність електродвигуна (кВт) - 600" 
                                    onDataChange={editElectricMotorPowerChange} 
                                    ref={editElectricMotorPowerInput} 
                                    value={editVehicleData?.electricMotorPower?.toString()}
                                    min={0}
                                />
                            }
                            <CustomInput type="text" name="kind" label="Редагувати тип" placeholder="Редагувати тип - CAR, MOTO..." onDataChange={editKindChange} ref={editKindInput} value={editVehicleData.kind} />
                            <VehicleTypeInfo />
                            <CustomInput type="text" name="brand" label="Редагувати бренд" placeholder="Редагувати бренд - Skoda" onDataChange={editBrandChange} ref={editBrandInput} value={editVehicleData.brand} />
                            <CustomInput type="text" name="model" label="Редагувати модель" placeholder="Редагувати модель - Octavia A7" onDataChange={editModelChange} ref={editModelInput} value={editVehicleData.model ? editVehicleData.model : ""} />
                            <CustomInput type="text" name="modelText" label="Редагувати назва" placeholder="Редагувати назва - Skoda Octavia A7" ref={editModelTextInput} value={editVehicleData.modelText ? editVehicleData.modelText : ""} isReadOnly={true} />
                            <CustomInput type="text" name="stateNumber" label="Редагувати державний номер" placeholder="Редагувати державний номер - AА0000AA" onDataChange={editStateNumberChange} ref={editStateNumberInput} value={editVehicleData.stateNumber} />
                            <div className="form-group form-check">
                                <input name="dontHaveVIN" id="dont-have-vin" type="checkbox" hidden checked={editVehicleData.dontHaveVIN} onChange={editDontHaveVINChange} disabled />
                                <label htmlFor="dont-have-vin">Немає VIN коду</label>
                            </div>
                            <CustomInput type="text" name="autoColor" label="Редагувати колір" placeholder="Редагувати колір - БІЛИЙ" onDataChange={editAutoColorChange} ref={editAutoColorInput} value={editVehicleData.autoColor} />
                            <div className="switch-wrapper">
                                <label className="switch-container">
                                    <SwitchComponent onChange={handleEditForeignStateChange} checked={editIsForeign} onColor="#1BA876" />
                                    <div className="switch-text">Iноземна реєстрація</div>
                                </label>
                            </div>
                            {!editIsForeign &&
                            <>
                                <AutoComplete ref={editVehicleRegLocationInput} content={city} handleContent={handleEditVechileRegPlace} handleContentSuggestion={handleEditRegPlaceSuggestion} handleContentBlur={handleEditVechileRegContentBlur} isTouch={touch} />
                                <div className="vehicle-reg-location">
                                    <RegCities handleVechicleRegCity={setVechicleRegCity} />
                                </div>
                                <CustomInput type="number" name="regPlaceID" label="Редагувати ідентифікатор місця реєстрації" placeholder="Редагувати ідентифікатор місця реєстрації" ref={editRegPlaceIDInput} isReadOnly={true} value={regPlaceID} />
                                <CustomInput type="text" name="regPlaceCode" label="Редагувати код місця реєстрації" placeholder="Редагувати код місця реєстрації" ref={editRegPlaceCodeInput} isReadOnly={true} value={regPlaceCode} />
                            </>
                            }
                            <CustomInput type="number" name="grossWeight" label="Редагувати повну масу (кг)" placeholder="Редагувати повну масу - 2440" onDataChange={handleEditGrossWeightChange} ref={editGrossWeightInput} min={0} value={editVehicleData?.grossWeight?.toString()} />
                            <CustomInput type="number" name="curbWeight" label="Редагувати масу без навантаження (кг)" placeholder="Редагувати масу без навантаження - 1940" onDataChange={handleEditCurbWeightChange} ref={editCurbWeightInput} min={0} value={editVehicleData?.curbWeight?.toString()} />
                            <CustomInput type="number" name="seatingCapacity" label="Редагувати кількість місць (з водієм)" placeholder="Редагувати кількість місць (з водієм) - 5" onDataChange={handleEditSeatingCapacityChange} ref={editSeatingCapacityInput} min={0} value={editVehicleData?.seatingCapacity?.toString()} />
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => navToViewVehicle(dashboardNavigate, id, externalID, stateNumber!.toLowerCase(), vehicleID)}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={() => navToVehiclesPage(dashboardNavigate, id, externalID)}>Транспортні засоби</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && vehicleErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleErrorMsg}</div>}
                  </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditVehiclePage;