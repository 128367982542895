import insurance from "./insurance/insuranceReducer";
import {combineReducers} from "redux";
import tokenReducer from "./token/tokenReducer";
import offerReducer from "./offer/offerReducer";
import {IVehicleState} from "./vehicle/vehicleTypes";
import vehicleReducer from "./vehicle/vehicleReducer";
import {IOfferState} from "./offer/offerTypes";
import {IDataOrderState, IGreenCardDataOrderState, IPolicyState} from "./insurance/insuranceTypes";
import cityReducer from "./city/cityReducer";
import {ICityState} from "./city/cityTypes";
import userReducer from "./user/userReducer";
import {IUserInfoState} from "./user/userType";
import {StepsState} from "./steps/stepsTypes";
import stepsReducer from "./steps/stepsReducer";
import {PromocodeState} from "./promocode/promocodeTypes";
import promocodeReducer from "./promocode/promocodeReducer";
import {MiscState} from "./misc/miscTypes";
import miscReducer from "./misc/miscReducer";

export interface RootState {
  insurance: {
    dataOrder: IDataOrderState;
    greenCardDataOrder: IGreenCardDataOrderState;
    policies: IPolicyState;
  },
  tokens: {
    token: string;
    error: any;
  },
  vehicle: IVehicleState;
  city: ICityState;
  offer: IOfferState;
  user: IUserInfoState;
  steps: StepsState;
  promocode: PromocodeState,
  misc: MiscState;
}

const rootReducer = combineReducers({
  insurance,
  tokens: tokenReducer,
  vehicle: vehicleReducer,
  city: cityReducer,
  offer: offerReducer,
  user: userReducer,
  steps: stepsReducer,
  promocode: promocodeReducer,
  misc: miscReducer
});

export default rootReducer;