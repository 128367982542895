import {useEffect} from "react";
import {useNavigate} from "react-router-dom";

const NotFoundComponent = () => {
    const navigate = useNavigate();

    useEffect(() => {
      const redirectToMainPage = () => {
        navigate("/");
      };
      
      redirectToMainPage();
    }, [navigate]);

    return null;
};

export default NotFoundComponent;