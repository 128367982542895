import React, {useRef, useState} from "react";
import useForm from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {RootState} from "../../redux-data";
import {featureVehicle, typeVehicle} from "../../core/typeAuto";
import {useId} from "react-id-generator";
import {useStepsContext} from "../../utils/StepsProvider";
import {AutoComplete} from "../MainPage/AutoComplete/AutoComplete";
import {hasCityZone, setData, setError} from "../../redux-data/city/cityReducer";
import {setDataOrder} from "../../redux-data/insurance/insuranceReducer";
import CustomField from "../MainPage/CustomField";
import {PeriodsTypeList, epolicyPeriods} from "../../core/insuranceData";
import appConfig from "../../config";
import RegCities from "./common/RegCities";
import {dbAxiosInstance} from "../../core/configDBAxios";
import CustomSelect from "../common/CustomSelect";
import {isVehicleOtkRequired, checkRequestStatus} from "../../utils/utilsGeneral";
import {getMaxDate, compareOtkDate, getCurrentDateTime, isDateValid} from "../../utils/utilsDate";
import {getCustomerPrivilege} from "../../redux-data/user/userSelector";
import {clearUserPrivilegeType, getCurrentUserInfo} from "../../redux-data/user/userReducer";
import {IDataForm} from "../../typings/IDataOffers";
import webConfig from "../../config";
import {setPromocodeIsActivated, setPromocodeName, setPromocodeSalePointId} from "../../redux-data/promocode/promocodeReducer";
import PublicModal from "../common/Modals/PublicModal";

const CalculatorForm = (props) => {
  const data = useSelector(((state: RootState) => state.insurance.dataOrder));
  const {register, handleSubmit, watch, errors, clearError} = useForm<IDataForm>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: data
  });
  const [typeAuto, setTypeAuto] = useState(data.typeAuto) as any;
  const [valueType, setValueType] = useState(data.valueType);
  const [period, setPeriod] = useState<PeriodsTypeList>(data.period);
  const isCityZone = useSelector(hasCityZone);
  const customerPrivilege = useSelector(getCustomerPrivilege);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const {goTo} = useStepsContext() as any;
  const [formId] = useId(1, "form");
  const dispatch = useDispatch();
  const [privilegeState, setPrivilegeState] = useState(false);
  const [isPrivilegeError, setPrivilegeError] = useState(false);
  const [privilegeErrorMsg, setPrivilegeErrorMsg] = useState("");
  const [vechileEngineOptionType, setVehicleEngineOptionType] = useState(0);
  const [taxiState, setTaxiState] = useState(false);
  const [promocodeState, setPromocodeState] = useState(false);
  const [promocode, setPromocode] = useState("");
  const vechicleRegLocationField = useRef<HTMLInputElement>(null);
  const [city, setCity] = useState("");
  const [touch, setTouch] = useState(false);
  const [isForeign, setForeign] = useState(false);
  const [typeError, setTypeError] = useState(false);
  const [categoryError, setCategoryError] = useState(false);
  const [vehicleOtkDate, setVehicleOtkDate] = useState("");
  const [isVehicleValidOtkDate, setIsVehicleValidOtkDate] = useState(false);
  const [vehicleOtkDateErrorMsg, setVehicleOtkDateErrorMsg] = useState("");
  const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
  const [promocodeSuccessMsg, setPromocodeSuccessMsg] = useState("");
  const [isPromocodeVerification, setIsPromocodeVerification] = useState(false);
  const [isPromocodeVerified, setPromocodeVerified] = useState(false);
  const [periodError, setPeriodError] = useState(false);

  const onSubmit = async (data: IDataForm) => {
    const {foreign} = data;

    if (typeAuto && valueType && period && (foreign || (city.length > 0 && isCityZone)) && ((isVehicleOtkRequired(typeAuto.kind, valueType.value) && vehicleOtkDate) || (!isVehicleOtkRequired(typeAuto.kind, valueType.value) && !vehicleOtkDate))) {
        setTypeError(false);
        setCategoryError(false);
        setPeriodError(false);
        dispatch(setDataOrder({
          ...data,
          typeAuto,
          valueType,
          period
        }));
        goTo(2);
    } else {
        if (!typeAuto) {
            setTypeError(true);
        }

        if (!valueType) {
            setCategoryError(true);
        }

        if (!period) {
            setPeriodError(true);
        }

        if (vechicleRegLocationField.current) {
            vechicleRegLocationField.current.focus();

            if (vechicleRegLocationField.current.value.length === 0) {
                setTouch(false);
                dispatch(setError({
                  message: "Це поле обов'язкове"
                }));
            }
        }
    }
  };

  const handleChangeTypeAuto = (e: any) => {
    setTypeAuto(e);
    props.handleSelect(e);
    setValueType(featureVehicle[e.value].options[0]);
    setPrivilegeError(false);
    setPrivilegeErrorMsg("");
    setTypeError(false);
    setCategoryError(false);

    if (vechicleRegLocationField.current) {
        vechicleRegLocationField.current.focus();
    }
  };

  const handleCategoryChange = (e: any) => {
    setValueType(e);
    setVehicleEngineOptionType(e.value);
    validateCalcPrivilege(privilegeState, e.value);
    setCategoryError(false);
  };

  const handleProcodeChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.target.value = event.target.value.toUpperCase();
    const customerPromocode = event.target.value.trim();

    if (customerPromocode.length === Number(webConfig.customerPromocodeLength)) {
        try {
            setIsPromocodeVerification(true);
            const promocodeRes = await dbAxiosInstance.get(`/verify/promocode/${encodeURIComponent(customerPromocode)}`);
            const dbPromocodeData = promocodeRes.data;

            if (dbPromocodeData.length > 0) {
                const [pessPromocode] = dbPromocodeData;
                const isPromocodeValid = !pessPromocode.is_used && pessPromocode.activations < pessPromocode.max_activations && isDateValid(pessPromocode.valid_until) && pessPromocode.sale_point_id === currentUserInfo?.salePoint.id;

                if (isPromocodeValid) {
                    const promocodeActivations = ++pessPromocode.activations;

                    const activatedPromocodeData = {
                      activations: promocodeActivations,
                      isUsed: promocodeActivations >= pessPromocode.max_activations ? true : false,
                      activated: getCurrentDateTime()
                    };

                    const activatedPromocodeRes = await dbAxiosInstance.put(`/activate/verified-promocode/${encodeURIComponent(pessPromocode.id)}/${encodeURIComponent(pessPromocode.promocode)}/${encodeURIComponent(pessPromocode.sale_point_id)}`, activatedPromocodeData);
                    const activatedPromcodeStatus = activatedPromocodeRes.status;
                    
                    if (checkRequestStatus(activatedPromcodeStatus)) {
                        setPromocodeVerified(true);
                        setPromocodeSuccessMsg(`Ваш промокод успішно прийнято! По промокоду у Вас доступна знижка ${pessPromocode.discount}% у платіжній системі.`);
                        setPromocodeErrorMsg("");
                        setPromocode(customerPromocode);
                        dispatch(setPromocodeName(pessPromocode.promocode));
                        dispatch(setPromocodeIsActivated(true));
                        dispatch(setPromocodeSalePointId(pessPromocode.sale_point_id));
                    } else {
                        setPromocodeVerified(false);
                        setPromocodeSuccessMsg("");
                        setPromocodeErrorMsg("Ваш промокод недійсний або вже використаний!");
                        setPromocode("");
                    }
                } else {
                    setPromocodeVerified(false);
                    setPromocodeSuccessMsg("");
                    setPromocodeErrorMsg("Ваш промокод недійсний або вже використаний!");
                    setPromocode("");
                }
            } else {
                setPromocodeVerified(false);
                setPromocodeSuccessMsg("");
                setPromocodeErrorMsg("Ваш промокод недійсний або вже використаний!");
                setPromocode("");
            }
        } catch (error: any) {
            setPromocodeVerified(false);
            setPromocodeSuccessMsg("");
            setPromocodeErrorMsg("Помилка! Не вдалось перевірити Ваш промокод!");
            setPromocode("");
        }
    } else {
        setPromocodeVerified(false);
        setPromocodeErrorMsg("");
        setPromocodeSuccessMsg("");
        setPromocode("");
    }
  };

  const handlePromocodeStateChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPromocodeState(event.target.checked);
  };

  const handleCalcPrivilegeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const isPrivilegeChecked = event.target.checked;
    setPrivilegeState(isPrivilegeChecked);
    validateCalcPrivilege(isPrivilegeChecked, vechileEngineOptionType);
    
    if (isPrivilegeChecked && customerPrivilege) {
        dispatch(clearUserPrivilegeType());
    }
  };

  const validateCalcPrivilege = (isChecked, engineOptionType) => {
    if (isChecked && typeAuto && typeAuto.id == appConfig.carID && (engineOptionType == featureVehicle[typeAuto.value].options[2].value || engineOptionType == featureVehicle[typeAuto.value].options[3].value)) {
        setPrivilegeError(true);
        setPrivilegeErrorMsg("Об'єм двигуна для пільговиків не може перевищувати 2500");
    } else { 
        setPrivilegeError(false);
        setPrivilegeErrorMsg(""); 
    }
  };

  const handleCalcTaxiChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setTaxiState(event.target.checked);
  };

  const handleForeignChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setForeign(event.target.checked);

    if (event.target.checked) {
        setCity("");
        setTouch(false);
        dispatch(setData(null));
    }
  };

  const setVechicleRegCity = async (event) => {
    const dataSuggestion = event.target.dataset.suggestion;

    if (vechicleRegLocationField.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
        vechicleRegLocationField.current.value = event.target.value;
        setCity(vechicleRegLocationField.current.value);
        setTouch(true);
        const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
        dispatch(setData(place.data[0]));
    } else {
        setCity("");
        setTouch(false);
    }
  };

  const handleVechileRegContent = (event, {newValue, method}) => {
    setCity(newValue);
    
    if (newValue !== "") {
        setTouch(true);
    } else {
        setTouch(false);
        dispatch(setError({
          message: "Це поле обов'язкове"
        }));
    }
  };

  const handleVechileRegContentBlur = () => {
    setTouch(true);
  };

  const handleOtkDateChange = (name, date) => {
    const isValidOtkDate = compareOtkDate(date);

    if (isValidOtkDate) {
        setVehicleOtkDate(date);
        setIsVehicleValidOtkDate(false);
        setVehicleOtkDateErrorMsg("");
    } else {
        setVehicleOtkDate("");
        setIsVehicleValidOtkDate(true);
        setVehicleOtkDateErrorMsg("Ви ввели невірну дату Обов'язкового Технічного Контролю (ОТК)!");
    }

    clearError(name);
  };

  const handlePromocodeVerification = () => {
    if (!isPromocodeVerified) {
        setPromocodeState(!promocodeState);
    }

    setIsPromocodeVerification(!isPromocodeVerification);
  };

  const handleEpolicyPeriodChange = (epolicyPeriod) => {
    setPeriod(epolicyPeriod);
    setPeriodError(false);
  };

  const feature = typeAuto ? featureVehicle[typeAuto.value] : null;
  const featureOptions = feature ? feature.options : [];
  const name = feature ? feature.name : "";

  return (
    <form onSubmit={handleSubmit(onSubmit)} noValidate={true} id={formId}>
      <div className="form-group">
        <CustomSelect
          options={typeVehicle}
          isSearchable={false}
          handleChange={handleChangeTypeAuto}
          value={typeAuto}
          placeholder="Тип транспортного засобу"
          isCreatable={false}
          isError={typeError}
        />
      </div>
      <div className="checkbox-car">
        <div className="form-group form-check">
          <input
            ref={register}
            name="privilege"
            id="benefit"
            type="checkbox"
            hidden
            defaultChecked={privilegeState} 
            onChange={handleCalcPrivilegeChange}
          />
          <label htmlFor="benefit">Є пільга</label>
        </div>
        <div className="form-group form-check">
          <input
            ref={register}
            name="taxi"
            id="taxi"
            type="checkbox"
            defaultChecked={taxiState}
            onChange={handleCalcTaxiChange}
            hidden
          />
          <label htmlFor="taxi">Таксі</label>
        </div>
        <div className="form-group form-check">
          <input ref={register} name="promocode" id="promocode" type="checkbox" defaultChecked={promocodeState} onChange={handlePromocodeStateChange} disabled={isPromocodeVerified} hidden />
          <label htmlFor="promocode">Промокод</label>
        </div>
      </div>
      {isPrivilegeError && privilegeErrorMsg && <div className="policy-error-notification">{privilegeErrorMsg}</div>}
      {promocodeState && 
        <div className="promocode-container">
          <CustomField 
            type="text" 
            register={register({
              required: "Це поле обов'язкове",
              pattern: {
                value: /^[A-z0-9]{10}$/,
                message: "Промокод невірно вказаний"
              },
              maxLength: {
                value: 10,
                message: "Промокод складається з 10 символів",
              },
              minLength: {
                value: 10,
                message: "Промокод складається з 10 символів",
              }
            })}
            className="promocode-input" 
            name="promocodeInput" 
            placeholder="Введіть Ваш промокод"
            onChange={handleProcodeChange}
            errors={errors}
            label=""
            defaultValue={promocode}
            readOnly={isPromocodeVerified}
            hidden={isPromocodeVerified}
            autoFocus
            />
          </div>
      }
      <div className="form-group">
        <CustomSelect
          options={featureOptions}
          isSearchable={false}
          handleChange={handleCategoryChange}
          isCreatable={false}
          value={valueType}
          placeholder={name}
          isError={categoryError}
        />
      </div>
      <div className="form-group">
        <CustomSelect
          options={epolicyPeriods}
          isSearchable={false}
          handleChange={handleEpolicyPeriodChange}
          isCreatable={false}
          value={period}
          placeholder="Період дій договору"
          isError={periodError}
        />
      </div>
      <div className="form-group form-check">
        <input
          ref={register}
          name="foreign"
          id="foreign"
          type="checkbox"
          hidden
          checked={isForeign}
          onChange={handleForeignChange}
        />
        <label htmlFor="foreign">Iноземна</label>
      </div>
      {!watch("foreign") && <AutoComplete ref={vechicleRegLocationField} content={city} handleContent={handleVechileRegContent} handleContentBlur={handleVechileRegContentBlur} isTouch={touch} />}
      {!isForeign &&
        <RegCities handleVechicleRegCity={setVechicleRegCity} />
      }
      {isVehicleOtkRequired(typeAuto?.kind, valueType?.value) &&
        <>
          <CustomField
            register={register({
              required: "Це поле обов'язкове"
            })}
            errors={errors}
            name="otkDate"
            label="Дата наступного ОТК"
            type="date"
            placeholder="РРРР.ММ.ДД"
            max={getMaxDate()}
            autoComplete="off"
            dateValue={vehicleOtkDate}
            onDateChange={handleOtkDateChange}
          />
          {isVehicleValidOtkDate && vehicleOtkDateErrorMsg && <div className="customer-error-notification">{vehicleOtkDateErrorMsg}</div>}
        </>
      }
      {promocodeState && isPromocodeVerification &&
        <PublicModal isOpen={promocodeState} onCLose={handlePromocodeVerification} type="promocode-verification" title="Промокод" successMsg={promocodeSuccessMsg} errorMsg={promocodeErrorMsg} />
      }
      <button type="submit" className="btn btn-primary">порівняти ціни</button>
    </form>
  )
};

export default CalculatorForm;
