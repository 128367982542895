import React, {useState, useEffect} from "react";
import {useNavigate, useParams, useLocation} from "react-router-dom";
import {navToAdminPanel, navToVehiclesPage, navToViewVehicle} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {InsuranceLocationState} from "../../../typings/InsuranceLocationState";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const EditedVehiclePage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, stateNumber, vehicleID} = useParams<{
        id: string;
        externalID: string;
        stateNumber: string;
        vehicleID: string;
    }>();
    const vehicleLocation = useLocation();
    const {isSuccess, successMsg, errorMsg} = vehicleLocation.state as InsuranceLocationState;
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setSuccessNotificationHidden(false);
            setErrorNotificationHidden(true);
        } else {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    const backToEditVehicle = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/edit-vehicle/${stateNumber!.toLowerCase()}/${vehicleID}`);
    };
    
    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                <div className="back-icon">
                    <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                </div>
                <div className="dashboard-container">
                    {!hideSuccessNotification && successMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{successMsg}</div>}
                    {!hideErrorNotification && errorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{errorMsg}</div>}
                    <div>
                        <button type="button" className="admin-btn" onClick={() => navToViewVehicle(dashboardNavigate, id, externalID, stateNumber!.toLowerCase(), vehicleID)}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути</button>
                        <button type="button" className="admin-btn" onClick={backToEditVehicle}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => navToVehiclesPage(dashboardNavigate, id, externalID)}>Транспортні засоби</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                </div>
                </>
            }
            </div>
        </div>
    );
};

export default EditedVehiclePage;