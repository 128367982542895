import React, {useEffect, useState, useRef} from "react";
import PinField from "react-pin-field";
import TimerComponent from "../Forms/common/TimerComponent";

interface IVerificationCode {
    title: string;
    count: number;
    name: string;
    inputType: string;
    inputMode: string;
    allowedChars: string;
    expTime: string | number | Date;
    onVerificationCodeResend?: () => void;
    onVerificationComplete?: (code: string) => void;
}

const VerificationCode = ({title, count, name, inputType, inputMode, allowedChars, expTime, onVerificationCodeResend, onVerificationComplete}: IVerificationCode) => {
    const [codeExpiration, setCodeExpiration] = useState(Date.now() + Number(expTime));
    const [isBtnEnabled, setBtnEnabled] = useState(false);
    const pinFieldRefs = useRef<HTMLInputElement[]>(null);

    useEffect(() => {
        pinFieldRefs.current?.forEach((input) => {
            input.type = inputType;
            input.inputMode = inputMode;
            input.pattern = allowedChars;
        });
    }, []);

    const handleTimerComplete = () => {
        setBtnEnabled(!isBtnEnabled);
    };
    
    const handleTimerRenderContent = (minutes, seconds) => {
        return <p>Вiдправити код повторно через: {minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</p>
    };

    const handleCodeResend = () => {
        setBtnEnabled(!isBtnEnabled);
        setCodeExpiration(Date.now() + Number(expTime));
        onVerificationCodeResend?.();
    };

    const handleVerificationComplete = (code: string) => {
        onVerificationComplete?.(code.trim());
    };

    return (
        <div className="verification-code-wrapper">
            <p>{title}</p>
            <div className="verification-code-container">
                <PinField ref={pinFieldRefs} className="verification-code-input" length={count} name={name} onComplete={handleVerificationComplete} autoFocus={true} />
            </div>
            <div className="verification-code-timer">
                {!isBtnEnabled && <TimerComponent expiryTimestamp={codeExpiration} onTimerRenderContent={handleTimerRenderContent} onTimerComplete={handleTimerComplete} />}
                <button type="button" className="insurance-btn" disabled={!isBtnEnabled} onClick={handleCodeResend}>Відправити код знову</button>
            </div>
        </div>
    );
};

export default VerificationCode;