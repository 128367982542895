import React, {useEffect, useState} from "react";
import webConfig from "../config";
import {useSelector} from "react-redux";
import {getOfferId, getOfferCustomerFirstName, getOfferCustomerMiddleName, getOfferCustomerLastName} from "../redux-data/offer/offerReducer";
import {getSelectedAmountItem} from "../redux-data/insurance/insuranceSelector";
import {isSettingItemActive} from "../utils/utilsGeneral";
import {getCurrentUtcDate, getCurrentEAgentDate} from "../utils/utilsDate";
import {wfpAxiosInstance} from "../core/configWfpAxios";
import {getCurrentUserInfo} from "../redux-data/user/userReducer";
import {getPromocodeName, getPromocodeIsActivated, getPromocodeSalePointID, getPromocodeDiscountedPayment} from "../redux-data/promocode/promocodeReducer";

interface WayForPayPageProps {
    isWidget: boolean;
    onApproved?: (data: any) => void;
    onDeclined?: (data: any) => void;
    onPending?: (data: any) => void;
    onUnknown?: (data: any) => void;
    onError?: (data: any) => void;
}

const WayForPayPage = ({isWidget, onApproved, onDeclined, onPending, onUnknown, onError}: WayForPayPageProps) => {
    const offerId = useSelector(getOfferId)!;
    const policyAmount = useSelector(getSelectedAmountItem)!;
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const promocodeName = useSelector(getPromocodeName);
    const promocodeIsActivated = useSelector(getPromocodeIsActivated);
    const promocodeSalePointID = useSelector(getPromocodeSalePointID);
    const promocodeDiscountedPayment = useSelector(getPromocodeDiscountedPayment);
    const customerFirstName = useSelector(getOfferCustomerFirstName);
    const customerMiddleName = useSelector(getOfferCustomerMiddleName);
    const customerLastName = useSelector(getOfferCustomerLastName);
    const [pageUrl, setPageUrl] = useState("");
    const [wfpError, setWfpError] = useState(false);
    const productPrice = promocodeIsActivated && promocodeName && promocodeSalePointID === currentUserInfo?.salePoint.id ? promocodeDiscountedPayment : policyAmount.amount > policyAmount.discountedAmount ? policyAmount.discountedAmount : policyAmount.amount;
    const insuranceProductName = `id ${currentUserInfo?.salePoint.id};Страховий платіж за договором страхування №${offerId} від ${getCurrentEAgentDate()} на суму ${productPrice} грн. без ПДВ, клієнт ${customerLastName} ${customerFirstName} ${customerMiddleName}`;

    useEffect(() => {
        if (isWidget) {
            handlePaymentWidget();
        } else {
            handlePaymentPage();
        }
    }, []);

    const handlePaymentWidget = () => {
        const wfpCheckout = window.document.createElement("script");
        wfpCheckout.src = "https://secure.wayforpay.com/server/pay-widget.js";
        wfpCheckout.id = "widget-wfp-script";
        wfpCheckout.onload = () => {
            prepareWayForPayWidget();
        };
        window.document.body.appendChild(wfpCheckout);

        return () => {
            wfpCheckout.remove();
        };
    };

    const handlePaymentPage = async () => {
        try {
            const isSandboxMode = await isSettingItemActive(webConfig.enableWfpSandboxID);
            const res = await wfpAxiosInstance.post("/wfp/page/signature", {
                data: {
                    merchantAccount: isSandboxMode ? webConfig.wfpSandboxMerchantLogin : webConfig.wfpMerchantLogin,
                    merchantDomainName: webConfig.domainName,
                    orderReference: offerId,
                    orderDate: getCurrentUtcDate(),
                    amount: productPrice,
                    currency: webConfig.wfpCurrency,
                    productName: insuranceProductName,
                    productCount: "1",
                    productPrice: productPrice
                }
            });
            const paymentPage = res.data;

            if (paymentPage) {
                setPageUrl(paymentPage);
                setWfpError(false);
            } else {
                setPageUrl("");
                setWfpError(true);
            }
        } catch (error: any) {
            onError && onError(error);
            setPageUrl("");
            setWfpError(true);
        }
    };

    const prepareWayForPayWidget = async () => {
        try {
            const isSandboxMode = await isSettingItemActive(webConfig.enableWfpSandboxID);
            const res = await wfpAxiosInstance.post("/wfp/widget/signature", {
                data: {
                    merchantAccount: isSandboxMode ? webConfig.wfpSandboxMerchantLogin : webConfig.wfpMerchantLogin,
                    merchantDomainName: webConfig.domainName,
                    orderReference: offerId,
                    orderDate: getCurrentUtcDate(),
                    amount: productPrice,
                    currency: webConfig.wfpCurrency,
                    productName: insuranceProductName,
                    productCount: "1",
                    productPrice: productPrice
                }
            });
            const {data, signature} = res.data;

            if (typeof window.Wayforpay !== "undefined") {
                const wayforpay = new window.Wayforpay();
                wayforpay.run({
                    ...data,
                    merchantTransactionType: webConfig.wfpTransactionType,
                    merchantTransactionSecureType: webConfig.wfpTransactionSecureType,
                    paymentSystems: webConfig.wfpPayTypes,
                    merchantAuthType: webConfig.wfpAuthType, 				
                    merchantSignature: signature, 
                    language: webConfig.wfpLang,
                    apiVersion: webConfig.wfpApiVersion
                    //clientEmail: "",
                    //clientPhone: "",
                }, (data) => {
                    onApproved && onApproved(data);
                }, (data) => {
                    onDeclined && onDeclined(data);
                }, (data) => {
                    onPending && onPending(data);
                }, (data) => {
                    onUnknown && onUnknown(data);
                });
            } else {
                onError && onError("Wayforpay не визначено.");
            }
        } catch (error: any) {
            onError && onError(error);
        }
    };

    return (
        <div className="wfp-checkout">
            {pageUrl && !wfpError && <iframe src={pageUrl} className="wfp-page-container"></iframe>}
            {!pageUrl && wfpError && <div className="customer-error-notification">Не вдалось завантажити сторінку оплати!</div>}
        </div>
    );
};

export default WayForPayPage;