import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {refreshPage, navToAdminPanel, findSalePointLabelByID} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import {Promocode} from "../../../typings/IDataOffers";
import {formatDBDate, formatDBDateTime} from "../../../utils/utilsDate";

const ViewPromocodePage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const {id, externalID, promocode, promocodeID} = useParams<{
        id: string;
        externalID: string;
        promocode: string;
        promocodeID: string;
    }>();
    const [promocodeData, setPromocodeData] = useState<Promocode>({
        id: 0,
        promocode: "",
        activations: 0,
        maxActivations: 0,
        validUntil: "",
        isUsed: false,
        added: "",
        discount: 0,
        salePointID: 0
    });

    useEffect(() => {
        const fetchPromocode = async () => {
            try {
                const res = await dbAxiosInstance.get(`/promocode/${encodeURIComponent(promocode!.toUpperCase())}/${encodeURIComponent(promocodeID!)}`);
                const dbPromocodeData = res.data;

                if (dbPromocodeData) {
                    if (dbPromocodeData.length > 0) {
                        const [pessPromocode] = dbPromocodeData;
                        setPromocodeData({id: pessPromocode.id, promocode: pessPromocode.promocode, activations: pessPromocode.activations, maxActivations: pessPromocode.max_activations, validUntil: pessPromocode.valid_until, isUsed: pessPromocode.is_used, 
                                          added: pessPromocode.added, discount: pessPromocode.discount, salePointID: pessPromocode.sale_point_id});
                        setPromocodeErrorMsg("");
                    } else {
                        setPromocodeData({id: 0, promocode: "", activations: 0, maxActivations: 0, validUntil: "", isUsed: false, added: "", discount: 0, salePointID: 0});
                        setPromocodeErrorMsg("Немає такого промокоду. Натисніть на кнопку \"Додати\", щоб додати промокод.");
                    }
                } else {
                    setPromocodeData({id: 0, promocode: "", activations: 0, maxActivations: 0, validUntil: "", isUsed: false, added: "", discount: 0, salePointID: 0});
                    setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокод.");
                }
            } catch (error: any) {
                setPromocodeData({id: 0, promocode: "", activations: 0, maxActivations: 0, validUntil: "", isUsed: false, added: "", discount: 0, salePointID: 0});
                setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити промокод.");
            }
        };

        fetchPromocode();
    }, []);

    useEffect(() => {
        const notifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(notifTimer);
    }, [hideErrorNotification]);

    const navToPromocodesPage = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/promocodes`);
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const addPromocodes = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/add-promocode`);
    };

    const editPromocode = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/edit-promocode/${promocode}/${promocodeID}`);
    };

    const deletePromocode = async (id) => {
        try {
            await dbAxiosInstance.delete(`/promocode/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/promocodes`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setPromocodeErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setPromocodeData({id: 0, promocode: "", activations: 0, maxActivations: 0, validUntil: "", isUsed: false, added: "", discount: 0, salePointID: 0});
        }
    };

   

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Промокод &#8212; {promocode?.toUpperCase()}</h1>
                        <p className="item-title">Кількість активацій: <span className="font-bold">{promocodeData.activations > 0 ? promocodeData.activations : 0}</span></p>
                        <p className="item-title">Максимальна кількість активацій: <span className="font-bold">{promocodeData.maxActivations > 0 ? promocodeData.maxActivations : 0}</span></p>
                        <p className="item-title">Дійсний до: <span className="font-bold">{formatDBDate(promocodeData.validUntil)}</span></p>
                        <p className="item-title">Знижка: <span className="font-bold">{promocodeData.discount}%</span></p>
                        <p className="item-title">Є використаний: <span className="font-bold">{promocodeData.isUsed ? <span className="used-promocode">Так</span> : <span className="new-promocode">Ні</span>}</span></p>
                        <p className="item-title">Додано/Оновлено: <span className="font-bold">{formatDBDateTime(promocodeData.added)}</span></p>
                        <p className="item-title">Точка продажу (ID): <span className="font-bold">{findSalePointLabelByID(promocodeData.salePointID)} ({promocodeData.salePointID})</span></p>
                        <button type="button" className="admin-btn" onClick={addPromocodes}><i className="fa fa-plus notification-icon"></i>Додати</button>
                        <button type="submit" className="admin-btn" onClick={editPromocode}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => deletePromocode(promocodeID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={navToPromocodesPage}>Промокоди</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && promocodeErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ViewPromocodePage;