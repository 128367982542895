import React, {useState, useRef, useEffect} from "react";
import {useSelector} from "react-redux";
import {QRCodeSVG} from "qrcode.react";
import axios, {CancelTokenSource} from "axios";
import CustomTooltip from "../Forms/common/CustomTooltip";
import webConfig from "../../config";
import serviceDiia from "../../service/serviceDiia";
import CustomLoader from "../Forms/common/CustomLoader";
import {generateUUID, isSettingItemActive, checkWebsiteDocumentByDiia, checkAuthToken} from "../../utils/utilsGeneral";
import CustomSelect from "./CustomSelect";
import {IDataDiia, IDiiaCustomerFound, IDiiaVehicleFound} from "../../typings/IDataDiia";
import {isMobile} from "react-device-detect";
import {getPlatform} from "react-use-platform";
import PublicModal from "./Modals/PublicModal";
import {getCurrentUser, getCurrentUserInfo} from "../../redux-data/user/userReducer";
import GetDiiaAppComponent from "../Forms/common/GetDiiaAppComponent";
import RefreshDiiaDeepLink from "../Forms/common/RefreshDiiaDeepLinkComponent";
import {insuranceProxyAxiosInstance} from "../../core/configInsuranceProxyAxios";
import TimerComponent from "../Forms/common/TimerComponent";
import {getEagentToken} from "../../redux-data/misc/miscReducer";
import diiaLogo from "../../assets/img/logos/diia.svg";
import diiaQRInfo from "../../assets/img/logos/diia-qr-info.webp";

const DiiaComponent = ({insuranceType, vehicleData, isOpen, selectedDocuments, onCustomerFound, onSelectedDiiaCustomer, onSelectedDiiaVehicle}) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const [isIdentification, setIdentification] = useState(false);
    const [diiaStatus, setDiiaStatus] = useState({
        isInit: false,
        errorMsg: ""
    });
    const [diiaAuthInProgress, setDiiaAuthInProgress] = useState(false);
    const [isDiiaTimer, setDiiaIsTimer] = useState(true);
    const [diiaDeepLink, setDiiaDeepLink] = useState("");
    const [diiaData, setDiiaData] = useState({
        token: "",
        branchID: "",
        offerID: ""
    });
    const [isCustomerFound, setCustomerFound] = useState(false);
    const [diiaExpiryTimestamp, setDiiaExpiryTimestamp] = useState(Date.now() + 3 * 60 * 1000);
    const cancelDiiaTokenSourceRef = useRef<CancelTokenSource | null>(null);
    const diiaRef = useRef<HTMLDivElement>(null);
    const [isAdmin, setAdmin] = useState(false);
    const [isDiiaVisible, setDiiaVisible] = useState(false);
    const [diiaRequestID, setDiiaRequestID] = useState("");
    const [selectedDiiaCustomerFoundData, setSelectedDiiaCustomerFoundData] = useState<IDiiaCustomerFound>();
    const [diiaCustomersFoundData, setDiiaCustomersFoundData] = useState<IDiiaCustomerFound[]>();
    const [selectedDiiaVehicleFoundData, setSelectedDiiaVehicleFoundData] = useState<IDiiaVehicleFound>();
    const [diiaVehiclesFoundData, setDiiaVehiclesFoundData] = useState<IDiiaVehicleFound[]>();
    const [isDiiaDocsReceived, setDiiaDocsReceived] = useState(false);
    const [diiaCustomerError, setDiiaCustomerError] = useState(true);
    const [diiaVehicleError, setDiiaVehicleError] = useState(true);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    if (currentUser.sessionId && currentUserInfo?.permissions.salePointAdmin) {
                        setAdmin(true);
                    } else {
                        setAdmin(false);
                    }
                } else {
                    setAdmin(false);
                }
            } else {
                setAdmin(false);
            }
        };

        const checkDiiaVisiblity = async () => {
            const diiaState = await isSettingItemActive(webConfig.displayDiiaID);
            setDiiaVisible(diiaState);
        };

        const startDiia = () => {
            setIdentification(true);
            setDiiaIsTimer(true);
            setDiiaExpiryTimestamp(getExpiryTimestamp());
            diiaInit();
        };

        checkAdminRights();
        checkDiiaVisiblity();

        if (isOpen) {
            startDiia();
        }
    }, [isOpen]);

    useEffect(() => {
        const fetchDiiaDocOniOS = async () => {
            if (document.visibilityState === "visible") {
                cancelDiiaDocsRequest();
                const diiaDocsRes = await insuranceProxyAxiosInstance.get(`/diia/fetch-data/customer/${encodeURIComponent(diiaRequestID)}`);
                const diiaData = JSON.parse(diiaDocsRes.data[0].document);
                setDiiaIsTimer(false);
                setIdentification(false);

                if (diiaData.requestId === diiaRequestID) {
                    const isDiiaDocsFound = true;
                    setCustomerFound(isDiiaDocsFound);
                    onCustomerFound(isDiiaDocsFound);
                    setDiiaDocsReceived(isDiiaDocsFound);
                    setDiiaCustomersFoundData(undefined);
                    setDiiaVehiclesFoundData(undefined);
                    const diiaRes: IDataDiia = diiaData;
                    setDiiaDocuments(diiaRes.data);
                    await insuranceProxyAxiosInstance.delete(`/diia/customer-data/${encodeURIComponent(diiaRequestID)}`);
                } else {
                    setCustomerFound(false);
                    setDiiaCustomersFoundData(undefined);
                    setDiiaVehiclesFoundData(undefined);
                    setDiiaStatus({isInit: false, errorMsg: "Не вдалось отримати Ваші дані з Дії!"});
                }
            }
        };

        if (!isCustomerFound && diiaRequestID && isMobile && getPlatform() === "ios") {
            document.addEventListener("visibilitychange", fetchDiiaDocOniOS);
        }

        return () => {
            document.removeEventListener("visibilitychange", fetchDiiaDocOniOS);
        };
    }, [diiaRequestID]);

    const diiaInit = async () => {
      try {
          setDiiaAuthInProgress(true);
          const diiaTokenRes = await serviceDiia.fetchToken();
          const diiaToken = diiaTokenRes.token;
          const diiaBranchRes = await serviceDiia.fetchBranchID(diiaToken, selectedDocuments);
          const diiaBranchID = diiaBranchRes._id;
          const diiaOfferRes = await serviceDiia.fetchOfferID(diiaToken, diiaBranchID, insuranceType.label, selectedDocuments);
          const diiaOfferID = diiaOfferRes._id;
          const diiaUUID = generateUUID();
          setDiiaRequestID(diiaUUID);
          setDiiaData({token: diiaToken, branchID: diiaBranchID, offerID: diiaOfferID});
          fetchDiiaDeepLink(diiaToken, diiaBranchID, diiaOfferID, diiaUUID);
          setDiiaAuthInProgress(false);
          fetchDiiaDocs(diiaUUID);
          diiaRef.current?.scrollIntoView();
      } catch (error: any) {
          checkDiiaError(error, "Сталася помилка при ініціалізації Дії!");
      }
    };

    const fetchDiiaDeepLink = async (token, branchID, offerID, requestID) => {
        const diiaDeepLinkRes = await serviceDiia.fetchDeepLink(token, branchID, offerID, requestID);
        setDiiaDeepLink(diiaDeepLinkRes.deeplink);
    };

    const getDiiaVehicleRegAddress = (type, document) => {
        return type === "epolicy" ? document.ua.address.value.split(",") : document.eng.address.value.split(",");
    };

    const getDiiaVehicleRegHouseNumber = (type, document) => {
        const vehicleRegAddress = getDiiaVehicleRegAddress(type, document)?.[3];
        return vehicleRegAddress ? vehicleRegAddress.split("/")?.[0] : undefined;
    };

    const getDiiaVehicleFlatNumber = (type, document) => {
        const vehicleRegAddress = getDiiaVehicleRegAddress(type, document)?.[3];
        return vehicleRegAddress ? vehicleRegAddress.split("/")?.[1] : undefined;
    };

    const fetchDiiaDocs = async (requestID) => {
        try {
            cancelDiiaTokenSourceRef.current = axios.CancelToken.source();
            const diiaDocsRes = await serviceDiia.fetchDocs(cancelDiiaTokenSourceRef);
            setDiiaIsTimer(false);
            setIdentification(false);

            if (diiaDocsRes.requestId === requestID) {
                const isDiiaDocsFound = true;
                setCustomerFound(isDiiaDocsFound);
                onCustomerFound(isDiiaDocsFound);
                setDiiaDocsReceived(isDiiaDocsFound);
                setDiiaCustomersFoundData(undefined);
                setDiiaVehiclesFoundData(undefined);
                const diiaRes: IDataDiia = diiaDocsRes;
                setDiiaDocuments(diiaRes.data);
            } else {
                setCustomerFound(false);
                setDiiaCustomersFoundData(undefined);
                setDiiaVehiclesFoundData(undefined);
                setDiiaStatus({isInit: false, errorMsg: "Не вдалось отримати Ваші дані з Дії!"});
            }
        } catch (error: any) {
            checkDiiaError(error, "Сталася помилка при отриманні даних з Дії!");
        }
    };

    const setDiiaDocuments = (data: IDataDiia["data"]) => {
        Object.entries(data).map(([documentType, documents]) => {
            if (documentType === "internal-passport") {
                setDiiaCustomersFoundData(documents.map((document) => ({
                    ...diiaCustomersFoundData,
                    label: insuranceType.value === "epolicy" ? `${document.lastNameUA} ${document.firstNameUA} ${document.middleNameUA}` : `${document.lastNameEN} ${document.firstNameEN}`,
                    value: document.id,
                    description: documentType,
                    disabled: false,
                    firstName: insuranceType.value === "epolicy" ? document.firstNameUA : document.firstNameEN,
                    middleName: insuranceType.value === "epolicy" ? document.middleNameUA : "",
                    lastName: insuranceType.value === "epolicy" ? document.lastNameUA : document.lastNameEN,
                    birthDate: document.birthday,
                    innCode: document.taxpayerNumber,
                    documentType: checkWebsiteDocumentByDiia(documentType),
                    documentSeries: document.docNumber,
                    documentNumber: document.recordNumber,
                    documentIssuedDate: document.issueDate,
                    documentExpirationDate: document.expirationDate,
                    documentIssuedBy: document.department,
                    insuranceType: insuranceType.value
                })));
            }

            if (documentType === "foreign-passport") {
                setDiiaCustomersFoundData(documents.map((document) => ({
                    ...diiaCustomersFoundData,
                    label: `${document.lastNameEN} ${document.firstNameEN} - ${document.docNumber}`,
                    value: document.id,
                    description: documentType,
                    disabled: false,
                    firstName: document.firstNameEN,
                    middleName: "",
                    lastName: document.lastNameEN,
                    birthDate: document.birthday,
                    innCode: document.taxpayerNumber,
                    documentType: checkWebsiteDocumentByDiia(documentType),
                    documentSeries: document.docNumber.substring(0, 2),
                    documentNumber: document.docNumber.substring(2),
                    documentIssuedDate: document.issueDate,
                    documentExpirationDate: document.expirationDate,
                    documentIssuedBy: document.departmentUA,
                    insuranceType: insuranceType.value
                })));
            }

            if (documentType === "driver-license") {
                setDiiaCustomersFoundData(documents.map((document) => ({      
                    label: insuranceType.value === "epolicy" ? `${document.ua.lastName.value} ${document.ua.firstName.value.split(" ")[0]} ${document.ua.firstName.value.split(" ")[1]}` : `${document.eng.lastName.value} ${document.eng.firstName.value}`,
                    value: document.ua.identifier,
                    description: documentType,
                    disabled: false,
                    firstName: insuranceType.value === "epolicy" ? document.ua.firstName.value.split(" ")[0] : document.eng.firstName.value,
                    middleName: insuranceType.value === "epolicy" ? document.ua.firstName.value.split(" ")[1] : "",
                    lastName: insuranceType.value === "epolicy" ? document.ua.lastName.value : document.eng.lastName.value,
                    birthDate: document.ua.birth.value.split("\n")[0],
                    innCode: "",
                    documentType: checkWebsiteDocumentByDiia(documentType),
                    documentSeries: document.eng.documentNumber.value.substring(0, 3),
                    documentNumber: document.eng.documentNumber.value.substring(3),
                    documentIssuedDate: document.ua.issueDate.value,
                    documentExpirationDate: document.ua.expiryDate.value,
                    documentIssuedBy: document.ua.department.value,
                    insuranceType: insuranceType.value
                })));
            }

            if (documentType === "vehicle-license") {
                setDiiaVehiclesFoundData(documents.map((document) => ({
                    ...diiaVehiclesFoundData,
                    label: `${document.ua.brand.value} ${document.ua.model.value} - ${document.ua.licensePlate.value}`,
                    description: document.ua.documentNumber.value,
                    disabled: false,
                    value: document.ua.licensePlate.value,
                    engineVolume: document.ua.capacity.value,
                    year: document.ua.makeYear.value,
                    vin: document.ua.vin.value,
                    brand: document.ua.brand.value,
                    model: document.ua.model.value,
                    modelText: `${document.ua.brand.value} ${document.ua.model.value}`,
                    stateNumber: document.ua.licensePlate.value,
                    autoColor: document.ua.color.value,
                    totalWeight: document.ua.totalWeight.value,
                    ownWeight: document.ua.ownWeight.value,
                    nseating: document.ua.nseating.value,
                    addressSettlementName: getDiiaVehicleRegAddress(insuranceType.value, document)?.[1],
                    addressStreet: getDiiaVehicleRegAddress(insuranceType.value, document)?.[2],
                    addressHouseNumber: getDiiaVehicleRegHouseNumber(insuranceType.value, document),
                    addressApartmentNumber: getDiiaVehicleFlatNumber(insuranceType.value, document)
                })));
            }
        });
    };

    const checkDiiaError = (error, msg) => {
        if (!axios.isCancel(error)) {
            setCustomerFound(false);
            setDiiaCustomersFoundData([]);
            setDiiaVehiclesFoundData(undefined);
            setDiiaAuthInProgress(false);
            setDiiaIsTimer(false);
            setIdentification(false);
            setDiiaStatus({isInit: false, errorMsg: msg});
        }
    };

    const handleDiiaIdentification = () => {
        const isDiiaIdentification = !isIdentification;
        setIdentification(isDiiaIdentification);

        if (isDiiaIdentification) {
            setDiiaIsTimer(true);
            setDiiaExpiryTimestamp(getExpiryTimestamp());
            diiaInit();
        } else {
            cancelDiiaDocsRequest();
        }
    };

    const cancelDiiaDocsRequest = () => {
        if (cancelDiiaTokenSourceRef.current) {
            cancelDiiaTokenSourceRef.current.cancel("Diia запит на отримання документів скасовано!");
        }
    };

    const closeNotificationMsg = () => {
        setDiiaStatus({isInit: false, errorMsg: ""});
    };

    const refreshDiiaDeepLink = () => {
        const diiaRequestID = generateUUID();

        if (diiaData.token && diiaData.branchID && diiaData.offerID && diiaRequestID) {
            cancelDiiaDocsRequest();
            setDiiaIsTimer(true);
            setDiiaExpiryTimestamp(getExpiryTimestamp());
            fetchDiiaDeepLink(diiaData.token, diiaData.branchID, diiaData.offerID, diiaRequestID);
            fetchDiiaDocs(diiaRequestID);
        } else {
            setDiiaIsTimer(false);
            setDiiaStatus({isInit: false, errorMsg: "Не вдалось згенерувати QR-код Дії!"});
        }
    };

    const handleDiiaTimer = () => {
        setDiiaIsTimer(false);
        setIdentification(false);
        setDiiaExpiryTimestamp(getExpiryTimestamp());
        cancelDiiaDocsRequest();
    };

    const getExpiryTimestamp = () => {
        return Date.now() + 3 * 60 * 1000;
    };

    const handleDiiaCustomerFoundDataChange = (data) => {
        setDiiaCustomerError(false);
        setSelectedDiiaCustomerFoundData(data);
        onSelectedDiiaCustomer(data);
    };

    const handleDiiaVehicleFoundDataChange = (data) => {
        setDiiaVehicleError(false);
        setSelectedDiiaVehicleFoundData(data);
        onSelectedDiiaVehicle(data);
    };

    const handleTimerRenderer = (minutes, seconds) => {
         return  <div className={isMobile ? "diia-mobile-timer" : "diia-pc-timer"}>{minutes.toString().padStart(2, "0")}:{seconds.toString().padStart(2, "0")}</div>
    };

    const handleMobileDiiaClick = () => {
        window.location.href = `https://diia.page.link?link=${diiaDeepLink}&apn=ua.gov.diia.app&isi=1489717872&ibi=ua.gov.diia.app`;
    };

    return (
        <>
        {(isDiiaVisible || isAdmin) &&
            <div className="pay-policy_form">
                {diiaAuthInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={diiaAuthInProgress} infoMsg={isMobile ? "Триває завантаження" : "Триває завантаження QR-коду"} />
                 :
                   <>
                   {isCustomerFound ?
                        <div className="diia-wrapper">
                            <div className="diia-success-notification">
                                <PublicModal isOpen={isDiiaDocsReceived} onCLose={() => setDiiaDocsReceived(!isDiiaDocsReceived)} type="diia" title="Інформація" />
                            </div>
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className={`${diiaCustomerError ? "select-error-label" : ""}`}>Виберіть особисті дані</label>
                                        <CustomSelect
                                            options={diiaCustomersFoundData}
                                            handleChange={handleDiiaCustomerFoundDataChange}
                                            value={selectedDiiaCustomerFoundData}
                                            isCreatable={false}
                                            isDisabled={false}
                                            placeholder="Натисніть тут"
                                            isError={diiaCustomerError}
                                        />
                                    </div>
                                </div>
                                {diiaVehiclesFoundData && Object.keys(vehicleData).length === 0 &&
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label className={`${diiaCustomerError ? "select-error-label" : ""}`}>Виберіть транспортний засіб</label>
                                        <CustomSelect
                                            options={diiaVehiclesFoundData}
                                            handleChange={handleDiiaVehicleFoundDataChange}
                                            value={selectedDiiaVehicleFoundData}
                                            isCreatable={false}
                                            isDisabled={false}
                                            placeholder="Натисніть тут"
                                            isError={diiaVehicleError}
                                        />
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                   :
                        <div className="diia-wrapper">
                            <div className="diia-identification-container">
                                <div className="diia-msg">
                                    <p>Надішліть копії документів через Дію, щоб автоматично заповнити поля. Або заповніть їх власноруч</p>
                                </div>
                                <CustomTooltip msg="Ідентифікація через застосунок Дія">
                                    <div className="diia-btn" onClick={handleDiiaIdentification}>
                                        Шеринг документів через <img src={diiaLogo} alt="diiaPCIdentification" />
                                    </div>
                                </CustomTooltip>
                            </div>
                            {!diiaStatus.isInit && diiaStatus.errorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{diiaStatus.errorMsg}</div>}
                            {isIdentification && isDiiaTimer &&
                                <>
                                    {isMobile ?
                                        <div className="diia-mobile-container" ref={diiaRef}>
                                            <p>За цим посиланням Ви потрапите до застосунку Дія. Після виконання інструкцій повертайтесь до нас на сайт і виберіть Ваші дані які будуть автоматично внесені.</p>
                                            <div className="diia-mobile-controls">
                                                <button type="button" className="insurance-btn" onClick={handleMobileDiiaClick}>Перейти в застосунок Дія<i className="fa-regular fa-arrow-right arrow-forward-icon"></i></button>
                                            </div>
                                            <div className="diia-mobile-timer-container">
                                                <p>У Вас є обмежений час для переходу в застосунок Дія</p>
                                                <TimerComponent expiryTimestamp={diiaExpiryTimestamp} onTimerRenderContent={handleTimerRenderer} onTimerComplete={handleDiiaTimer} />
                                                <RefreshDiiaDeepLink diiaClassName="diia-refresh-mobile-deeplink" isMobileDevice={isMobile} onRefreshDeepLink={refreshDiiaDeepLink} />
                                            </div>
                                            <ul className="diia-info">
                                                <GetDiiaAppComponent />
                                            </ul>
                                        </div>
                                    :
                                        <div className="diia-pc-container">
                                            <div className="diia-qrcode-wrapper">
                                                <div className="diia-qrcode-container" ref={diiaRef}>
                                                    <p>Відскануйте QR-код</p>
                                                    <TimerComponent expiryTimestamp={diiaExpiryTimestamp} onTimerRenderContent={handleTimerRenderer} onTimerComplete={handleDiiaTimer} />
                                                    <QRCodeSVG value={diiaDeepLink} size={Number(webConfig.diiaQRCodeSize)} bgColor="#FFFFFF" marginSize={4} />
                                                </div>
                                                <RefreshDiiaDeepLink diiaClassName="diia-refresh-pc-deeplink" isMobileDevice={isMobile} onRefreshDeepLink={refreshDiiaDeepLink} />
                                            </div>
                                            <div className="diia-info-wrapper">
                                                <p>Вам необхідно:</p>
                                                <ul className="diia-info">
                                                    <li>Переконайтесь, що у Вас встановлена остання версія застосунку Дія</li>
                                                    <li>Відкрийте застосунок Дія на вашому смартфоні</li>
                                                    <li>Переконайтесь, що у Вас активовано Дія.Підпис</li>
                                                    <li>На екрані натисніть кнопку сканування QR-коду (як на фото нижче)
                                                        <img className="diia-qr-info" src={diiaQRInfo} alt="diia-qr-info" />
                                                    </li>
                                                    <li>Відскануйте QR-код на цьому екрані</li>
                                                    <li>У Вас є обмежений час для переходу в застосунок Дія</li>
                                                    <GetDiiaAppComponent />
                                                </ul>
                                            </div>
                                        </div>
                                    }
                                </>
                        }
                        </div>
                    }
                  </>
               }
            </div>
            }
        </>
    );
};

export default DiiaComponent;