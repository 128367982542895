import React from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const AnalyticsPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                    :
                    <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Аналітика</h1>
                            <div className="dashboard">
                                <ul className="dashboard-items">
                                    <li>
                                        <div className="dashboard-item">
                                            <CustomTooltip msg="БанкІД"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/bankid`}}>БанкІД</Link></CustomTooltip>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="dashboard-item">
                                            <CustomTooltip msg="Сайт"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics/website`}}>Сайт</Link></CustomTooltip>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </>
                }
                </div>
            </div>
        </div>
    );
};

export default AnalyticsPage;