import React, {useEffect, useState} from "react";
import {useNavigate, Link, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import webConfig from "../../../config";
import FilterPagination from "../../../utils/FilterPagination";
import {filterRecords, countRecords, isMatchCaseSearch} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import DataFilter from "../../../utils/DataFilter";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import {Promocode} from "../../../typings/IDataOffers";

const PromocodesPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [promocodes, setPromocodes] = useState<Promocode[]>([]);
    const [promcodesErrorMsg, setPromocodesErrorMsg] = useState("");
    const [promocodesCount, setPromocodesCount] = useState(0);
    const [recordsOnPage, setRecordsOnPage] = useState<any[]>([]);
    const [filteredItems, setFilteredItems] = useState<any[]>([]);
    const [filterCompleted, setFilterCompleted] = useState(false);
    const [processingFilter, setProcessingFilter] = useState(false);
    const [isMatchCaseFilter, setMatchCaseFilter] = useState(false);

    useEffect(() => {
        const fetchPromocodes = async () => {
            try {
                const res = await dbAxiosInstance.get("/promocodes");
                const dbPromocodesData = res.data;

                if (dbPromocodesData) {
                    if (dbPromocodesData.length > 0) {
                        const pessPromocodes: Promocode[] = dbPromocodesData.map((promocodeItem) => {
                            return {
                                id: promocodeItem.id,
                                promocode: promocodeItem.promocode,
                                activations: promocodeItem.activations,
                                maxActivations: promocodeItem.max_activations,
                                validUntil: promocodeItem.valid_until,
                                isUsed: Boolean(promocodeItem.is_used),
                                added: promocodeItem.added,
                                discount: promocodeItem.discount
                            };
                        });
                        setPromocodes(pessPromocodes);
                        setFilteredItems(pessPromocodes);
                        setPromocodesErrorMsg("");
                    } else {
                        setPromocodes([]);
                        setFilteredItems([]);
                        setPromocodesErrorMsg("Немає доданих промокодів. Натисніть на кнопку, щоб додати промокод.");
                    }
                } else {
                    setPromocodes([]);
                    setFilteredItems([]);
                    setPromocodesErrorMsg("Помилка БД! Не вдалося завантажити промокоди.");
                }
            } catch (error: any) {
                setPromocodes([]);
                setFilteredItems([]);
                setPromocodesErrorMsg("Помилка БД! Не вдалося завантажити промокоди.");
            }
        };

        const countAllPromocodes = async () => {
            try {
                const res = await dbAxiosInstance.get("/count-promocodes");
                const dbCountPromocodeData = res.data;

                if (dbCountPromocodeData) {
                    if (dbCountPromocodeData.length > 0) {
                        setPromocodesCount(dbCountPromocodeData[0].counter);
                    } else {
                        setPromocodesCount(0);
                    }
                } else {
                    setPromocodesCount(0);
                }
            } catch (error: any) {
                setPromocodesCount(0);
            }
        };

        const checkSearchType = async () => {
            const isOn = await isMatchCaseSearch();
            setMatchCaseFilter(isOn);
        };

        fetchPromocodes();
        countAllPromocodes();
        checkSearchType();
    }, []);

    const handleItemDelete = async (id) => {
        try {
            await dbAxiosInstance.delete(`/promocode/${encodeURIComponent(id)}`);
            setRecordsOnPage(filterRecords(recordsOnPage, id));
            const allRecords = countRecords(promocodesCount)
            setPromocodesCount(allRecords);

            if (allRecords === 0) {
                setPromocodesErrorMsg("Немає доданих промокодів. Натисніть на кнопку, щоб додати промокод.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setPromocodesErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setPromocodes([]);
        }
    };

    const handleRecordsOnPage = (records) => {
        setRecordsOnPage(records);
    };

    return (
         <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Промокоди</h1>
                        <div className="itemsCounter">
                            <p>Всього: <span className="font-bold">{promocodesCount}</span></p>
                        </div>
                        <DataFilter items={promocodes} matchCase={isMatchCaseFilter} placeholderText="Введіть дані промокоду для пошуку..." onFilterCompleted={(state) => setFilterCompleted(state)} onProcessingFilter={(state) => setProcessingFilter(state)} onFilteredItems={(filteredItems) => setFilteredItems(filteredItems)} itemNotFoundText="За Вашим запитом такого промокоду не знайдено!" />
                        <div className="dashboard">
                            <ul className="dashboard-items">
                                <li>
                                    <div className="dashboard-item">
                                        <CustomTooltip msg="Додати"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/add-promocode`}}><i className="fa fa-plus"></i></Link></CustomTooltip>
                                    </div>
                                </li>
                                {recordsOnPage.length > 0 ? recordsOnPage.map((promocodeData, index) =>
                                    <li key={index}>
                                        <div className="manage-item-wrapper">
                                            <div className="manage-item-container">
                                                <CustomTooltip msg="Редагувати">
                                                    <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/edit-promocode/${promocodeData.promocode.toLowerCase()}/${promocodeData.id}`}}>
                                                        <i className="fa fa-pencil-alt"></i>
                                                    </Link>
                                                </CustomTooltip>
                                                <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => handleItemDelete(promocodeData.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                            </div>
                                        </div>
                                        <div className="dashboard-item">
                                            <Link to={{pathname: `/admin/dashboard/${id}/${externalID}/view-promocode/${promocodeData.promocode.toLowerCase()}/${promocodeData.id}`}} key={promocodeData.id}>{promocodeData.promocode}</Link>
                                        </div>
                                    </li>) : <li><div className="dashboard-error">{promcodesErrorMsg}</div></li>
                                }
                            </ul>
                        </div>
                        <FilterPagination data={filteredItems} recordsPerPage={Number(webConfig.contentPerPage)} dataOnPage={handleRecordsOnPage} isDataLoaded={filterCompleted} setDataLoaded={(state) => setFilterCompleted(state)} isFilterProcessing={processingFilter} setFilterState={(state) => setProcessingFilter(state)} />
                    </div>
                  </>
                }
                </div>
            </div>
        </div>
    );
};

export default PromocodesPage;