import React from "react";
import CustomField from "../CustomField";

const revolutionCertificate = (name: string) => `revolutionCertificate.${name}`;

const RevolutionCertificateForm = (props) => {
  return (
    <>
      <div className={props.seriesClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={revolutionCertificate("series")}
          label="Серія"
          placeholder=""
          readOnly={props.isReadOnly}
          defaultValue={props.documentSeriesValue}
          onChange={props.onDocumentSeriesChange}
        />
      </div>
      <div className={props.numberClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={revolutionCertificate("number")}
          label="Номер"
          placeholder=""
          readOnly={props.isReadOnly}
          defaultValue={props.documentNumberValue}
          onChange={props.onDocumentNumberChange}
        />
      </div>
      <div className={props.issuedDateClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={revolutionCertificate("date")}
          label="Коли видано"
          placeholder="РРРР.ММ.ДД"
          type="date"
          autoComplete="off"
          dateReadOnly={props.isReadOnly}
          dateValue={props.documentIssuedDateValue}
          onDateChange={props.onDocumentIssuedDateChange}
        />
      </div>
    </>
  );
};

export default RevolutionCertificateForm;
