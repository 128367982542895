import config from "../config";
import {getLiqPayExpirationDate} from "./utilsDate";

interface IgetPayloadLiqpay {
  amount: number;
  order_id: string;
  isTest: boolean;
  customerEmail: string;
  description: string;
  token: string;
}

const getPayloadLiqpay = ({amount, order_id, isTest, customerEmail, description, token}: IgetPayloadLiqpay) => {
  return {
    action: "pay",
    version: 3,
    public_key: isTest ? config.liqPayPublicKeyTest : config.liqPayPublicKey,
    amount: amount,
    currency: "UAH",
    expired_date: getLiqPayExpirationDate(),
    language: "uk",
    order_id: order_id,
    email: customerEmail,
    description,
    info: token
  };
};

export {getPayloadLiqpay};
