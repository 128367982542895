import React, {useEffect, useLayoutEffect, useState} from "react";
import {NavLink, useNavigate, useLocation} from "react-router-dom";
import {useWindowScrollPositionSSR} from "../../utils/useWindowScrollPositionSSR";
import {useMediaQueryContext} from "../../utils/MediaQueryProvider";
import webConfig from "../../config";
import transparentLogo from "../../assets/img/logo.webp";
import PublicModal from "./Modals/PublicModal";
import _ from "lodash";
import {useDispatch, useSelector} from "react-redux";
import {getCurrentUser, getCurrentUserInfo, setCurrentUser, setData, setError, setUserLoginErrorMsg} from "../../redux-data/user/userReducer";
import {isSettingItemActive, clearAll} from "../../utils/utilsGeneral";
import serviceEAgent from "../../service/serviceEAgent";

const HeaderComponent: React.FC = React.memo((props: any) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [menu, setMenu] = useState(false);
  const {tabletView}: any = useMediaQueryContext();
  const [headerClassName, setHeaderClassName] = useState("");
  const [className, setClassName] = useState("");
  const navigate = useNavigate();
  const [modalType, setModalType] = useState("public");
  const dispatch = useDispatch();
  const currentUser = useSelector(getCurrentUser);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const [isDevModeEnabled, setDevModeEnabled] = useState(false);
  const location = useLocation();
  let {y} = useWindowScrollPositionSSR({});

  useEffect(() => {
    if (window) {
        window.onscroll = function () {
          setMenu(false);
          setIsOpen(false);
        };
    }

    const checkDevModeState = async () => {
      const devModeState = await isSettingItemActive(webConfig.enableDevModeID);
      setDevModeEnabled(devModeState);
    };

    checkDevModeState();
  }, []);

  useEffect(() => {
    setHeaderClassName(location.pathname === "/" ? `header navbar-expand-xl fixed-top ${className}` : `header navbar-expand-xl fixed-top scroll`);
    setMenu(false);
    setIsOpen(false);
  }, [location, className]);

  useEffect(() => {
    const checkLogin = async () => {  
      if (_.isEmpty(currentUser) && _.isEmpty(currentUserInfo)) {
          try {
              const currentCustomerRes = await serviceEAgent.getCurrentCustomer();
              const userData = await serviceEAgent.getCustomerInfo(currentCustomerRes.user.id);
              dispatch(setError(false));
              dispatch(setCurrentUser(currentCustomerRes));
              dispatch(setData(userData));
              dispatch(setUserLoginErrorMsg(""));
          } catch (error: any) {
              dispatch(setError(true));
              dispatch(setCurrentUser(null));
              dispatch(setData(null));
              dispatch(setUserLoginErrorMsg("Помилка! Не вдалось увійти до сервісу страхування."));
          }
      }
    };

    checkLogin();
  }, [currentUser, currentUserInfo, dispatch]);

  const handleToggle = (e: any) => {
    e.preventDefault();
    setIsOpen((open) => !open);
  };

  const handleToggleMenu = () => {
    setMenu((m) => !m);
  };

  useEffect(() => {
    if (y !== 0) {
        setClassName("scroll");
    } else {
        setClassName("");
    }
  }, [y]);

  useLayoutEffect(() => {
    if (y !== 0) {
        setClassName("scroll");
    } else {
        setClassName("");
    }
  }, [y]);

  const handleSelect = (value: "epolicy" | "greencard") => {
    //dispatch(setInsuranceType(value));
    setIsOpen(false);
    clearAll(dispatch);
    navigate(`/${value}`);
  };

  // const handleClickHeader = (e: any) => {
  //     e.preventDefault();
  //     const id = e.target.getAttribute("href");
  //     document.querySelector(id).scrollIntoView({
  //       behavior: "smooth",
  //       block: "center"
  //     })
  // };

  const handleToggleModal = (e) => {
    e && e.preventDefault();
    setIsModalOpen((open) => !open);
  };

  return (
    <header className={headerClassName}>
      <div className="container">
        <div className="header-logo">
          <NavLink to="/">
            <img id="socialLogo" src={webConfig.pessLogo} width="72px" height="60px" loading="lazy" alt="socialPessLogo" />
            <img id="logo" src={transparentLogo} alt="transparentPessLogo" />
          </NavLink>
        </div>
        <div className="header-right">
          <button
            onClick={handleToggleMenu}
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#header-menu">
            <i></i>
            <i></i>
            <i></i>
          </button>
          {(menu || !tabletView) && (
            <div className="navbar-collapse">
              <nav>
                <ul className="header-menu">
                  <li>
                    <NavLink to="/" className={() => "inactive"}><i className="fal fa-house home-icon"></i>Головна {isDevModeEnabled && <sup className="dev-mode-title">Dev</sup>}</NavLink>
                  </li>
                  <li>
                    <div className="dropdown">
                      <a onClick={handleToggle} href="#" className="dropdown-toggle" data-toggle="dropdown">
                        Страхування
                      </a>
                      {isOpen && (
                        <div className="dropdown-menu">
                          <button onClick={() => handleSelect("epolicy")} className="dropdown-item">Придбати Автоцивілку</button>
                          <button onClick={() => handleSelect("greencard")} className="dropdown-item">Придбати Зелену картку</button>
                        </div>
                      )}
                    </div>
                  </li>
                  <PublicModal isOpen={isModalOpen} onCLose={handleToggleModal} type={modalType} />
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        handleToggleModal(e);
                        setModalType("public");
                      }}>
                      Публічна оферта
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        handleToggleModal(e);
                        setModalType("reviews");
                      }}>
                      Відгуки та пропозиції
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        handleToggleModal(e);
                        setModalType("about");
                      }}>
                      Про компанію
                    </a>
                  </li>
                  <li>
                    <a
                      href="#"
                      onClick={(e) => {
                        handleToggleModal(e);
                        setModalType("contact");
                      }}>
                      Контакти
                    </a>
                  </li>
                </ul>
              </nav>
            </div>
          )}
        </div>
      </div>
    </header>
  );
});

export default HeaderComponent;
