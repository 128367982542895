import React from "react";
import {TabListProps} from "./tab";

const TabList = ({tabs, currentTab, orientation, tabSelected}: TabListProps) => {
    const handleTabClick = (tabID, tabValue) => {
        tabSelected(tabID, tabValue ? tabValue : "");
    };

    return (
        tabs.map((tab) => {
            return <button key={tab.index} className={`${!tab.isDisabled && currentTab === `tab-${tab.index}` ? "active" : ""} ${tab.isDisabled ? "disabled" : ""} ${orientation}`} onClick={() => !tab.isDisabled && handleTabClick(`tab-${tab.index}`, tab.value)} disabled={tab.isDisabled}>{tab.label}</button>;
        })
    );
};

export default TabList;