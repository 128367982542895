import React, {useState, useEffect} from "react";
import {useNavigate} from "react-router-dom";
import {TabsProps} from "./tab";
import TabList from "./TabList";
import TabContent from "./TabContent";
import {navToHomePage} from "./utilsGeneral";

const TabsComponent = ({tabs, defaultTab = 1, activeTab, orientation = "horizontal", tabsDisabledMsg, isContentPadding, onSelectedTab, onSelectedValue}: TabsProps) => {
  const navigate = useNavigate();
  const [selectedTab, setSelectedTab] = useState(`tab-${defaultTab}`);
  const [tabsDisabled, setTabsDisabled] = useState(false);
  
  useEffect(() => {
    if (tabs.length > 0) {
        const filteredTabs = tabs.filter((tab) => !tab.isDisabled);
        const initialTab = filteredTabs.find((tab) => tab)?.index;

        if (initialTab) {
            setSelectedTab(`tab-${initialTab}`);
            setTabsDisabled(false);
        } else {
            setTabsDisabled(true);
        }
    }
  }, [tabs]);

  const handleSelectedTab = (id: string, value: string) => {
    setSelectedTab(id);
    onSelectedTab(Number(id.trim().slice(4)));
    onSelectedValue?.(value);
  };

  return (
    <div className="tabs-wrapper">
      <div className="tabs-container">
        <div className={`tab-list ${orientation}`}>
          <TabList tabs={tabs} currentTab={selectedTab} orientation={orientation} tabSelected={handleSelectedTab} />
        </div>
        <div className={`${isContentPadding ? "tab-panels-padding" : "tab-panels"}`}>
        <> 
          {!tabsDisabled ? 
            <TabContent tabs={tabs} currentTab={selectedTab} isPadding={isContentPadding} /> 
          : 
            <div>
              <div className="customer-error-notification">{tabsDisabledMsg}</div>
              <button type="button" className="insurance-btn" onClick={() => navToHomePage(navigate)}>На головну</button>
            </div>
          }
        </>
        </div>
      </div>
    </div>
  );
};

export default TabsComponent;