import React, {useEffect, useState, useRef} from "react";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {checkValue, refreshPage, navToAdminPanel, navToGreencardCompaniesPage, checkRequestStatus, navToEpolicyCompaniesPage} from "../../../utils/utilsGeneral";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {IGreencardCashback} from "../../../typings/IDataOffers";
import SwitchComponent from "react-switch";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";

const EditGreencardCompany = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, productCode, companyID, greencardID} = useParams<{
        id: string;
        externalID: string; 
        productCode: string; 
        companyID: string; 
        greencardID: string;
    }>();
    const [editGreencardCompanyData, setGreencardCompanyDataEdited] = useState<IGreencardCashback>({
        id: 0,
        name: "",
        companyID: 0,
        productCode: 0,
        isCashbackEnabled: false,
        isHidden: false,
        cashbackPercentageValue: 0
    });
    const [greencardCompanyErrorMsg, setGreencardCompanyErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [greencardCompanyName, setGreencardCompanyName] = useState("");
    const [isCashback, setCashback] = useState(false);
    const percentageInput = useRef<HTMLInputElement>(null);
    const [isCompanyHidden, setCompanyHidden] = useState(false);

    useEffect(() => {
        const fetchGreencardCompany = async () => {
            try {
                const res = await dbAxiosInstance.get(`/greencard/company/${encodeURIComponent(productCode!)}/${encodeURIComponent(companyID!)}/${encodeURIComponent(greencardID!)}`);
                const dbGreencardData = res.data;

                if (dbGreencardData) {
                    if (dbGreencardData.length > 0) {
                        const [editGreencardCompany] = dbGreencardData;
                        setGreencardCompanyDataEdited({id: editGreencardCompany.id, name: editGreencardCompany.name, companyID: editGreencardCompany.company_id, productCode: editGreencardCompany.product_code, 
                                                       isCashbackEnabled: Boolean(editGreencardCompany.is_cashback_enabled), isHidden: Boolean(editGreencardCompany.is_hidden), cashbackPercentageValue: editGreencardCompany.cashback_percentage});
                        setGreencardCompanyName(editGreencardCompany.name);
                        setCashback(Boolean(editGreencardCompany.is_cashback_enabled));
                        setCompanyHidden(Boolean(editGreencardCompany.is_hidden));
                        setGreencardCompanyErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
                        setGreencardCompanyName("");
                        setCashback(false);
                        setCompanyHidden(false);
                        setGreencardCompanyErrorMsg("Немає такої страхової компанії зеленої карти.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
                    setGreencardCompanyName("");
                    setCashback(false);
                    setCompanyHidden(false);
                    setGreencardCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію зеленої карти.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
                setGreencardCompanyName("");
                setCashback(false);
                setCompanyHidden(false);
                setGreencardCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію зеленої карти.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchGreencardCompany();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const editCompanyNameChange = (event) => {
        setGreencardCompanyDataEdited((prev) => ({...prev, name: checkValue(event.target.value)}));
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editCompanyIDChange = (event) => {
        setGreencardCompanyDataEdited((prev) => ({...prev, companyID: checkValue(event.target.value)}));
    };

    const editProductCodeChange = (event) => {
        setGreencardCompanyDataEdited((prev) => ({...prev, productCode: checkValue(event.target.value)}));
    };

    const handleCashbackStateChange = (isOn) => {
        setCashback(isOn);
        setGreencardCompanyDataEdited((prev) => ({...prev, isCashbackEnabled: isOn}));

        if (isOn) {
            setTimeout(() => {
                if (percentageInput.current) {
                    percentageInput.current.focus();
                    percentageInput.current.select();
                }
            }, 0);
        } else {
            if (percentageInput.current) {
                percentageInput.current.value = "0";
                setGreencardCompanyDataEdited((prev) => ({...prev, cashbackPercentageValue: 0}));
            }
        }
    };

    const handleCashbackPercentageChange = (event) => {
        const percentage = event.target.value;

        if (percentage >= 0 && percentage <= 100) {
            setGreencardCompanyDataEdited((prev) => ({...prev, cashbackPercentageValue: checkValue(percentage)}));
        }
    };

    const handleHiddenStateChange = (isCompanyHidden) => {
        setCompanyHidden(isCompanyHidden);
        setGreencardCompanyDataEdited((prev) => ({...prev, isHidden: isCompanyHidden}));
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editGreencardCompanyData.name.toString().trim().length > 0 && editGreencardCompanyData.companyID > 0 && editGreencardCompanyData.productCode > 0) {
            try {
                const res = await dbAxiosInstance.put(`/greencard/edit-company/${encodeURIComponent(greencardID!)}`, editGreencardCompanyData);
                const editStatus = res.status;
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/greencard/company/${productCode}/${companyID}/${greencardID}/item/edited`, {
                    state: {
                        isSuccess: checkRequestStatus(editStatus),
                        status: editStatus,
                        successMsg: res.data,
                        errorMsg: ""
                    }
                });
                setGreencardCompanyErrorMsg("");
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setGreencardCompanyErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setGreencardCompanyErrorMsg("Будь ласка, заповніть усі поля, щоб оновити страхову компанію зеленої карти!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setGreencardCompanyDataEdited({id: 0, name: "", companyID: 0, productCode: 0, isCashbackEnabled: false, isHidden: false, cashbackPercentageValue: 0});
        setCashback(false);
        setCompanyHidden(false);
        setGreencardCompanyErrorMsg("");
    };

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати страхову компанію &#8212; {greencardCompanyName}</h1>
                        <div className="edit-dashboard-item-form">
                            <CustomInput type="text" name="name" label="Назва" placeholder="Назва" onDataChange={editCompanyNameChange} value={editGreencardCompanyData.name} />
                            <CustomInput type="number" name="companyID" label="Ідентифікатор компанії" placeholder="Ідентифікатор компанії" onDataChange={editCompanyIDChange} value={editGreencardCompanyData.companyID.toString()} />
                            <CustomInput type="number" name="productCode" label="Код продукту" placeholder="Код продукту" onDataChange={editProductCodeChange} value={editGreencardCompanyData.productCode.toString()} />
                            <div className="switch-wrapper">
                                <label className="switch-container">
                                    <SwitchComponent onChange={handleCashbackStateChange} checked={isCashback} onColor="#1BA876" />
                                    <div className="switch-text">Включити кешбек</div>
                                </label>
                            </div>
                            <div className="percentage-container">
                                <CustomInput type="number" name="cashbackPercentage" label="Кешбек (%)" id="percentage-field" placeholder="0" onDataChange={handleCashbackPercentageChange} value={editGreencardCompanyData.cashbackPercentageValue.toString()} 
                                    isReadOnly={!isCashback} ref={percentageInput} min={0} />
                                <i className="fa-solid fa-percent percentage-icon"></i>
                            </div>
                            <div className="switch-wrapper">
                                <label className="switch-container">
                                    <SwitchComponent onChange={handleHiddenStateChange} checked={isCompanyHidden} onColor="#1BA876" />
                                    <div className="switch-text">Є прихованою</div>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={() => navToGreencardCompaniesPage(dashboardNavigate, id, externalID)}>Зелена карта</button>
                        <button type="button" className="admin-btn" onClick={() => navToEpolicyCompaniesPage(dashboardNavigate, id, externalID)}>Автоцивілка</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && greencardCompanyErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{greencardCompanyErrorMsg}</div>}
                </>
            }
            </div>
        </div>
    );
};

export default EditGreencardCompany;