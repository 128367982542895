import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {IEpolicyFranchise} from "../../../typings/IDataOffers";
import {checkRequestStatus, checkValue, navToAdminPanel, navToEpolicyCompaniesPage, navToGreencardCompaniesPage, refreshPage, navToEpolicyCompany} from "../../../utils/utilsGeneral";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import SwitchComponent from "react-switch";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";

const EditEpolicyFranchise = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, epolicyID, productID, franchiseID} = useParams<{
        id: string;
        epolicyID: string;
        externalID: string; 
        productID: string; 
        franchiseID: string;
    }>();
    const [editEpolicyFranchiseData, setEpolicyFranchiseDataEdited] = useState<IEpolicyFranchise>({
        id: 0,
        companyName: "",
        franchiseName: "",
        productID: 0,
        franchise: 0,
        franchiseID: 0,
        isHidden: false
    });
    const [epolicyFranchiseErrorMsg, setEpolicyFranchiseErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [epolicyFranchiseName, setEpolicyFranchiseName] = useState("");
    const [isFranchiseHidden, setFranchiseHidden] = useState(false);

    useEffect(() => {
        const fetchEpolicyFranchise = async () => {
            try {
                const res = await dbAxiosInstance.get(`/epolicy/franchise/${encodeURIComponent(productID!)}/${encodeURIComponent(franchiseID!)}`);
                const dbFranchiseData = res.data;

                if (dbFranchiseData) {
                    if (dbFranchiseData.length > 0) {
                        const [editEpolicyFranchise] = dbFranchiseData;
                        setEpolicyFranchiseDataEdited({id: editEpolicyFranchise.id, companyName: editEpolicyFranchise.company_name, franchiseName: editEpolicyFranchise.franchise_name, productID: editEpolicyFranchise.product_id, 
                                                       franchise: editEpolicyFranchise.franchise, franchiseID: editEpolicyFranchise.franchise_id, isHidden: editEpolicyFranchise.is_hidden});
                        setEpolicyFranchiseName(`${editEpolicyFranchise.company_name}: ${editEpolicyFranchise.franchise_name}`);
                        setFranchiseHidden(Boolean(editEpolicyFranchise.is_hidden));
                        setEpolicyFranchiseErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setEpolicyFranchiseDataEdited({id: 0, companyName: "", franchiseName: "", productID: 0, franchise: 0, franchiseID: 0, isHidden: false});
                        setEpolicyFranchiseName("");
                        setFranchiseHidden(false);
                        setEpolicyFranchiseErrorMsg("Немає такої франшизи страхової компанії автоцивілки.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setEpolicyFranchiseDataEdited({id: 0, companyName: "", franchiseName: "", productID: 0, franchise: 0, franchiseID: 0, isHidden: false});
                    setEpolicyFranchiseName("");
                    setFranchiseHidden(false);
                    setEpolicyFranchiseErrorMsg("Помилка БД! Не вдалося завантажити франшизу страхової компанії автоцивілки.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setEpolicyFranchiseDataEdited({id: 0, companyName: "", franchiseName: "", productID: 0, franchise: 0, franchiseID: 0, isHidden: false});
                setEpolicyFranchiseName("");
                setFranchiseHidden(false);
                setEpolicyFranchiseErrorMsg("Помилка БД! Не вдалося завантажити франшизу страхової компанії автоцивілки.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchEpolicyFranchise();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const editCompanyNameChange = (event) => {
        setEpolicyFranchiseDataEdited((prev) => ({...prev, companyName: checkValue(event.target.value)}));
    };

    const editFranchiseNameChange = (event) => {
        setEpolicyFranchiseDataEdited((prev) => ({...prev, franchiseName: checkValue(event.target.value)}));
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editProductIDChange = (event) => {
        setEpolicyFranchiseDataEdited((prev) => ({...prev, productID: checkValue(event.target.value)}));
    };

    const editFranchiseChange = (event) => {
        setEpolicyFranchiseDataEdited((prev) => ({...prev, franchise: checkValue(event.target.value)}));
    };

    const editFranchiseIDChange = (event) => {
        setEpolicyFranchiseDataEdited((prev) => ({...prev, franchiseID: checkValue(event.target.value)}));
    };

    const handleHiddenStateChange = (isFranchiseHidden) => {
        setFranchiseHidden(isFranchiseHidden);
        setEpolicyFranchiseDataEdited((prev) => ({...prev, isHidden: isFranchiseHidden}));
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editEpolicyFranchiseData.companyName.toString().trim().length > 0 && editEpolicyFranchiseData.franchiseName.toString().trim().length > 0 && editEpolicyFranchiseData.productID > 0 && 
            editEpolicyFranchiseData.franchise.toString().trim().length > 0 && editEpolicyFranchiseData.franchiseID > 0) {
            try {
                const res = await dbAxiosInstance.put(`/epolicy/edit-franchise/${encodeURIComponent(productID!)}/${encodeURIComponent(franchiseID!)}`, editEpolicyFranchiseData);
                const editStatus = res.status;
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/epolicy/company/franchise/${epolicyID}/${productID}/${franchiseID}/item/edited`, {
                    state: {
                        isSuccess: checkRequestStatus(editStatus),
                        status: editStatus,
                        successMsg: res.data,
                        errorMsg: ""
                    }
                });
                setEpolicyFranchiseErrorMsg("");
                setErrorNotificationHidden(true);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setEpolicyFranchiseErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setEpolicyFranchiseErrorMsg("Будь ласка, заповніть усі поля, щоб оновити франшизу страхової компанії автоцивілки!");
            setErrorNotificationHidden(false);
        }
    };

    const clearFields = (event) => {
        event.preventDefault();
        setErrorNotificationHidden(true);
        setEpolicyFranchiseDataEdited({id: 0, companyName: "", franchiseName: "", productID: 0, franchise: 0, franchiseID: 0, isHidden: false});
        setFranchiseHidden(false);
        setEpolicyFranchiseErrorMsg("");
    };

    return (
        <div className="container">
            <div className="section-white">
            {authInProgress ?
                <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                :
                <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати франшизу &#8212; {epolicyFranchiseName}</h1>
                        <div className="edit-dashboard-item-form">
                            <CustomInput type="text" name="companyName" label="Назва компанії" placeholder="Назва страхової компанії" onDataChange={editCompanyNameChange} value={editEpolicyFranchiseData.companyName} />
                            <CustomInput type="text" name="franchiseName" label="Назва франшизи" placeholder="Назва франшизи" onDataChange={editFranchiseNameChange} value={editEpolicyFranchiseData.franchiseName} />
                            <CustomInput type="number" name="productID" label="Ідентифікатор продукту" placeholder="Ідентифікатор продукту" onDataChange={editProductIDChange} value={editEpolicyFranchiseData.productID.toString()} isReadOnly={true} />
                            <CustomInput type="number" name="franchise" label="Франшиза" placeholder="Франшиза" onDataChange={editFranchiseChange} value={editEpolicyFranchiseData.franchise.toString()} />
                            <CustomInput type="number" name="franchiseID" label="Ідентифікатор франшизи" placeholder="Ідентифікатор франшизи" onDataChange={editFranchiseIDChange} value={editEpolicyFranchiseData.franchiseID.toString()} />
                            <div className="switch-wrapper">
                                <label className="switch-container">
                                    <SwitchComponent onChange={handleHiddenStateChange} checked={isFranchiseHidden} onColor="#1BA876" />
                                    <div className="switch-text">Є прихованою</div>
                                </label>
                            </div>
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={() => navToEpolicyCompany(dashboardNavigate, id, externalID, productID, epolicyID)}><i className="fa-regular fa-circle-info notification-icon"></i>Переглянути компанію</button>
                        <button type="button" className="admin-btn" onClick={() => navToEpolicyCompaniesPage(dashboardNavigate, id, externalID)}>Автоцивілка</button>
                        <button type="button" className="admin-btn" onClick={() => navToGreencardCompaniesPage(dashboardNavigate, id, externalID)}>Зелена карта</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && epolicyFranchiseErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{epolicyFranchiseErrorMsg}</div>}
                </>
            }
            </div>
        </div>
    );
};

export default EditEpolicyFranchise;