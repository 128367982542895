import React, {useState} from "react";
import CustomTooltip from "../components/Forms/common/CustomTooltip";
import CustomInput from "../components/MainPage/CustomInput";

interface DataFilterProps {
    items: any[];
    onFilterCompleted: (state: boolean) => void;
    onProcessingFilter: (state: boolean) => void;
    onFilteredItems: (items: any) => void;
    placeholderText: string;
    itemNotFoundText: string;
    matchCase: boolean; 
}

const DataFilter = ({items, onFilterCompleted, onProcessingFilter, onFilteredItems, placeholderText, itemNotFoundText, matchCase} : DataFilterProps) => {
    const [search, setSearch] = useState("");
    const [updatedItems, setUpdatedItems] = useState<any[]>([]);

    const handleSearchChange = (event) => {
        onFilterCompleted(false);
        const searchData = event.target.value;
        setSearch(searchData);

        if (searchData) {
            onProcessingFilter(true);
            const updatedFilteredItems = items.filter(item => {
                return Object.values(item).some((value: any) =>
                    matchCase ? value?.toString().toLowerCase() === searchData.toLowerCase() : value?.toString().toLowerCase().includes(searchData.toLowerCase())
                );
            });
            onFilteredItems(updatedFilteredItems);
            setUpdatedItems(updatedFilteredItems);
        } else {
            onFilteredItems(items);
            onFilterCompleted(false);
        }
    };

    const resetSearch = () => {
        if (search) {
            setSearch("");
            onFilteredItems(items);
            onFilterCompleted(false);
        }
    };

    return (
        <>
            <div className="search-items-wrapper">
                <CustomInput type="text" name="data-search" placeholder={placeholderText} value={search} onDataChange={handleSearchChange} />
                <CustomTooltip msg="Очистити"><i className="fa-solid fa-xmark clear-search-icon" onClick={resetSearch}></i></CustomTooltip>
            </div>
            {search && <p className="search-items-found-count">Знайдено: <span className="font-bold">{updatedItems.length}</span></p>}
            {search && items.length > 0 && updatedItems.length === 0 && <div className="admin-error-notification">{itemNotFoundText}</div>}
        </>
    );
};

export default DataFilter;