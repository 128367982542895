import axios from "axios";
import isWebConfigOn from "to-boolean";
import {fetchEAgentToken} from "../redux-data/token/tokenReducer";
import {getEagentToken} from "../redux-data/misc/miscReducer";
import webConfig from "../config";

export const eAgentInstance = axios.create({
  baseURL: isWebConfigOn(webConfig.isDev) ? webConfig.insuranceLocalUrl : webConfig.insuranceBaseUrl
});

export const configEAgentAxios = (store: any) => {
  eAgentInstance.interceptors.request.use((value) => {
    const currentState = store.getState();
    const eAgentToken = getEagentToken(currentState);
    const token = {"Authorization": eAgentToken};
    
    (value.headers as any) = {
      ...value.headers,
      "Access-Control-Allow-Origin": "*",
      Accept: "application/vnd.cloud.v1+json",
      "Content-Type": "application/json",
      "Access-Control-Expose-Headers": "*, Authorization",
      "Access-Control-Allow-Headers": "*",
      "Access-Control-Allow-Credentials": "true",
      "Access-Control-Allow-Methods": "GET, POST, OPTIONS",
      ...token,
    };

    return value;
  });

  eAgentInstance.interceptors.response.use(
    async (value: any) => {
      const {data = {}}: any = value;
      const {httpStatusCode} = data;

      if (httpStatusCode === 401) {
          return handleError(value);
      }

      return value;
    },
    async (error) => {
      const {response} = error;

      if (response && response.status === 401) {
          return handleError(response);
      }

      throw error;
    },
  );

  const handleError = async (response: any) => {
    await store.dispatch(await fetchEAgentToken());
    return axios({
      ...response.config
    });
  };
};
