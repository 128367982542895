import {RootState} from "../index";

export const getDataOrderState = (state: RootState) => {
  return state.insurance.dataOrder;
};

export const getGreenCardDataOrderState = (state: RootState) => {
  return state.insurance.greenCardDataOrder;
};

export const getVehicleType = (state: RootState) => {
  return getGreenCardDataOrderState(state).vehicleType;
};

export const getVehicleCategory = (state: RootState) => {
  return getGreenCardDataOrderState(state).category;
};

export const getGreencardPeriod = (state: RootState) => {
  return getGreenCardDataOrderState(state).period;
};

export const getCoverageArea = (state: RootState) => {
  return getGreenCardDataOrderState(state).coverageArea;
};

export const getCalculatorAutoType = (state: RootState) => {
  if (getDataOrderState(state).typeAuto) {
      return getDataOrderState(state).typeAuto!.name + getDataOrderState(state).valueType.value;
  }

  return null;
};

export const getCalculatorAutoTypeDTO = (state: RootState) => {
  if (getDataOrderState(state).typeAuto) {
      return getDataOrderState(state).typeAuto!.name + getDataOrderState(state).valueType.value;
  }
  
  return null;
};

export const getAutoKind = (state: RootState) => {
  return getDataOrderState(state).typeAuto?.kind;
};

export const getPrivilege = (state: RootState) => {
  return getDataOrderState(state).privilege;
};

export const getIsForeign = (state: RootState) => {
  return getDataOrderState(state).foreign;
};

export const getIsTaxi = (state: RootState) => {
  return getDataOrderState(state).taxi;
};

export const getOtkDate = (state: RootState) => {
  return getDataOrderState(state).otkDate;
};

export const getIsPromocode = (state: RootState) => {
  return getDataOrderState(state).promocode;
};

export const getEpolicyPeriod = (state: RootState) => {
  return getDataOrderState(state).period;
};

export const getDontHaveInnCode = (state: RootState) => {
  return state.offer.data!.dontHaveCode;
};

export const getRightCityId = (state: RootState) => {
  return getIsForeign(state) ? null : (
    state.city.data!.id
  )
};

const getPolicyState = (state: RootState) => {
  return state.insurance.policies;
};

export const getPolicyId = (state: RootState) => {
  return getPolicyState(state).selectedPolicyId;
}

export const getReportId = (state: RootState) => {
  return getPolicyState(state).reportId;
};

export const getAnalyticsId = (state: RootState) => {
  return getPolicyState(state).analyticsId;
};

export const getProductCode = (state: RootState) => {
  return getPolicyState(state).productCode;
};

export const getPolicyCompany = (state: RootState) => {
  return getPolicyState(state).selectedPolicy!.company;
}

export const getPolicyInsurer = (state: RootState) => {
  return getPolicyState(state).selectedPolicy!.insurer;
}

export const getPolicyPeriod = (type: string, state: RootState) => {
  const period = type === "epolicy" ? getEpolicyPeriod(state) : getGreencardPeriod(state);
  
  if (period) {
      return type === "epolicy" ? {value: period.value, datePeriodType: period.type} : getPolicyState(state).selectedPolicy!.periods.find((p) => period.value === p.value);
  } else {
      return undefined;
  }
};

export const getOfferItems = (state: RootState) => {
  return getPolicyState(state).data;
};

export const getMainId = (state: RootState) => {
  return getPolicyState(state).selectedId;
};

export const getAmountId = (state: RootState) => {
  return getPolicyState(state).selectedAmountId;
};

export const getSelectedItem = (state: RootState) => {
  const mainId = getMainId(state);

  if (mainId === null) {
      return null;
  }

  return getOfferItems(state).find((item, index) => item.id === mainId)!;
};

export const getSelectedAmountItem = (state: RootState) => {
  const policy = getSelectedItem(state);
  
  if (policy === null) {
      return null;
  }

  const amountId = getAmountId(state);

  if (amountId === null) {
      return null;
  }

  return policy.data.find(item => item.id === amountId)!;
};

export const getPolicyPayment = (state: RootState) => {
  return getSelectedAmountItem(state)?.amount;
};

export const getPolicyDiscountedPayment = (state: RootState) => {
  return getSelectedAmountItem(state)?.discountedAmount;
};

export const getPolicyLoading = (state: RootState) => {
  return getPolicyState(state).isLoading;
};

export const getSalePoint = (state: RootState) => {
  if (state.user.data!.salePoint === undefined) {
      return null;
  }

  return state.user.data!.salePoint.id;
};

export const getTypeAuto = (state: RootState) => {
  return getDataOrderState(state).typeAuto.label
};

export const getDiscountPercentage = (payment, discountedPayment) => {
  return ((payment - discountedPayment) / payment) * 100;
};

export const getCashback = (payment, percent) => {
  return (payment * percent) / 100;
};