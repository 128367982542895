import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {getCurrentUser, getCurrentUserInfo} from "../redux-data/user/userReducer";
import {getEagentToken} from "../redux-data/misc/miscReducer";
import {checkAuthToken} from "../utils/utilsGeneral";
import {NavigateFunction} from "react-router-dom";

const useDashboardAuth = (dashboardNavigate: NavigateFunction) => {
    const currentUser = useSelector(getCurrentUser);
    const currentUserInfo = useSelector(getCurrentUserInfo);
    const eAgentToken = useSelector(getEagentToken);
    const [authInProgress, setAuthInProgress] = useState(false);

    useEffect(() => {
        const checkAdminRights = () => {
            if (checkAuthToken(eAgentToken)) {
                if (currentUser && currentUserInfo) {
                    setAuthInProgress(false);

                    if (!currentUserInfo?.permissions.salePointAdmin) {
                        dashboardNavigate("/");
                    }
                } else {
                    setAuthInProgress(true);
                }
            } else {
                dashboardNavigate("/");
            }
        };

        checkAdminRights();
    }, [currentUser, currentUserInfo]);

    return authInProgress;
};

export default useDashboardAuth;