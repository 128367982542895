import React from "react";
import {useSelector} from "react-redux";
import {featureVehicle, getInfoByType} from "../../core/typeAuto";
import {
  getDataOrderState,
  getGreenCardDataOrderState,
  getIsForeign
} from "../../redux-data/insurance/insuranceSelector";
import {getCityName, getRegisterCity} from "../../redux-data/city/cityReducer";
import {getVehicleData} from "../../redux-data/vehicle/vehicleReducer";

interface IDataOrderProps {
  value_type: string;
  feature_name: string;
  type_auto: string;
  privilege: boolean;
  register_city: string;
  period: string;
  area: string;
  taxi: boolean;
  promocode: boolean;
}

const DataOrder = React.memo(({type, handleChangeDataOrder}: {type: "epolicy" | "greencard", handleChangeDataOrder: any}) => {
  const data = useSelector(getDataOrderState);
  const greenCardData = useSelector(getGreenCardDataOrderState);
  const foreing = useSelector(getIsForeign);
  const vehicle = useSelector(getVehicleData);  
  const cityName = useSelector(getCityName);
  let res: IDataOrderProps = {} as any;
  res.register_city = useSelector(getRegisterCity);

  if (vehicle) {
      const info = getInfoByType(vehicle.type);
      res.type_auto = info.type_auto;

      if (type === "epolicy") {
          res.value_type = info.label;
          res.feature_name = info.name;
          res.privilege = data.privilege;
          res.taxi = data.taxi;
          res.promocode = data.promocode;
          res.period = data.period.label;
      } else {
          res.period = greenCardData.period.label;
          res.area = greenCardData.coverageArea.label;
      }
  } else {
      if (type === "epolicy") {
          const featureAuto = featureVehicle[data.typeAuto.value];
          res.feature_name = featureAuto.name;
          res.type_auto = data.typeAuto.label;
          res.value_type = data.valueType.label;
          res.privilege = data.privilege;
          res.taxi = data.taxi;
          res.promocode = data.promocode;
          res.period = data.period.label;
      } else {
          res.type_auto = greenCardData.vehicleType.label;
          res.period = greenCardData.period.label;
          res.area = greenCardData.coverageArea.label;
      }
  }

  if (foreing) {
      res.register_city = "Іноземне авто";
  }

  return (
    <>
      <h4>Дані замовлення</h4>
      <p className="subtitle green">Транспорт</p>
      <ul className="pay-choose_info-list">
        <li>
          <p>Тип транспортного засобу: </p>
          <span className="font-bold">{res.type_auto}</span>
        </li>
        {
            type === "epolicy" &&
            <li>
              <p>{res.feature_name}: </p>
              <span className="font-bold">{res.value_type}</span>
            </li>
        }
        <li>
          <p>Реєстрація: </p>
          <span className="font-bold">{cityName}</span>
        </li>
        <li>
          <p>Пільги: </p>
          <span className="font-bold">
            {res.privilege ? "Так" : "Нi"}
          </span>
        </li>
        <li>
          <p>Таксі: </p>
          <span className="font-bold">
            {res.taxi ? "Так" : "Ні"}
          </span>
        </li>
        <li>
          <p>Промокод: </p>
          <span className="font-bold">
            {res.promocode ? "Так" : "Ні"}
          </span>
        </li>
        <li>
          <p>Термін дії: </p>
          <span className="font-bold">{res.period}</span>
        </li>
        {
            type === "greencard" &&
            <li>
              <p>Територія покриття: </p>
              <span className="font-bold">{res.area}</span>
            </li>
        }
      </ul>
      <button onClick={handleChangeDataOrder} className="btn btn-link btn-edit">Змінити</button>
    </>
  )
});

export default DataOrder;
