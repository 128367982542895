import React, {useEffect, useRef, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {getMaxDate, getCurrentDateTime} from "../../../utils/utilsDate";
import CustomField from "../../../components/MainPage/CustomField";
import useForm from "react-hook-form";
import SwitchComponent from "react-switch";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {refreshPage, checkValue, navToAdminPanel, findSalePointByID} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {Promocode, CustomSelectData} from "../../../typings/IDataOffers";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";
import CustomSelect from "../../../components/common/CustomSelect";
import {salePoints} from "../../../mock-data/insuranceTypes";

const EditPromocodePage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const editMaxActivationsCountInput = useRef<HTMLInputElement>(null);
    const {id, externalID, promocode, promocodeID} = useParams<{
        id: string;
        externalID: string;
        promocode: string;
        promocodeID: string;
    }>();
    const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [editPromocode, setPromocodeEdited] = useState<Pick<Promocode, "maxActivations" | "validUntil" | "discount" | "isUsed" | "salePointID">>({
        maxActivations: 0,
        validUntil: "",
        discount: 0,
        isUsed: false,
        salePointID: 0
    });
    const [promocodeValidUntilMsg, setPromocodeValidUntilMsg] = useState("");
    const [isPromocodeUsed, setIsPromocodeUsed] = useState(false);
    const [selectedSalePoint, setSelectedSalePoint] = useState<CustomSelectData>({
        value: 0,
        label: "",
        isDisabled: false
    });
    const [salePointError, setSalePointError] = useState(false);
    
    const {register, errors, clearError} = useForm({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    useEffect(() => {
        const fetchPromocode = async () => {
            try {
                const res = await dbAxiosInstance.get(`/promocode/${encodeURIComponent(promocode!.toUpperCase())}/${encodeURIComponent(promocodeID!)}`);
                const dbPromocodeData = res.data;

                if (dbPromocodeData) {
                    if (dbPromocodeData.length > 0) {
                        const [promocodeItem] = dbPromocodeData; 
                        setPromocodeEdited({maxActivations: promocodeItem.max_activations, validUntil: promocodeItem.valid_until, discount: promocodeItem.discount, isUsed: promocodeItem.is_used, salePointID: promocodeItem.sale_point_id});
                        setIsPromocodeUsed(Boolean(promocodeItem.is_used));
                        setSelectedSalePoint(findSalePointByID(promocodeItem.sale_point_id)!);
                        setPromocodeValidUntilMsg("Відредагувати дійсний до");
                        setPromocodeErrorMsg("");
                    } else {
                        setPromocodeEdited({maxActivations: 0, validUntil: "", discount: 0, isUsed: false, salePointID: 0});
                        setPromocodeValidUntilMsg("Відредагувати дійсний до");
                        setPromocodeErrorMsg("Немає такого промокоду. Ви можете додати промокод через панель адміністратора.");
                    }
                } else {
                    setPromocodeEdited({maxActivations: 0, validUntil: "", discount: 0, isUsed: false, salePointID: 0});
                    setPromocodeValidUntilMsg("Відредагувати дійсний до");
                    setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити дані промокоду.");
                }
            } catch (error: any) {
                setPromocodeEdited({maxActivations: 0, validUntil: "", discount: 0, isUsed: false, salePointID: 0});
                setPromocodeValidUntilMsg("Відредагувати дійсний до");
                setPromocodeErrorMsg("Помилка БД! Не вдалося завантажити дані промокоду.");
            }
        };

        fetchPromocode();
    }, []);

    useEffect(() => {
        const notifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(notifTimer);
    }, [hideErrorNotification]);

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editPromocode.maxActivations > 0 && editPromocode.validUntil.length > 0 && editPromocode.discount > 0 && editPromocode.salePointID > 0) {
            try {
                const editPessPromocode = {
                    ...editPromocode,
                    edited: getCurrentDateTime()
                };

                await dbAxiosInstance.put(`/promocode/${encodeURIComponent(promocode!.toUpperCase())}/${encodeURIComponent(promocodeID!)}`, editPessPromocode);
                setPromocodeErrorMsg("");
                setErrorNotificationHidden(true);
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/view-promocode/${promocode}/${promocodeID}`);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setPromocodeErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
                checkSalePointError();
            }
        } else {
            setPromocodeErrorMsg("Будь ласка, заповніть усі поля, щоб оновити промокод!");
            setErrorNotificationHidden(false);
            checkSalePointError();
        }
    };

    const clearFields = (event) => {
        setPromocodeEdited({maxActivations: 0, validUntil: "", discount: 0, isUsed: false, salePointID: 0});
        setPromocodeValidUntilMsg("Відредагувати дійсний до");
        setSalePointError(false);

        if (editMaxActivationsCountInput.current) {
            editMaxActivationsCountInput.current.value = "";
        }
    };

    const checkSalePointError = () => {
        if (editPromocode.salePointID === 0) {
            setSalePointError(true);
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const navToPromocodesPage = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/promocodes`);
    };

    const handleMaxActivationsChange = (event) => {
        setPromocodeEdited((prev) => ({...prev, maxActivations: checkValue(event.target.value)}));
    };

    const handlePromocodeValidUntil = (name, date) => {
        setPromocodeEdited((prev) => ({...prev, validUntil: date}));
        clearError(name);
    };

    const handleDiscountChange = (event) => {
        setPromocodeEdited((prev) => ({...prev, discount: event.target.value}));
    };

    const handleIsUsedChange = (isUsed) => {
        setIsPromocodeUsed(isUsed)
        setPromocodeEdited((prev) => ({...prev, isUsed: isUsed}));
    };

    const handleSalePointChange = (salePoint) => {
        setSelectedSalePoint(salePoint);
        setPromocodeEdited((prev) => ({...prev, salePointID: salePoint.value}));
        setSalePointError(false);
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати промокод &#8212; {promocode?.toUpperCase()}</h1>
                        <div className="edit-dashboard-item-form">
                            <CustomInput 
                                type="number" 
                                name="name" 
                                label="Редагувати максимальну кількість активацій" 
                                placeholder="Редагувати максимальну кількість активацій" 
                                value={editPromocode.maxActivations.toString()} 
                                onDataChange={handleMaxActivationsChange} 
                                ref={editMaxActivationsCountInput}
                                isReadOnly={true}
                            />
                            <div className="percentage-container">
                                <CustomInput type="number" name="discount" label="Знижка (%)" id="percentage-field" placeholder="0" onDataChange={handleDiscountChange} value={editPromocode.discount.toString()} min={0} />
                                <i className="fa-solid fa-percent percentage-icon"></i>
                            </div>
                            <div className="switch-wrapper">
                                <label className="switch-container">
                                    <SwitchComponent onChange={handleIsUsedChange} checked={isPromocodeUsed} onColor="#1BA876" />
                                    <div className="switch-text">Є використаний</div>
                                </label>
                            </div>
                            <div className="col-md-4">
                                <div className="form-group">
                                    <label className={salePointError ? "select-error-label" : ""}>Редагувати точку продажу</label>
                                    <CustomSelect
                                        options={salePoints}
                                        handleChange={handleSalePointChange}
                                        value={selectedSalePoint}
                                        isCreatable={false}
                                        isDisabled={false}
                                        placeholder="Точка продажу"
                                        isError={salePointError}
                                    />
                                </div>
                            </div>
                            <CustomField register={register({required: "Це поле обов'язкове"})} errors={errors} name="validUntil" label={promocodeValidUntilMsg} type="date" placeholder="РРРР.ММ.ДД" max={getMaxDate()} autoComplete="off" 
                                dateValue={editPromocode.validUntil} onDateChange={handlePromocodeValidUntil} />
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={navToPromocodesPage}>Промокоди</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && promocodeErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{promocodeErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditPromocodePage;