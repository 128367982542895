export type VehicleTypeList = {
    id: number;
    label: string;
    code: string;
    subcategories: string[];
    value: string;
};

export type PeriodsTypeList = {
    id: number;
    label: string;
    code: string;
    value: number;
    type: string;
};

export type AreasTypeList = {
    id: number;
    label: string;
    value: string;
};

export const vehicleTypes: VehicleTypeList[] = [
    {
        id: 0,
        label: "Легкові автомобілі",
        code: "A",
        value: "CAR",
        subcategories: ["B"],
    },
    {
        id: 1,
        label: "Мотоцикли",
        code: "B",
        value: "MOTO",
        subcategories: ["A"]
    },
    {
        id: 2,
        label: "Вантажні автомобілі",
        code: "C",
        value: "FREIGHT",
        subcategories: ["C"]
    },
    {
        id: 3,
        label: "Автобуси",
        code: "E",
        value: "PASSENGER",
        subcategories: ["D"]
    },
    {
        id: 4,
        label: "Причіпи, напівпричіпи",
        code: "F",
        value: "TRAILER",
        subcategories: ["E", "F"]
    },
]

export const epolicyPeriods: PeriodsTypeList[] = [
    {
        id: 0,
        code: "SIX_MONTHS",
        label: "6 місяців",
        value: 6,
        type: "MONTHS"
    },
    {
        id: 1,
        code: "YEAR",
        label: "рік",
        value: 12,
        type: "MONTHS"
    }
];

export const greencardPeriods: PeriodsTypeList[] = [
    {
        id: 0,
        code: "FIFTEEN_DAYS",
        label: "15 днів",
        value: 15,
        type: "DAYS"
    },
    {
        id: 1,
        code: "TWENTY_ONE_DAYS",
        label: "21 день",
        value: 21,
        type: "DAYS"
    },
    {
        id: 1,
        code: "ONE_MONTH",
        label: "1 місяць",
        value: 1,
        type: "MONTHS"
    },
    {
        id: 2,
        code: "TWO_MONTHS",
        label: "2 місяці",
        value: 2,
        type: "MONTHS"
    },
    {
        id: 3,
        code: "THREE_MONTHS",
        label: "3 місяці",
        value: 3,
        type: "MONTHS"
    },
    {
        id: 4,
        code: "FOUR_MONTHS",
        label: "4 місяці",
        value: 4,
        type: "MONTHS"
    },
    {
        id: 5,
        code: "FIVE_MONTHS",
        label: "5 місяців",
        value: 5,
        type: "MONTHS"
    },
    {
        id: 6,
        code: "SIX_MONTHS",
        label: "6 місяців",
        value: 6,
        type: "MONTHS"
    },
    {
        id: 7,
        code: "SEVEN_MONTHS",
        label: "7 місяців",
        value: 7,
        type: "MONTHS"
    },
    {
        id: 8,
        code: "EIGHT_MONTHS",
        label: "8 місяців",
        value: 8,
        type: "MONTHS"
    },
    {
        id: 9,
        code: "NINE_MONTHS",
        label: "9 місяців",
        value: 9,
        type: "MONTHS"
    },
    {
        id: 10,
        code: "TEN_MONTHS",
        label: "10 місяців",
        value: 10,
        type: "MONTHS"
    },
    {
        id: 11,
        code: "ELEVEN_MONTHS",
        label: "11 місяців",
        value: 11,
        type: "MONTHS"
    },
    {
        id: 12,
        code: "YEAR",
        label: "рік",
        value: 12,
        type: "MONTHS"
    }
]

export const areas: AreasTypeList[] = [
    {
        id: 0,
        label: "Молдова Азербайджан",
        value: "BMR"
    },
    {
        id: 1,
        label: "Європа",
        value: "EUROPE"
    }
]