import {combineReducers} from "redux";
import {CreatorReducer} from "../base/base";
import {IBaseActions} from "../base/baseTypes";
import {RootState} from "../index";
import {
  getCalculatorAutoType,
  getCoverageArea,
  getIsForeign,
  getIsTaxi, 
  getOtkDate, 
  getEpolicyPeriod,
  getGreencardPeriod,
  getPrivilege,
  getRightCityId, 
  getVehicleType
} from "./insuranceSelector";
import {getVehicleAutoType} from "../vehicle/vehicleReducer";
import {ICompanyInsurance, IDataOffer, IDataNumberOffer, IDataGreencardOffer} from "../../typings/IDataOffers";
import {getCorrectDate, getFinishToDate, getDay} from "../../utils/utilsDate";
import {IPolicyState} from "./insuranceTypes";
import {getSalePointId} from "../user/userSelector";
import serviceEAgent from "../../service/serviceEAgent";

const init = {
  data: [],
  selectedId: null,
  selectedAmountId: null,
  selectedPolicyId: null,
  selectedPolicy: null,
  productCode: null,
  reportId: null,
  analyticsId: null,
  error: null,
  isLoading: false,
  insuranceErrorMsg: ""
};

interface IOrderActions {
  setDataOrder: any;
  orderClear: any;
}

interface IGreenCardOrderActions {
  setGreenCardDataOrder: any;
  greencardClear: any;
}

interface IPolicyActions extends IBaseActions {
  setItemId: any;
  setAmountId: any;
  setPolicyId: any;
  setPolicy: any;
  setProductCode: any;
  setReportId: any;
  setAnalyticsId: any;
  setInsuranceErrorMsg: any;
  policyClear: any;
}

const creatorDataOrder = new CreatorReducer<IOrderActions, {}>("order");
creatorDataOrder.addAction("SET_DATA_ORDER", (state, action) => {
  return {...state, ...action.payload};
});
creatorDataOrder.addAction("ORDER_CLEAR", (state) => {
  return init;
});

const creatorGreenCardDataOrder = new CreatorReducer<IGreenCardOrderActions, {}>("order");
creatorGreenCardDataOrder.addAction("SET_GREEN_CARD_DATA_ORDER", (state, action) => {
  return {...state, ...action.payload};
});
creatorGreenCardDataOrder.addAction("GREENCARD_CLEAR", (state) => {
  return init;
});

const {setDataOrder, orderClear} = creatorDataOrder.createActions();
const dataOrderReducer = creatorDataOrder.createReducer({});

const {setGreenCardDataOrder, greencardClear} = creatorGreenCardDataOrder.createActions();
const greenCardDataOrderReducer = creatorGreenCardDataOrder.createReducer({});

const creatorPolicy = new CreatorReducer<IPolicyActions, IPolicyState>("policy");
creatorPolicy.addAction("SET_ITEM_ID", (state, action) => {
  return {...state, selectedId: action.payload};
});
creatorPolicy.addAction("SET_AMOUNT_ID", (state, action) => {
  return {...state, selectedAmountId: action.payload};
});
creatorPolicy.addAction("SET_POLICY_ID", (state, action) => {
  return {...state, selectedPolicyId: action.payload};
});
creatorPolicy.addAction("SET_POLICY", (state, action) => {
  return {...state, selectedPolicy: action.payload};
});
creatorPolicy.addAction("SET_PRODUCT_CODE", (state, action) => {
  return {...state, productCode: action.payload ? Number(action.payload) : null};
});
creatorPolicy.addAction("SET_REPORT_ID", (state, action) => {
  return {...state, reportId: action.payload};
});
creatorPolicy.addAction("SET_ANALYTICS_ID", 
(state, action) => {
  return {...state, analyticsId: action.payload};
});
creatorPolicy.addAction("SET_INSURANCE_ERROR_MSG",
(state, action) => {
  return {...state, insuranceErrorMsg: action.payload}
});
creatorPolicy.addAction("POLICY_CLEAR", () => {
  return init;
});

const {setLoading, setError, setData, setItemId, setPolicy, setAmountId, setPolicyId, setReportId, setAnalyticsId, setProductCode, setInsuranceErrorMsg, policyClear} = creatorPolicy.createActions();
const itemsReducer = creatorPolicy.createReducerFetch(init);

const loadPolicies = (type: "epolicy" | "greencard") => async (dispatch: any, getState: any) => {
  dispatch(setLoading(true));
  const state = getState() as RootState;

  const setCustomerCategory = () => {
    if (getPrivilege(state)) {
        return "PRIVILEGED";
    }

    return "NATURAL";
  };

  const getEpolicyCalculatorOfferData = (vehicleType) => {
    const data: IDataOffer = {
      salePoint: getSalePointId(state),
      customerCategory: setCustomerCategory(),
      bonusMalus: null,
      taxi: getIsTaxi(state),
      autoCategory: vehicleType ? vehicleType : "",
      discount: 0,
      registrationPlace: getRightCityId(state),
      outsideUkraine: getIsForeign(state),
      dateFrom: getCorrectDate(getDay()),
      driveExp: false,
      dateTo: getFinishToDate(),
      contractPeriod: getEpolicyPeriod(state).code,
      registrationType: "PERMANENT",
      otkDate: getOtkDate(state)
    };

    return data;
  };

  const getEpolicyNumberOfferData = () => {
    const data: IDataNumberOffer = {
      customerCategory: setCustomerCategory(),
      dateFrom: getCorrectDate(getDay()),
      stateNumber: state.vehicle.data!.regNumber,
      registrationType: "PERMANENT",
      contractPeriod: getEpolicyPeriod(state).code,
      taxi: getIsTaxi(state),
      otkDate: getOtkDate(state),
      calculateExternalTariff: false
    };

    return data;
  };

  const getGreencardOfferData = () => {
    const data: IDataGreencardOffer = {
      salePoint: getSalePointId(state),
      customerCategory: setCustomerCategory(),
      dateFrom: getCorrectDate(getDay()),
      area: getCoverageArea(state).value,
      type: getVehicleType(state).code,
      period: getGreencardPeriod(state).code
    };

    return data;
  };

  try {
    let res: ICompanyInsurance[] = [];
    let data: any = {};

    if (type === "epolicy") {
        let vehicleType = getCalculatorAutoType(state);
        
        if (vehicleType) {
            data = getEpolicyCalculatorOfferData(vehicleType);
            res = await serviceEAgent.getOffers(type, data);
        } else {
            if (state.vehicle.data?.registrationPlace?.id && state.vehicle.data?.registrationPlace?.name) {
                data = getEpolicyNumberOfferData();
                res = await serviceEAgent.getOffersByNumber(data);
            } else {
                vehicleType = getVehicleAutoType(state);
                data = getEpolicyCalculatorOfferData(vehicleType);
                res = await serviceEAgent.getOffers(type, data);
            }
        }
    } else {
        data = getGreencardOfferData();
        res = await serviceEAgent.getOffers(type, data);
    }

    dispatch(setData(res));
  } catch (ex: any) {
    dispatch(setError(ex));
    dispatch(setInsuranceErrorMsg(ex.message));
  } finally {
    dispatch(setLoading(false));
  }
};

const getInsuranceErrorMsg = (state: RootState) => state.insurance.policies.insuranceErrorMsg;

export {
  loadPolicies, 
  setError, 
  setDataOrder, 
  setGreenCardDataOrder, 
  setItemId, 
  setAmountId, 
  setPolicyId, 
  setPolicy, 
  setReportId, 
  setAnalyticsId, 
  setProductCode, 
  setInsuranceErrorMsg, 
  getInsuranceErrorMsg, 
  orderClear,
  greencardClear,
  policyClear
};

export default combineReducers({
  dataOrder: dataOrderReducer,
  policies: itemsReducer,
  greenCardDataOrder: greenCardDataOrderReducer
});