import React, {useState, useEffect, useRef} from "react";
import {useDispatch, useSelector} from "react-redux";
import useForm from "react-hook-form";
import {useId} from "react-id-generator";
import {getInfoByType, getOptionsByKind, getGreenCardType} from "../../core/typeAuto";
import {useStepsContext} from "../../utils/StepsProvider";
import {hasCityZone, setData, setError} from "../../redux-data/city/cityReducer";
import {AutoComplete} from "../MainPage/AutoComplete/AutoComplete";
import {getPrivilege, getIsForeign} from "../../redux-data/insurance/insuranceSelector";
import {config} from "../../assets/config";
import {getVehicle, getVehicleData, getVehicleError, getVehicleLoading, setVehicleSpecs} from "../../redux-data/vehicle/vehicleReducer";
import {setOfferMark, setOfferModel} from "../../redux-data/offer/offerReducer";
import {setDataOrder} from "../../redux-data/insurance/insuranceReducer";
import CustomField from "../MainPage/CustomField";
import webConfig from "../../config";
import {RootState} from "../../redux-data";
import {areas, greencardPeriods, VehicleTypeList, PeriodsTypeList, AreasTypeList, epolicyPeriods} from "../../core/insuranceData";
import {setGreenCardDataOrder} from "../../redux-data/insurance/insuranceReducer";
import RegCities from "./common/RegCities";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {fetchVehicleData, validateOrderData, checkVehicleBrand, findDBVehicleModel, fetchVehicleModelByBrandID, isVehicleOtkRequired, checkRequestStatus, isElectricMotorVehicle} from "../../utils/utilsGeneral";
import CustomLoader from "./common/CustomLoader";
import {IVehicle} from "../../redux-data/vehicle/vehicleTypes";
import CustomSelect from "../common/CustomSelect";
import {getCurrentDateTime, getMaxDate, compareOtkDate, isDateValid} from "../../utils/utilsDate";
import {clearUserPrivilegeType, getCurrentUserInfo} from "../../redux-data/user/userReducer";
import {getCustomerPrivilege} from "../../redux-data/user/userSelector";
import {IDataForm} from "../../typings/IDataOffers";
import PublicModal from "../common/Modals/PublicModal";
import {setPromocodeName, setPromocodeIsActivated, setPromocodeSalePointId} from "../../redux-data/promocode/promocodeReducer";

const NumberAutoForm = (props) => {
  const isCityZone = useSelector(hasCityZone);
  const vehicle = useSelector(getVehicleData);
  const isLoading = useSelector(getVehicleLoading);
  const privilege = useSelector(getPrivilege);
  const regError = useSelector(getVehicleError);
  const isForeign = useSelector(getIsForeign);
  const customerPrivilege = useSelector(getCustomerPrivilege);
  const currentUserInfo = useSelector(getCurrentUserInfo);
  const [vehicleEngineOptions, setVehicleEngineOptions] = useState([{}]);
  const [engineType, setEngineType] = useState("");
  const [privilegeState, setPrivilegeState] = useState(false);
  const [isPrivilegeError, setPrivilegeError] = useState(false);
  const [privilegeErrorMsg, setPrivilegeErrorMsg] = useState("");
  const privilegeCategories = webConfig.disallowedPrivilegeCategories.split(", ");
  const epolicyData = useSelector(((state: RootState) => state.insurance.dataOrder));
  const greenCardData = useSelector(((state: RootState) => state.insurance.greenCardDataOrder));
  const [vehicleType, setVehicleType] = useState<VehicleTypeList>(greenCardData.vehicleType);
  const [coverageArea, setCoverageArea] = useState<AreasTypeList>(greenCardData.coverageArea);
  const [greencardPeriod, setGreencardPeriod] = useState<PeriodsTypeList>(greenCardData.period);
  const [promocodeState, setPromocodeState] = useState(false);
  const [promocode, setPromocode] = useState("");
  const vehicleRegLocationInput = useRef<HTMLInputElement>(null);
  const [city, setCity] = useState("");
  const [touch, setTouch] = useState(false);
  const [engineTypeError, setEngineTypeError] = useState(false);
  const [greencardPeriodError, setGreencardPeriodError] = useState(false);
  const [coverageAreaError, setCoverageAreaError] = useState(false);
  const [vehicleOtkDate, setVehicleOtkDate] = useState("");
  const [isVehicleValidOtkDate, setIsVehicleValidOtkDate] = useState(false);
  const [vehicleOtkDateErrorMsg, setVehicleOtkDateErrorMsg] = useState("");
  const [promocodeErrorMsg, setPromocodeErrorMsg] = useState("");
  const [promocodeSuccessMsg, setPromocodeSuccessMsg] = useState("");
  const [isPromocodeVerification, setIsPromocodeVerification] = useState(false);
  const [isPromocodeVerified, setPromocodeVerified] = useState(false);
  const [epolicyPeriod, setEpolicyPeriod] = useState<PeriodsTypeList>(epolicyData.period);
  const [epolicyPeriodError, setEpolicyPeriodError] = useState(false);

  const [vehicleInfo, setVehicleInfo] = useState<IVehicle>({
    engineVolume: 0,
    year: 0,
    vin: "",
    category: "",
    kind: "",
    brand: "",
    model: "",
    modelText: "",
    stateNumber: "",
    dontHaveVIN: false,
    lastModified: "",
    autoColor: "",
    grossWeight: 0,
    curbWeight: 0,
    seatingCapacity: 0,
    electricMotorPower: 0,
    registrationPlace: {
      placeCode: 0,
      zone: 0,
      nameFull: "",
      id: 0,
      country: "",
      name: "",
      nameRus: "",
      nameFullRus: "",
      cdbMtibu: false,
      cdbMtibuCode: 0,
      lastModified: ""
    },
    isForeignReg: false
  });

  const {register, handleSubmit, errors, clearError} = useForm<IDataForm>({
    reValidateMode: "onChange",
    mode: "onChange",
    defaultValues: {
      privilege
    }
  });

  const handleChangeEngineType = (e: any) => {
    vehicle.type = e.value;
    setEngineType(e);
    validatePrivilege(privilegeState);
    validateGreenCardVehicleType(vehicle.type);
    setEngineTypeError(false);
  };

  const handlePrivilegeChange = (event) => {
    const isPrivilegeChecked = event.target.checked;
    setPrivilegeState(isPrivilegeChecked);
    validatePrivilege(isPrivilegeChecked);

    if (isPrivilegeChecked && customerPrivilege) {
        dispatch(clearUserPrivilegeType());
    }
  };

  const handlePromocodeStateChange = (event) => {
    setPromocodeState(event.target.checked);
  };

  const validatePrivilege = (isChecked) => {
    if (isChecked && (vehicle.type === privilegeCategories[0] || vehicle.type === privilegeCategories[1])) {
        setPrivilegeError(true);
        setPrivilegeErrorMsg("Об'єм двигуна для пільговиків не може перевищувати 2500");
    } else {
        setPrivilegeError(false);
        setPrivilegeErrorMsg(""); 
    }
  };

  const handleProcodeChange = async (event) => {
    event.target.value = event.target.value.toUpperCase();
    const customerPromocode = event.target.value.trim();

    if (customerPromocode.length === Number(webConfig.customerPromocodeLength)) {
        try {
            setIsPromocodeVerification(true);
            const promocodeRes = await dbAxiosInstance.get(`/verify/promocode/${encodeURIComponent(customerPromocode)}`);
            const dbPromocodeData = promocodeRes.data;

            if (dbPromocodeData.length > 0) {
                const [pessPromocode] = dbPromocodeData;
                const isPromocodeValid = !pessPromocode.is_used && pessPromocode.activations < pessPromocode.max_activations && isDateValid(pessPromocode.valid_until) && pessPromocode.sale_point_id === currentUserInfo?.salePoint.id;

                if (isPromocodeValid) {
                    const promocodeActivations = ++pessPromocode.activations;

                    const activatedPromocodeData = {
                      activations: promocodeActivations,
                      isUsed: promocodeActivations >= pessPromocode.max_activations ? true : false,
                      activated: getCurrentDateTime()
                    };

                    const activatedPromocodeRes = await dbAxiosInstance.put(`/activate/verified-promocode/${encodeURIComponent(pessPromocode.id)}/${encodeURIComponent(pessPromocode.promocode)}/${encodeURIComponent(pessPromocode.sale_point_id)}`, activatedPromocodeData);
                    const activatedPromcodeStatus = activatedPromocodeRes.status;
                    
                    if (checkRequestStatus(activatedPromcodeStatus)) {
                        setPromocodeVerified(true);
                        setPromocodeSuccessMsg(`Ваш промокод успішно прийнято! По промокоду у Вас доступна знижка ${pessPromocode.discount}% у платіжній системі.`);
                        setPromocodeErrorMsg("");
                        setPromocode(customerPromocode);
                        dispatch(setPromocodeName(pessPromocode.promocode));
                        dispatch(setPromocodeIsActivated(true));
                        dispatch(setPromocodeSalePointId(pessPromocode.sale_point_id));
                    } else {
                        setPromocodeVerified(false);
                        setPromocodeSuccessMsg("");
                        setPromocodeErrorMsg("Ваш промокод недійсний або вже використаний!");
                        setPromocode("");
                    }
                } else {
                    setPromocodeVerified(false);
                    setPromocodeSuccessMsg("");
                    setPromocodeErrorMsg("Ваш промокод недійсний або вже використаний!");
                    setPromocode("");
                }
            } else {
                setPromocodeVerified(false);
                setPromocodeSuccessMsg("");
                setPromocodeErrorMsg("Ваш промокод недійсний або вже використаний!");
                setPromocode("");
            }
        } catch (error: any) {
            setPromocodeVerified(false);
            setPromocodeSuccessMsg("");
            setPromocodeErrorMsg("Помилка! Не вдалось перевірити Ваш промокод!");
            setPromocode("");
        }
    } else {
        setPromocodeVerified(false);
        setPromocodeErrorMsg("");
        setPromocodeSuccessMsg("");
        setPromocode("");
    }
  };

  const [checkboxId] = useId(1, "checkbox");
  const [formId] = useId(1, "form");

  const dispatch = useDispatch();
  const {goTo} = useStepsContext() as any;

  const onSubmit = async (data: any) => {    
    if (!vehicle || (vehicle && !vehicle.type)) {
        await dispatch(getVehicle(data.register_auto));
    } else {
        addVehicleData(vehicleInfo);

        if (props.insuranceType === "epolicy") {
            if (((vehicle.registrationPlace?.id && vehicle.registrationPlace?.name || city.length > 0 && isCityZone) && vehicle.type && isVehicleOtkRequired(vehicle.kind, vehicle.type) && vehicleOtkDate && vehicle.grossWeight && vehicle.curbWeight && 
                vehicle.seatingCapacity && epolicyPeriod) || ((vehicle.registrationPlace?.id && vehicle.registrationPlace?.name || city.length > 0 && isCityZone) && vehicle.type && !isVehicleOtkRequired(vehicle.kind, vehicle.type) && !vehicleOtkDate && 
                vehicle.grossWeight && vehicle.curbWeight && vehicle.seatingCapacity && epolicyPeriod)) {
                setEngineTypeError(false);
                setEpolicyPeriodError(false);
                dispatch(setDataOrder({...data, period: epolicyPeriod}));
                goTo(2);
            } else {
                if (!vehicle.type) {
                    setEngineTypeError(true);
                }

                if (!epolicyPeriod) {
                    setEpolicyPeriodError(true);
                }

                if (vehicleRegLocationInput.current) {
                    vehicleRegLocationInput.current.focus();

                    if (vehicleRegLocationInput.current.value.length === 0) {
                        setCity("");
                        setTouch(false);
                        dispatch(setError({
                          message: "Це поле обов'язкове"
                        }));
                    }
                }
            }
        } else if (props.insuranceType === "greencard") {
            if ((vehicle.registrationPlace?.id && vehicle.registrationPlace?.name || (city.length > 0 && isCityZone)) && vehicleType && coverageArea && greencardPeriod) {
                dispatch(setGreenCardDataOrder({
                  ...data,
                  vehicleType,
                  coverageArea,
                  period: greencardPeriod
                }));
                goTo(2);
            } else {
                if (!vehicleType) {
                    setEngineTypeError(true);
                }

                if (!greencardPeriod) {
                    setGreencardPeriodError(true);
                }

                if (!coverageArea) {
                    setCoverageAreaError(true);
                }

                if (vehicleRegLocationInput.current) {
                    vehicleRegLocationInput.current.focus();

                    if (vehicleRegLocationInput.current.value.length === 0) {
                        setTouch(false);
                        dispatch(setError({
                          message: "Це поле обов'язкове"
                        }));
                    }
                }
            }
        }
    }
  };

  const addVehicleData = async (currentVehicle) => {
    await dbAxiosInstance.post("/add-vehicle/number", currentVehicle);
  };

  const handleChangeCoverageArea = (e: any) => {
      setCoverageArea(e);
      setCoverageAreaError(false);
  };

  const handleGreencardPeriodChange = (e: any) => {
      setGreencardPeriod(e);
      setGreencardPeriodError(false);
  };

  useEffect(() => {
    const handle = async () => {
      const {modelName, brandName, name, id} = vehicle;

      if (brandName && modelName && name && id) {
          const vehicleBrand = await checkVehicleBrand(name);

          if (vehicleBrand) {
              dispatch(setOfferMark(vehicleBrand));
              const models = await fetchVehicleModelByBrandID(vehicleBrand.id);
              const findModel = findDBVehicleModel(models, modelName);

              if (findModel) {
                  dispatch(setOfferModel(findModel));
              } else {
                  const vehicleData = fetchVehicleData(vehicleBrand.name, modelName);
                  dispatch(setOfferModel(vehicleData));
              }
          } else {
              const dbVehicleData = fetchVehicleData(name, modelName);              
              dispatch(setOfferMark(dbVehicleData));
              dispatch(setOfferModel(dbVehicleData));
          }
      }
    };

    const validateVehicleType = (kind) => {
      setVehicleEngineOptions(getOptionsByKind(kind));
    };

    if (vehicle) {
        handle();

        if (vehicle.type) {
            validateGreenCardVehicleType(vehicle.type);
        } else {
            validateVehicleType(vehicle.kind);
        }

        if (vehicle.registrationPlace?.id && vehicle.registrationPlace?.name) {
            dispatch(setData(vehicle.registrationPlace));
        }

        setVehicleInfo((prev) => ({...prev, engineVolume: vehicle.engineVolume, year: vehicle.year, vin: vehicle.vin, category: vehicle.type, kind: vehicle.kind, brand: vehicle.name, model: vehicle.modelName, modelText: vehicle.brandName, 
          stateNumber: vehicle.regNumber, dontHaveVIN: vehicle.dontHaveBodyNumber, grossWeight: vehicle.grossWeight, curbWeight: vehicle.curbWeight, seatingCapacity: vehicle.seatingCapacity, autoColor: vehicle.autoColor, 
          isForeignReg: validateOrderData(isForeign), lastModified: vehicle.lastModified ?? vehicle.registrationPlace?.lastModified ?? getCurrentDateTime(), registrationPlace: {
            placeCode: vehicle.registrationPlace?.placeCode,
            zone: vehicle.registrationPlace?.zone,
            nameFull: vehicle.registrationPlace?.nameFull,
            id: vehicle.registrationPlace?.id,
            country: vehicle.registrationPlace?.country,
            name: vehicle.registrationPlace?.name,
            nameRus: vehicle.registrationPlace?.nameRus,
            nameFullRus: vehicle.registrationPlace?.nameFullRus,
            cdbMtibu: vehicle.registrationPlace?.cdbMtibu,
            cdbMtibuCode: vehicle.registrationPlace?.cdbMtibuCode,
            lastModified: vehicle.registrationPlace?.lastModified
          }}
        ));
    }

    if (vehicleRegLocationInput.current) {
        vehicleRegLocationInput.current.focus();
    }
  }, [vehicle, dispatch]);

  const validateGreenCardVehicleType = (type) => {
    const greenCardType = getGreenCardType(type);
    setVehicleType(greenCardType as VehicleTypeList);
  };

  const setVechicleRegCity = async (event) => {
    const dataSuggestion = event.target.dataset.suggestion;

    if (vehicleRegLocationInput.current && event.target.value.length > 0 && dataSuggestion.length > 0) {
        vehicleRegLocationInput.current.value = event.target.value;
        setCity(vehicleRegLocationInput.current.value);
        setTouch(true);
        const place = await dbAxiosInstance.get(`/reg-place/${encodeURIComponent(dataSuggestion)}`);
        dispatch(setData(place.data[0]));
    } else {
        setCity("");
        setTouch(false);
    }
  };

  const handleVechileRegContent = (event, {newValue, method}) => {
    setCity(newValue);
    
    if (newValue !== "") {
        setTouch(true);
    } else {
        setTouch(false);
        dispatch(setError({
          message: "Це поле обов'язкове"
        }));
    }
  };

  const handleVechileRegContentBlur = (event, {newValue, method}) => {
    setTouch(true);
  };

  const handleOtkDateChange = (name, date) => {
    const isValidOtkDate = compareOtkDate(date);

    if (isValidOtkDate) {
        setVehicleOtkDate(date);
        setIsVehicleValidOtkDate(false);
        setVehicleOtkDateErrorMsg("");
    } else {
        setVehicleOtkDate("");
        setIsVehicleValidOtkDate(true);
        setVehicleOtkDateErrorMsg("Ви ввели невірну дату Обов'язкового Технічного Контролю (ОТК)!");
    }

    clearError(name);
  };
  
  const handlePromocodeVerification = () => {
    if (!isPromocodeVerified) {
        setPromocodeState(!promocodeState);
    }

    setIsPromocodeVerification(!isPromocodeVerification);
  };

  const handleGrossWeightChange = (event) => {
    setVehicleInfo((prev) => ({...prev, grossWeight: event.target.value}));
  };

  const handleCurbWeightChange = (event) => {
    setVehicleInfo((prev) => ({...prev, curbWeight: event.target.value}));
  };

  const handleSeatingCapacityChange = (event) => {
    setVehicleInfo((prev) => ({...prev, seatingCapacity: event.target.value}));
  };

  const handleElectricMotorPowerChange = (event) => {
    setVehicleInfo((prev) => ({...prev, electricMotorPower: event.target.value}))
  };

  const handleEpolicyPeriodChange = (period) => {
    setEpolicyPeriod(period);
    setEpolicyPeriodError(false);
  };

  const handleGrossWeightBlur = (event) => {
    const vehicleGrossWeight = event.target.value;

    if (vehicleGrossWeight.length > 0) {
        dispatch(setVehicleSpecs({...vehicle, grossWeight: vehicleGrossWeight}));
    }
  };

  const handleCurbWeightBlur = (event) => {
    const vehicleCurbWeight = event.target.value;

    if (vehicleCurbWeight.length > 0) {
        dispatch(setVehicleSpecs({...vehicle, curbWeight: vehicleCurbWeight}));
    }
  };

  const handleSeatingCapacityBlur = (event) => {
    const vehicleSeatingCapacity = event.target.value;

    if (vehicleSeatingCapacity.length > 0) {
        dispatch(setVehicleSpecs({...vehicle, seatingCapacity: vehicleSeatingCapacity}));
    }
  };

  const handleElectricMotorPowerBlur = (event) => {
    const vehicleElectricMotorPower = event.target.value;

    if (vehicleElectricMotorPower.length > 0) {
        dispatch(setVehicleSpecs({...vehicle, electricMotorPower: vehicleElectricMotorPower}));
    }
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} id={formId} noValidate>
      {(!vehicle || (vehicle && !vehicle.id)) && (
        <>
          <div className="form-group">
            <CustomField
              className="vehicle-number"
              register={register({
                required: true,
                pattern: {
                  value: /[A-zА-я-І-і-Ї-ї0-9]/,
                  message: "Реєстраційний номер ТЗ не відповідає правилам перевірки"
                }
              })}
              errors={errors}
              name="register_auto"
              label=""
              placeholder="АА0000АА"
              onChange={props.handleChange}
              defaultValue={props.vehicleNumber}
              autoFocus
            />
            {regError && (!vehicle || !vehicle.id) && <div className="customer-error-notification">{regError}</div>}
            <CustomLoader isSquare={true} isInfoOn={false} isLoading={isLoading} color={config.color} css={config.css} />
          </div>
          <button type="submit" className="btn btn-primary" disabled={isLoading}>
            Розрахувати вартість
          </button>
        </>
      )}
      {vehicle && (
        <div>
          <div className="vehicle-info">
            <div>
              Марка
              <br />
              <strong>{vehicle.brandName || "Н/Д"}</strong>
            </div>
            <div>
              Модель
              <br />
              <strong>{vehicle.modelName || "Н/Д"}</strong>
            </div>
            <div>
              Номер авто
              <br />
              <strong>{vehicle.regNumber}</strong>
            </div>
            <div>
              {vehicle.type ? getInfoByType(vehicle.type).name : "Обсяг"}
              <br />
              {
                vehicle.type ? 
                  <strong>{getInfoByType(vehicle.type).label}</strong> 
                : 
                  <CustomSelect
                    options={vehicleEngineOptions} 
                    isSearchable={false}
                    isCreatable={false}
                    handleChange={handleChangeEngineType}
                    value={engineType}
                    placeholder="Обсяг двигуна"
                    isError={engineTypeError}
                  />
              }
            </div>
            <div>
              {vehicle.grossWeight ?
                <>
                  Повна маса
                  <br />
                  <strong>{vehicle.grossWeight} кг</strong>
                </>
               :
                <CustomField 
                  register={register({
                    required: "Це поле обов'язкове"
                  })}
                  type="number"
                  name="grossWeight"
                  label="Повна маса (кг)"
                  placeholder="2440"
                  onChange={handleGrossWeightChange}
                  onBlur={handleGrossWeightBlur}
                  min={0}
                  errors={errors}
                />
              }
            </div>
            <div>
              {vehicle.curbWeight ?
                <>
                  Маса без навантаження
                  <br />
                  <strong>{vehicle.curbWeight} кг</strong>
                </>
               :
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове"
                  })}
                  type="number" 
                  name="curbWeight" 
                  label="Маса без навантаження (кг)" 
                  placeholder="1940" 
                  onChange={handleCurbWeightChange}
                  onBlur={handleCurbWeightBlur}
                  min={0} 
                  errors={errors} 
                />
              }
            </div>
            <div>
              {vehicle.seatingCapacity ?
                <>
                  Кількість місць (з водієм)
                  <br />
                  <strong>{vehicle.seatingCapacity}</strong>
                </>
               :
                <CustomField
                  register={register({
                    required: "Це поле обов'язкове"
                  })}
                  type="number" 
                  name="seatingCapacity" 
                  label="Кількість місць (з водієм)" 
                  placeholder="5" 
                  onChange={handleSeatingCapacityChange}
                  onBlur={handleSeatingCapacityBlur}
                  min={0} 
                  errors={errors} 
                />
              }
            </div>
            {isElectricMotorVehicle(vehicle.type) &&
              <div>
                {vehicle.electricMotorPower ?
                  <>
                    Потужність електродвигуна (кВт)
                    <br />
                    <strong>{vehicle.electricMotorPower}</strong>
                  </>
                :
                  <CustomField
                    register={register({
                      required: "Це поле обов'язкове"
                    })}
                    type="number" 
                    name="electricMotorPower" 
                    label="Потужність електродвигуна (кВт)" 
                    placeholder="600" 
                    onChange={handleElectricMotorPowerChange}
                    onBlur={handleElectricMotorPowerBlur}
                    min={0} 
                    errors={errors} 
                  />
                }
              </div>
            }
          </div>
          {props.insuranceType === "greencard" ?
            <div className="greencard-form">
              <div className="form-group">
                  <CustomSelect
                    options={greencardPeriods}
                    isSearchable={false}
                    handleChange={handleGreencardPeriodChange}
                    isCreatable={false}
                    value={greencardPeriod}
                    placeholder="Період дій договору"
                    isError={greencardPeriodError}
                  />
              </div>
              <div className="form-group">
                  <CustomSelect
                    options={areas}
                    isSearchable={false}
                    handleChange={handleChangeCoverageArea}
                    isCreatable={false}
                    value={coverageArea}
                    placeholder="Територія покриття"
                    isError={coverageAreaError}
                  />
              </div>
            </div>
            :
            <div>
              <div className="checkbox-car">
                <div className="form-group form-check">
                  <input ref={register} name="privilege" id={checkboxId} type="checkbox" defaultChecked={privilegeState} onChange={handlePrivilegeChange} hidden />
                  <label htmlFor={checkboxId}>Є пільга</label>
                </div>
                <div className="form-group form-check">
                  <input ref={register} name="taxi" id="taxi" type="checkbox" hidden />
                  <label htmlFor="taxi">Таксі</label>
                </div>
                <div className="form-group form-check">
                  <input ref={register} name="promocode" id="promocode" type="checkbox" checked={promocodeState} onChange={handlePromocodeStateChange} disabled={isPromocodeVerified} hidden />
                  <label htmlFor="promocode">Промокод</label>
                </div>
              </div>
            {isPrivilegeError && privilegeErrorMsg && <div className="policy-error-notification">{privilegeErrorMsg}</div>}
            {promocodeState && 
              <div className="promocode-container">
                <CustomField 
                  type="text" 
                  register={register({
                    required: "Це поле обов'язкове",
                    pattern: {
                      value: /^[A-z0-9]{10}$/,
                      message: "Промокод невірно вказаний"
                    },
                    maxLength: {
                      value: 10,
                      message: "Промокод складається з 10 символів",
                    },
                    minLength: {
                      value: 10,
                      message: "Промокод складається з 10 символів"
                    }
                  })}
                  className="promocode-input" 
                  name="promocodeInput" 
                  placeholder="Введіть Ваш промокод" 
                  errors={errors}
                  onChange={handleProcodeChange}
                  label=""
                  defaultValue={promocode}
                  readOnly={isPromocodeVerified}
                  hidden={isPromocodeVerified}
                  autoFocus
                  />
              </div>
             }
              <div className="epolicy-form">
                  <div className="form-group">
                      <CustomSelect
                        options={epolicyPeriods}
                        isSearchable={false}
                        handleChange={handleEpolicyPeriodChange}
                        isCreatable={false}
                        value={epolicyPeriod}
                        placeholder="Період дій договору"
                        isError={epolicyPeriodError}
                      />
                  </div>
              </div>
           </div>
          }
          {!vehicle.registrationPlace?.id && !vehicle.registrationPlace?.name &&
            <>
              <AutoComplete ref={vehicleRegLocationInput} content={city} handleContent={handleVechileRegContent} handleContentBlur={handleVechileRegContentBlur} isTouch={touch} id="policy-required-auto-number" />
              <RegCities handleVechicleRegCity={setVechicleRegCity} />
            </>
          }
          {props.insuranceType === "epolicy" && isVehicleOtkRequired(vehicle.kind, vehicle.type) &&
            <>
              <CustomField
                register={register({
                  required: "Це поле обов'язкове"
                })}
                errors={errors}
                name="otkDate"
                label="Дата наступного ОТК"
                type="date"
                placeholder="РРРР.ММ.ДД"
                max={getMaxDate()}
                autoComplete="off"
                dateValue={vehicleOtkDate}
                onDateChange={handleOtkDateChange}
              />
              {isVehicleValidOtkDate && vehicleOtkDateErrorMsg && <div className="customer-error-notification">{vehicleOtkDateErrorMsg}</div>}
            </>
          }
          {promocodeState && isPromocodeVerification &&
            <PublicModal isOpen={promocodeState} onCLose={handlePromocodeVerification} type="promocode-verification" title="Промокод" successMsg={promocodeSuccessMsg} errorMsg={promocodeErrorMsg} />
          }
          <button type="submit" className="btn btn-primary">
            Далі
          </button>
        </div>
      )}
    </form>
  );
};

export default NumberAutoForm;