import React from "react";
import CustomField from "../CustomField";

const combatCertificate = (name: string) => `combatCertificate.${name}`;

const CombatCertificateForm = (props) => {
  return (
    <>
      <div className={props.seriesClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
            pattern: {
              value: /^[А-Я-ІЄЇ-а-я-ієї]{2,3}$/,
              message: "Серія складається з 2 або 3 літер кирилиці"
            },
            minLength: {
              value: 2,
              message: "Серія складається мінімально з 2 літер кирилиці"
            },
            maxLength: {
              value: 3,
              message: "Серія складається максимально з 3 літер кирилиці"
            }
          })}
          errors={props.errors}
          name={combatCertificate("series")}
          label="Серія"
          placeholder="УБД"
          readOnly={props.isReadOnly}
          defaultValue={props.documentSeriesValue}
          onChange={props.onDocumentSeriesChange}
        />
      </div>
      <div className={props.numberClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове",
            pattern: {
              value: /\d{6}/,
              message: "Номер складається з шести цифр"
            },
            maxLength: {
              value: 6,
              message: "Номер складається з шести цифр"
            }
          })}
          errors={props.errors}
          name={combatCertificate("number")}
          label="Номер"
          placeholder="123456"
          readOnly={props.isReadOnly}
          defaultValue={props.documentNumberValue}
          onChange={props.onDocumentNumberChange}
        />
      </div>
      <div className={props.issuedDateClass}>
        <CustomField
          register={props.register({
            required: "Це поле обов'язкове"
          })}
          errors={props.errors}
          name={combatCertificate("date")}
          label="Коли видано"
          placeholder="РРРР.ММ.ДД"
          type="date"
          autoComplete="off"
          dateReadOnly={props.isReadOnly}
          dateValue={props.documentIssuedDateValue}
          onDateChange={props.onDocumentIssuedDateChange}
        />
      </div>
    </>
  );
};

export default CombatCertificateForm;
