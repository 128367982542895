import React, {useEffect, useState} from "react";
import {navToAdminPanel, refreshPage, navToEpolicyCompaniesPage, navToGreencardCompaniesPage} from "../../../utils/utilsGeneral";
import {useNavigate, useParams} from "react-router-dom";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {companyImage, companyImages} from "../../../constants/companyImages";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const ViewGreencardCompany = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, productCode, companyID, greencardID} = useParams<{
        id: string; 
        externalID: string; 
        productCode: string; 
        companyID: string; 
        greencardID: string;
    }>();
    const [greencardCompanyData, setGreencardCompanyData] = useState<any[]>([]);
    const [greencardCompanyErrorMsg, setGreencardCompanyErrorMsg] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);

    useEffect(() => {
        const fetchGreencardCompany = async () => {
            try {
                const res = await dbAxiosInstance.get(`/greencard/company/${encodeURIComponent(productCode!)}/${encodeURIComponent(companyID!)}/${encodeURIComponent(greencardID!)}`);
                const dbGreencardData = res.data;

                if (dbGreencardData) {
                    if (dbGreencardData.length > 0) {
                        setGreencardCompanyData(dbGreencardData);
                        setGreencardCompanyErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setGreencardCompanyData([]);
                        setGreencardCompanyErrorMsg("Немає такої страхової компанії зеленої карти.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setGreencardCompanyData([]);
                    setGreencardCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію зеленої карти.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setGreencardCompanyData([]);
                setGreencardCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію зеленої карти.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchGreencardCompany();
    }, []);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editGreencardCompany = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/greencard/edit-company/${productCode}/${companyID}/${greencardID}`);
    };

    const deleteGreencardCompany = async (id) => {
        try {
            await dbAxiosInstance.delete(`/greencard/company/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/greencard`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setGreencardCompanyErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setGreencardCompanyData([]);
        }
    };

    const greencardCompanyImg = companyImages.filter((company: companyImage) => greencardCompanyData.find((item) => company.id === item.company_id));

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        {greencardCompanyData.length > 0 && greencardCompanyData.map((greencardCompany) => (
                            <div className="company-info-container" key={greencardCompany.id}>
                                <h1 className="company-title">Страхова компанія &#8212; {greencardCompany.name}</h1>
                                <div className="company-logo">
                                    <img src={greencardCompanyImg.length > 0 ? require(`../../../assets/img/logos/${greencardCompanyImg[0].key}`).default : "\\img\\base.webp"} alt="" />
                                </div>
                                <p className="item-title">Назва: <span className="font-bold">{greencardCompany.name}</span></p>
                                <p className="item-title">Ідентифікатор компанії: <span className="font-bold">{greencardCompany.company_id}</span></p>
                                <p className="item-title">Код продукту: <span className="font-bold">{greencardCompany.product_code}</span></p>
                                <p className="item-title">Кешбек включено: <span className="font-bold">{greencardCompany.is_cashback_enabled ? <span className="cashback-enabled">Так</span> : <span className="cashback-disabled">Ні</span>}</span></p>
                                <p className="item-title">Процент кешбеку (%): <span className="font-bold">{`${greencardCompany.cashback_percentage}%`}</span></p>
                                <p className="item-title">Є прихованою: <span className="font-bold">{greencardCompany.is_hidden ? <span className="company-hidden">Так</span> : <span className="company-visible">Ні</span>}</span></p>
                            </div>
                        ))}
                        <div className="manage-company">
                            <button type="submit" className="admin-btn" onClick={editGreencardCompany}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                            <button type="button" className="admin-btn" onClick={() => deleteGreencardCompany(greencardID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                            <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                            <button type="button" className="admin-btn" onClick={() => navToGreencardCompaniesPage(dashboardNavigate, id, externalID)}>Зелена карта</button>
                            <button type="button" className="admin-btn" onClick={() => navToEpolicyCompaniesPage(dashboardNavigate, id, externalID)}>Автоцивілка</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                        </div>
                    </div>
                    {!hideErrorNotification && greencardCompanyErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{greencardCompanyErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ViewGreencardCompany;