import React, {useEffect} from "react";
import {createRoot, Root} from "react-dom/client";
import {axiosInstance} from "../../../core/configAxios";
import {getCompanyYear} from "../../../utils/utilsGeneral";
import ReactDOMServer from "react-dom/server";
import webConfig from "../../../config";
import BBCodeComponent from "../../../components/Forms/common/BBCodeComponent";
import ImageZoomComponent from "../../../components/Forms/common/ImageZoomComponent";
import InfoSpoiler from "../../../components/Forms/common/InfoSpoiler";

interface IEmailTemplate {
  content: any;
  setEmailContent?: (content: any) => void;
  isEmail: boolean;
  isModalOpen: boolean;
}

const roots = new Map<HTMLElement, Root>();

const EmailTemplate = ({content, setEmailContent, isEmail, isModalOpen}: IEmailTemplate) => {
  useEffect(() => {
    const handleTooltip = () => {
        const tooltipWrappers = document.querySelectorAll(".tooltip-wrapper");
        tooltipWrappers.forEach((wrapper) => {
            const title = wrapper.getAttribute("data-title");
            const src = wrapper.getAttribute("data-src");

            if (title && src) {
                let imageZoomComponentRoot = roots.get(wrapper as HTMLElement);

                if (!imageZoomComponentRoot) {
                    imageZoomComponentRoot = createRoot(wrapper as HTMLElement);
                    roots.set(wrapper as HTMLElement, imageZoomComponentRoot);
                }

                imageZoomComponentRoot.render(<ImageZoomComponent content={src} caption={title} alt={title} isLocalImage={false} />);
            }
        });
    };

    const handleSpoiler = () => {
        const spoilerWrappers = document.querySelectorAll(".spoiler-wrapper");
        spoilerWrappers.forEach((wrapper) => {
            const title = wrapper.getAttribute("data-title");
            const spoilerContent = wrapper.getAttribute("data-content");

            if (title && spoilerContent) {
                let infoSpoilerRoot = roots.get(wrapper as HTMLElement);

                if (!infoSpoilerRoot) {
                    infoSpoilerRoot = createRoot(wrapper as HTMLElement);
                    roots.set(wrapper as HTMLElement, infoSpoilerRoot);
                }

                infoSpoilerRoot.render(<InfoSpoiler isDefaultSpoilerTitle={true} spoilerContentVisibility={false} spoilerTitle={title} spoilerContent={spoilerContent.trim()} />);
            }
        });
    };

    const fetchEmailTemplate = async () => {
      const renderedBBCode = ReactDOMServer.renderToStaticMarkup(<BBCodeComponent content={content} isEmail={isEmail} />);
      
      const templateReplacements = {
        WEBSITE: webConfig.pessWebsite,
        COMPANY_YEAR: getCompanyYear(),
        CONTENT: renderedBBCode
      };

      const res = await axiosInstance.get("/templates/email");
      let htmlContent = res.data;

      for (const templateKey in templateReplacements) {
           const templateRegex = new RegExp(`{{${templateKey}}}`, "g");
           htmlContent = htmlContent.replace(templateRegex, templateReplacements[templateKey]);
      }

      setTimeout(() => {
        handleTooltip();
        handleSpoiler();
      }, 1);

      setEmailContent?.(htmlContent);
    };

    fetchEmailTemplate();

    return () => {
      setTimeout(() => {
        roots.forEach((root) => root.unmount());
        roots.clear();
      }, 0);
    };
  }, [content, isModalOpen]);
  
  return null;
};

export default EmailTemplate;