import React, {useState, useEffect} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {formatDBDateTime} from "../../../utils/utilsDate";
import {refreshPage, navToAdminPanel} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import {IVehicle} from "../../../redux-data/vehicle/vehicleTypes";

const ViewVehiclePage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, stateNumber, vehicleID} = useParams<{
        id: string;
        externalID: string;
        stateNumber: string;
        vehicleID: string;
    }>();
    const [vehicleData, setVehicleData] = useState<IVehicle[]>([]);
    const [vehicleErrorMsg, setVehicleErrorMsg] = useState("");
    const [vehicleRegLocation, setVehicleRegLocation] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    
    useEffect(() => {
        const fetchVehicle = async () => {
            try {
                const vehicleRes = await dbAxiosInstance.get(`/vehicle/${encodeURIComponent(stateNumber!.toUpperCase())}/${encodeURIComponent(vehicleID!)}`);
                const regPlaceRes = await dbAxiosInstance.get(`/vehicle/reg-location/${encodeURIComponent(vehicleRes.data[0].reg_place_id)}/${encodeURIComponent(vehicleRes.data[0].reg_place_code)}`);
                const dbVehicleData = vehicleRes.data;
                const dbRegPlaceData = regPlaceRes.data;

                if (dbVehicleData) {
                    if (dbVehicleData.length > 0) {
                        const dbVehicles: IVehicle[] = dbVehicleData.map((vehicle) => {
                            return {
                                id: vehicle.id,
                                engineVolume: vehicle.engine_volume,
                                year: vehicle.year,
                                vin: vehicle.vin,
                                category: vehicle.category,
                                kind: vehicle.kind,
                                brand: vehicle.brand,
                                model: vehicle.model,
                                modelText: vehicle.model_text,
                                stateNumber: vehicle.state_number,
                                dontHaveVIN: vehicle.dont_have_vin,
                                lastModified: vehicle.last_modified,
                                autoColor: vehicle.auto_color,
                                isForeignReg: vehicle.is_foreign,
                                grossWeight: vehicle.gross_weight,
                                curbWeight: vehicle.curb_weight,
                                seatingCapacity: vehicle.seating_capacity,
                                electricMotorPower: vehicle.electric_motor_power,
                                registrationPlace: {
                                    id: vehicle.reg_place_id,
                                    placeCode: vehicle.reg_place_code
                                }
                            };
                        });
                        setVehicleData(dbVehicles);
                        setVehicleErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setVehicleData([]);
                        setVehicleErrorMsg("Немає такого транспортного засобу. Натисніть на кнопку \"Додати\", щоб додати ТЗ.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setVehicleData([]);
                    setVehicleErrorMsg("Помилка БД! Не вдалося завантажити транспортний засіб.");
                    setErrorNotificationHidden(false);
                }

                if (dbRegPlaceData) {
                    if (dbRegPlaceData.length > 0) {
                        setVehicleRegLocation(dbRegPlaceData[0].name_full);
                    } else {
                        setVehicleRegLocation("");
                    }
                } else {
                    setVehicleRegLocation("");
                }
            } catch (error: any) {
                setVehicleData([]);
                setVehicleRegLocation("");
                setVehicleErrorMsg("Помилка БД! Не вдалося завантажити транспортний засіб.");
                setErrorNotificationHidden(false);
            }
        };
        
        fetchVehicle();
    }, []);

    const addVehicle = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/add-vehicle`);
    };

    const editVehicle = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/edit-vehicle/${stateNumber}/${vehicleID}`);
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const navToVehiclesPage = (event) => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/vehicles`);
    };

    const deleteVehicle = async (id) => {
        try {
            await dbAxiosInstance.delete(`/vehicle/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/vehicles`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setVehicleErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setVehicleData([]);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Транспортний засіб &#8212; {stateNumber?.toUpperCase()}</h1>
                        {vehicleData.length > 0 && vehicleData.map((vehicle) => 
                            <div key={vehicle.id}>
                                {vehicle.engineVolume > 0 && <p className="item-title">Об'єм двигуна (см3): <span className="font-bold">{vehicle.engineVolume}</span></p>}
                                {vehicle.electricMotorPower > 0 && <p className="item-title">Потужність електродвигуна (кВт): <span className="font-bold">{vehicle.electricMotorPower}</span></p>}
                                <p className="item-title">Рік: <span className="font-bold">{vehicle.year > 0 ? vehicle.year : 0}</span></p>
                                <p className="item-title">VIN код: <span className="font-bold">{vehicle.vin ? vehicle.vin : "Невідомо"}</span></p>
                                <p className="item-title">Категорія: <span className="font-bold">{vehicle.category ? vehicle.category : "Невідомо"}</span></p>
                                <p className="item-title">Тип: <span className="font-bold">{vehicle.kind ? vehicle.kind : "Невідомо"}</span></p>
                                <p className="item-title">Бренд: <span className="font-bold">{vehicle.brand ? vehicle.brand : "Невідомо"}</span></p>
                                <p className="item-title">Модель: <span className="font-bold">{vehicle.model ? vehicle.model : "Невідомо"}</span></p>
                                <p className="item-title">Назва: <span className="font-bold">{vehicle.modelText ? vehicle.modelText : "Невідомо"}</span></p>
                                <p className="item-title">Державний номер: <span className="font-bold">{vehicle.stateNumber}</span></p>
                                <p className="item-title">Додано/оновлено: <span className="font-bold">{formatDBDateTime(vehicle.lastModified)}</span></p>
                                <p className="item-title">Немає VIN коду: <span className="font-bold">{vehicle.dontHaveVIN ? <span className="dont-have-vin-code">Так</span> : <span className="vin-code-available">Ні</span>}</span></p>
                                <p className="item-title">Колір: <span className="font-bold">{vehicle.autoColor ? vehicle.autoColor : "Невідомо"}</span></p>
                                <p className="item-title">Іноземна реєстрація: <span className="font-bold">{vehicle.isForeignReg ? <span className="is-foreign-reg">Так</span> : <span className="is-state-reg">Ні</span>}</span></p>
                                {!vehicle.isForeignReg &&
                                  <>
                                    <p className="item-title">Місце реєстрації: <span className="font-bold">{vehicleRegLocation ? vehicleRegLocation : "Невідомо"}</span></p>
                                    <p className="item-title">Ідентифікатор місця реєстрації: <span className="font-bold">{vehicle.registrationPlace.id > 0 ? vehicle.registrationPlace.id : "Невідомо"}</span></p>
                                    <p className="item-title">Код місця реєстрації: <span className="font-bold">{vehicle.registrationPlace.placeCode > 0 ? vehicle.registrationPlace.placeCode : "Невідомо"}</span></p>
                                  </>
                                }
                                <p className="item-title">Повна маса (кг): <span className="font-bold">{vehicle.grossWeight ? vehicle.grossWeight : "Невідомо"}</span></p>
                                <p className="item-title">Маса без навантаження (кг): <span className="font-bold">{vehicle.curbWeight ? vehicle.curbWeight : "Невідомо"}</span></p>
                                <p className="item-title">Кількість місць (з водієм): <span className="font-bold">{vehicle.seatingCapacity ? vehicle.seatingCapacity : "Невідомо"}</span></p>
                            </div>
                        )}
                        <button type="button" className="admin-btn" onClick={addVehicle}><i className="fa fa-plus notification-icon"></i>Додати</button>
                        <button type="submit" className="admin-btn" onClick={editVehicle}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="button" className="admin-btn" onClick={() => deleteVehicle(vehicleID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                        <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                        <button type="button" className="admin-btn" onClick={navToVehiclesPage}>Транспортні засоби</button>
                        <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                    </div>
                    {!hideErrorNotification && vehicleErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{vehicleErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ViewVehiclePage;