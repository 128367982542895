import React, {useState, useEffect} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import useForm from "react-hook-form";
import {refreshPage, bbCodeToText} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import CustomSelect from "../../../components/common/CustomSelect";
import {fromAdminEmails} from "../../../mock-data/newsletterTypes";
import SwitchComponent from "react-switch";
import CustomField from "../../../components/MainPage/CustomField";
import CustomTextarea from "../../../components/MainPage/CustomTextarea";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import PublicModal from "../../../components/common/Modals/PublicModal";
import EmailTemplate from "./EmailTemplate";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const PolicyNewsletter = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, insuranceType, phoneNumber, vehicleStateNumber} = useParams<{
        id: string; 
        externalID: string;
        insuranceType: string;
        phoneNumber: string;
        vehicleStateNumber: string;
    }>();
    const [fromEmail, setFromEmail] = useState(fromAdminEmails[0]);
    const [customersSelected, setCustomersSelected] = useState(false);
    const [customEmailsSelected, setCustomEmailsSelected] = useState(false);
    const [customersEmails, setCustomersEmails] = useState<any>([]);
    const [customEmails, setCustomEmails] = useState<any>([]);
    const [emailSubject, setEmailSubject] = useState("");
    const [emailContent, setEmailContent] = useState("");
    const [isClearBtnClicked, setClearBtnClicked] = useState(false);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [emailErrorMsg, setEmailErrorMsg] = useState("");
    const [emailIsSending, setEmailSending] = useState(false);
    const [isEmailPreviewOpen, setEmailPreviewOpen] = useState(false);
    const [emailHtmlContent, setEmailHtmlContent] = useState("");

    const {register, errors} = useForm<any>({
        reValidateMode: "onChange",
        mode: "onChange"
    });

    useEffect(() => {
        const fetchCustomerData = async () => {
            try {
                const res = await dbAxiosInstance.get(`/admin/customer/${encodeURIComponent(phoneNumber!)}/${encodeURIComponent(vehicleStateNumber!.toUpperCase())}/${encodeURIComponent(insuranceType!)}`);
                const dbCustomerData = res.data;

                if (dbCustomerData) {
                    if (dbCustomerData.length > 0) {
                        setCustomEmailsSelected(true);
                        setCustomEmails(dbCustomerData[0].email);
                    } else {
                        setCustomEmailsSelected(false);
                        setCustomEmails("");
                    }
                } else {
                    setCustomEmailsSelected(false);
                    setCustomEmails("");
                }
            } catch (error: any) {
                setCustomEmailsSelected(false);
                setCustomEmails("");
            }
        };

        fetchCustomerData();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const handleFromEmailChange = (email) => {
        setFromEmail(email);
    };

    const fetchCustomersData = async () => {
        try {
            const res = await dbAxiosInstance.get("/newsletter/customers-data/emails");
            const dbCustomersData = res.data;

            if (dbCustomersData) {
                if (dbCustomersData.length > 0) {
                    const allCustomersEmails = dbCustomersData.map((customer) => {
                        return customer.email;
                    });
                    setCustomersEmails(allCustomersEmails);
                } else {
                    setCustomersEmails("");
                }
            } else {
                setCustomersEmails("");
            }
        } catch (error: any) {
            setCustomersEmails("");
        }
    };

    const handleCustomersSelectionChange = (isSelected) => {
        setCustomersSelected(isSelected);

        if (isSelected) {
            fetchCustomersData();
        }
    };

    const handleCustomEmailsSelectionChange = (isSelected) => {
        setCustomEmailsSelected(isSelected);
    };

    const handleCustomEmailsChange = (data) => {
        setCustomEmails(data);
    };

    const handleEmailSubjectChange = (event) => {
        setEmailSubject(event.target.value);
    };

    const handleEmailContentChange = (data) => {
        setEmailContent(data);
    };

    const handleEmailSend = async (event) => {
        try {
            setEmailSending(true);
                        
            if (fromEmail.value && emailSubject && emailContent && emailHtmlContent && (customersEmails.length > 0 || customEmails.length > 0)) {
                const res = await dbAxiosInstance.post("/send-newsletter", {senderEmail: fromEmail.value, receivers: customersSelected ? customersEmails : customEmails, subject: emailSubject, textContent: bbCodeToText(emailContent), htmlContent: emailHtmlContent});
                const emailData = res.data;

                if (emailData.isSent) {
                    dashboardNavigate(`/admin/dashboard/${id}/${externalID}/newsletter/sent`, {
                        state: {
                            isSuccess: true,
                            successMsg: emailData.successMsg,
                            errorMsg: ""
                        }
                    });
                } else {
                    dashboardNavigate(`/admin/dashboard/${id}/${externalID}/newsletter/sent`, {
                        state: {
                            isSuccess: false,
                            successMsg: "",
                            errorMsg: "Помилка надсилання електронного листа!"
                        }
                    });
                }
            } else {
                setEmailErrorMsg("Будь ласка, заповніть усі поля, щоб відправити лист!");
                setErrorNotificationHidden(false);
            }
        } catch (error: any) {
            setEmailErrorMsg("Помилка надсилання електронного листа!");
            setErrorNotificationHidden(false);
        } finally {
            setEmailSending(false);
        }
    };

    const handleEmailPreview = (event) => {
        if (emailSubject && emailContent) {
            setEmailPreviewOpen(!isEmailPreviewOpen);
            setEmailErrorMsg("");
            setErrorNotificationHidden(true);
        } else {
            setEmailErrorMsg("Будь ласка, заповніть тему і вміст листа, щоб відкрити попередній перегляд!");
            setErrorNotificationHidden(false);
        }
    };

    const clearField = () => {
        setEmailContent("");
        setClearBtnClicked(false);
        closeNotificationMsg();
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Розсилка новин</h1>
                        {emailIsSending ?
                            <CustomLoader className="email-send-request" isSquare={false} isInfoOn={true} isLoading={emailIsSending} infoMsg="Триває відправка електронного листа..." />
                         :
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="send-newsletter-from">
                                        <label>Відправка з пошти</label>
                                        <CustomSelect
                                            options={fromAdminEmails}
                                            handleChange={handleFromEmailChange}
                                            value={fromEmail}
                                            isCreatable={true}
                                            isDisabled={false}
                                            placeholder="Виберіть зі списку"
                                        />
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="manage-newsletter-receivers">
                                        <div className="switch-wrapper">
                                            <label className="switch-container">
                                                <SwitchComponent onChange={handleCustomersSelectionChange} checked={customersSelected} disabled={customEmailsSelected} onColor="#1BA876" />
                                                <div className="switch-text">Клієнти</div>
                                            </label>
                                        </div>
                                        <div className="switch-wrapper">
                                            <label className="switch-container">
                                                <SwitchComponent onChange={handleCustomEmailsSelectionChange} checked={customEmailsSelected} disabled={customersSelected} onColor="#1BA876" />
                                                <div className="switch-text">Спеціальні електронні листи</div>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                {customEmailsSelected &&
                                    <div className="col-md-12">
                                        <CustomTextarea
                                            name="custom_emails"
                                            label="Список адрес електронної пошти"
                                            placeholder="Введіть власний список адрес електронної пошти розділений комами" 
                                            value={customEmails} 
                                            onDataChange={handleCustomEmailsChange}
                                            isBBCode={false}
                                            isFocus={true}
                                            rows={4}
                                        />
                                    </div>
                                }
                                <div className="col-md-12">
                                    <CustomField
                                        register={register({
                                            required: "Це поле обов'язкове"
                                        })}
                                        errors={errors}
                                        name="subject"
                                        label="Тема електронного листа"
                                        defaultValue={emailSubject}
                                        readOnly={false}
                                        onChange={handleEmailSubjectChange}
                                        placeholder="Введіть тему електронного листа"
                                    />
                                </div>
                                <div className="col-md-12">
                                    <CustomTextarea
                                        name="content"
                                        label="Вміст електронного листа"
                                        placeholder="Введіть вміст електронного листа" 
                                        value={emailContent} 
                                        onDataChange={handleEmailContentChange}
                                        isBBCode={true}
                                        rows={20}
                                        isClear={isClearBtnClicked}
                                        onDataClear={clearField}
                                    />
                                </div>  
                                <div className="col-md-12">
                                    <button type="submit" className="admin-btn" onClick={handleEmailSend}><i className="fa fa-paper-plane-top notification-icon"></i>Надіслати</button>
                                    <button type="button" className="admin-btn" onClick={handleEmailPreview}><i className="fa fa-file-magnifying-glass notification-icon"></i>Попередній перегляд</button>
                                    <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                                    <button type="button" className="admin-btn" onClick={() => setClearBtnClicked(true)}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                                </div>
                                <PublicModal isOpen={isEmailPreviewOpen} onCLose={handleEmailPreview} type="email-preview" title={emailSubject} data={emailContent}/>
                                <EmailTemplate content={emailContent} setEmailContent={(content) => setEmailHtmlContent(content)} isEmail={true} isModalOpen={isEmailPreviewOpen} />
                            </div>
                        }
                        {!hideErrorNotification && emailErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{emailErrorMsg}</div>}
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default PolicyNewsletter;