import React from "react";
import InfoSpoiler from "../../../components/Forms/common/InfoSpoiler";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";

const VehicleTypeInfo = (props) => {
    return (
        <InfoSpoiler isDefaultSpoilerTitle={true} spoilerContentVisibility={false} spoilerTitle="Детальніше про тип ТЗ" 
            spoilerContent={
                <div className="vehicle-kind-details">
                    <p className="font-bold">Тип автомобіля</p>
                    <p>Допустимі значення:</p>
                    <ul>
                        <li><span className="font-bold">MOTO</span> - мотоцикл</li>
                        <li><span className="font-bold">CAR</span> - автомобіль</li>
                        <li><span className="font-bold">PASSENGER</span> - автобус</li>
                        <li><span className="font-bold">FREIGHT</span> - вантажний автомобіль</li>
                        <li><span className="font-bold">TRAILER</span> - причіп</li>
                        <li><span className="font-bold">AGRICULTURAL</span> - сільськогосподарська техніка</li>
                        <li><span className="font-bold">SPECIAL</span> - спецтранспорт</li>
                    </ul>
                    <hr/>
                    <CustomTooltip msg="Посилання на сайт EUA відкриється у новій вкладці"><a href="https://api.ewa.ua/#model-AutoModel" target="_blank" rel="noopener noreferrer">Детальніше про тип ТЗ на сайті EUA</a></CustomTooltip>
                </div>
            }
        />
    );
};

export default VehicleTypeInfo;