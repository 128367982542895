import React, {createContext, useContext, useEffect} from "react";
import {useDispatch, useSelector} from "react-redux";
import {useNavigate, useParams, useMatch} from "react-router-dom";
import {v4} from "uuid";
import {setIdToInsuranceStep, setInsuranceStepToId, getIDToInsuranceStep, getInsuranceStepToID} from "../redux-data/steps/stepsReducer";
import {getOfferDocumentServiceSelected} from "../redux-data/offer/offerReducer";
import {isBankIDDocumentServiceSelected, clearAll} from "./utilsGeneral";

const STEPS = 5;

const generateSteps = (steps: number = STEPS) => {
  const stepToId: any = {};
  const idToStep: any = {};

  for (let i = 1; i <= steps; i++) {
      const id = v4();
      stepToId[i] = id;
      idToStep[id] = i;
  }

  return [stepToId, idToStep];
};

export const StepsContext = createContext(null);

export default function StepsProvider({children}: any) {
  const storedStepToID = useSelector(getInsuranceStepToID);
  const idToStoredStep = useSelector(getIDToInsuranceStep);
  const offerDocumentServiceSelected = useSelector(getOfferDocumentServiceSelected);
  const isBankIDDocServiceSelected = isBankIDDocumentServiceSelected(offerDocumentServiceSelected!);
  const navigate = useNavigate();
  const match = useMatch(`/:name/:id`);
  const dispatch = useDispatch();
  const currentStep = useParams<{
    id: string;
    name: string;
  }>();

  useEffect(() => {
    const initializeSteps = () => {
      if (Object.keys(storedStepToID).length > 0 && Object.keys(idToStoredStep).length > 0 && (!isBankIDDocServiceSelected || validateFifthStep(idToStoredStep, currentStep.id))) {
          clearAll(dispatch);
          goTo(1);
          return;
      }

      if (Object.keys(storedStepToID).length === 0 && Object.keys(idToStoredStep).length === 0) {
          const [initialStepToID, initialIDToStep] = generateSteps();
          dispatch(setInsuranceStepToId(initialStepToID));
          dispatch(setIdToInsuranceStep(initialIDToStep));
      }
    }

    initializeSteps();
  }, [dispatch]);

  const getStepByID = (id: any) => {
    return idToStoredStep ? idToStoredStep[id] || 1 : null;
  };

  const getFirst = () => {
    return storedStepToID ? storedStepToID[1] : null;
  };

  const goTo = (step: number) => {
    navigate(`/${match?.params.name}/${storedStepToID[step]}`);
  };

  const navigateToStep = (type: string, step: number) => {
    navigate(`/${type}/${storedStepToID[step]}`);
  };

  const validateFifthStep = (idToPersistedStep, currentStepID) => {
    return idToPersistedStep[currentStepID] === 5;
  };

  const value: any = {
    getStepByID,
    goTo,
    navigateToStep,
    getFirst,
    validateFifthStep
  };

  return <StepsContext.Provider value={value}>{children}</StepsContext.Provider>;
}

export function useStepsContext() {
  return useContext(StepsContext);
}