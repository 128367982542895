import React from "react";
import InfoSpoiler from "../../../components/Forms/common/InfoSpoiler";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";

const VehicleCategoryInfo = (props) => {
    return (
        <InfoSpoiler isDefaultSpoilerTitle={true} spoilerContentVisibility={false} spoilerTitle="Детальніше про категорію ТЗ" 
            spoilerContent={
                <div className="vehicle-cat-details">
                    <p className="font-bold">Категорія ТЗ</p>
                    <p>Допустимі значення:</p>
                    <ul>
                        <li><span className="font-bold">A1</span> - мотоцикл / моторолер - до 300 см3</li>
                        <li><span className="font-bold">A2</span> - мотоцикл / моторолер - більше 300 см3</li>
                        <li><span className="font-bold">B1</span> - легковий автомобіль - до 1600 см3</li>
                        <li><span className="font-bold">B2</span> - легковий автомобіль - 1601 - 2000 см3</li>
                        <li><span className="font-bold">B3</span> - легковий автомобіль - 2001 - 3000</li>
                        <li><span className="font-bold">B4</span> - легковий автомобіль - більше 3000 см3</li>
                        <li><span className="font-bold">B5</span> - легковий електромобіль (виключно з силовим електродвигуном, крім гібридних авто)</li>
                        <li><span className="font-bold">C1</span> - вантажний автомобіль - до 2т</li>
                        <li><span className="font-bold">C2</span> - вантажний автомобіль - більше 2т</li>
                        <li><span className="font-bold">D1</span> - автобус - до 20 людей</li>
                        <li><span className="font-bold">D2</span> - автобус - більше 20 людей</li>
                        <li><span className="font-bold">E</span> - причіп до вантажного авто</li>
                        <li><span className="font-bold">F</span> - причіп до легкового авто</li>
                    </ul>
                    <hr/>
                    <CustomTooltip msg="Посилання на сайт EUA відкриється у новій вкладці"><a href="https://api.ewa.ua/#model-AutoTransferBean" target="_blank" rel="noopener noreferrer">Детальніше про категорію ТЗ на сайті EUA</a></CustomTooltip>
                </div>
            }
        />
    );
};

export default VehicleCategoryInfo;