import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../core/configDBAxios";
import {checkValue} from "../../utils/utilsGeneral";
import CustomLoader from "../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../hooks/dashboard-auth";
import CustomInput from "../../components/MainPage/CustomInput";

const AddDashboardItem = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const [dashboardItem, setDashboardItem] = useState({
        name: "",
        url: ""
    });
    const [dashboardItemError, setDashboardItemError] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const {id, externalID} = useParams<{
        id: string; 
        externalID: string;
    }>();
    const nameInput = useRef<HTMLInputElement>(null);
    const urlInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const handleInputChange = (event) => {
        setDashboardItem((prev) => ({...prev, [event.target.name]: checkValue(event.target.value)}));
    };

    const handleItemSubmit = async (event) => {
        event.preventDefault();

        if (dashboardItem.name.trim().length > 0 && dashboardItem.url.trim().length > 0) {
            try {
                await dbAxiosInstance.post("/dashboard-items", dashboardItem);
                setDashboardItemError("");
                setErrorNotificationHidden(true);
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}`);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setDashboardItemError(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setDashboardItemError("Будь ласка, заповніть усі поля, щоб додати елемент панелі керування!");
            setErrorNotificationHidden(false);
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const clearFields = (event) => {
        event.preventDefault();

        if (nameInput.current) {
            nameInput.current.value = "";
        }

        if (urlInput.current) {
            urlInput.current.value = "";
        }

        setDashboardItem({name: "", url: ""});
    };

    return (
         <div>
            <div className="container">
                <div className="section-white">
                    {authInProgress ?
                       <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                     :
                       <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1>Додати елемент панелі керування</h1>
                            <div className="add-dashboard-item-form">
                                <CustomInput type="text" name="name" label="Назва" placeholder="Назва" onDataChange={handleInputChange} ref={nameInput} />
                                <CustomInput type="url" name="url" label="Посилання" placeholder="Посилання" onDataChange={handleInputChange} ref={urlInput} />
                            </div>
                            <button type="submit" className="submit-btn" onClick={handleItemSubmit}><i className="fa fa-plus notification-icon"></i>Додати</button>
                            <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                        </div>
                        {!hideErrorNotification && dashboardItemError && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{dashboardItemError}</div>}
                      </>
                    }
                </div>
            </div>
        </div>
    );
};

export default AddDashboardItem;