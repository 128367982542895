import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {PromocodeState, PromocodeActions} from "./promocodeTypes";

const initState: PromocodeState = {
  name: "",
  isActivated: false,
  salePointID: 0,
  discountedPayment: 0
};

const creator = new CreatorReducer<PromocodeActions, PromocodeState>("promocode");
creator.addAction("SET_PROMOCODE_NAME",
((state, action) => {
  return {...state, name: action.payload}
}));
creator.addAction("SET_PROMOCODE_IS_ACTIVATED", 
((state, action) => {
  return {...state, isActivated: action.payload}
}));
creator.addAction("SET_PROMOCODE_SALE_POINT_ID",
((state, action) => {
    return {...state, salePointID: action.payload}
}));
creator.addAction("SET_PROMOCODE_DISCOUNTED_PAYMENT",
((state, action) => {
    return {...state, discountedPayment: action.payload}
}));
creator.addAction("PROMOCODE_CLEAR", 
((state, action) => {
  return initState;
}));

const {setPromocodeName, setPromocodeIsActivated, setPromocodeSalePointId, setPromocodeDiscountedPayment, promocodeClear} = creator.createActions();

const getPromocodeName = (state: RootState) => state.promocode.name;

const getPromocodeIsActivated = (state: RootState) => state.promocode.isActivated;

const getPromocodeSalePointID = (state: RootState) => state.promocode.salePointID;

const getPromocodeDiscountedPayment = (state: RootState) => state.promocode.discountedPayment;

export {setPromocodeName, setPromocodeIsActivated, setPromocodeSalePointId, setPromocodeDiscountedPayment, getPromocodeName, getPromocodeIsActivated, getPromocodeSalePointID, getPromocodeDiscountedPayment, promocodeClear};
export default creator.createReducer(initState);