import React, {useState, useEffect} from "react";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import {useNavigate, useParams} from "react-router-dom";
import SwitchComponent from "react-switch";
import {navToGeneralSettings} from "../../../utils/utilsGeneral";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";

const EditGeneralSettingPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const {id, externalID, settingID} = useParams<{
        id: string; 
        externalID: string; 
        settingID: string;
    }>();
    const [settingItemError, setSettingItemError] = useState("");
    const [editGeneralSetting, setGeneralSettingEdited] = useState({
        name: "",
        isActive: false,
        itemID: 0
    });

    useEffect(() => {
        const fetchGeneralSettingItem = async () => {
            try {
                const res = await dbAxiosInstance.get(`/general-setting/${encodeURIComponent(settingID!)}`);
                const dbGeneralSettingData = res.data;

                if (dbGeneralSettingData) {
                    if (dbGeneralSettingData.length > 0) {
                        const [generalSettingItem] = dbGeneralSettingData;
                        setGeneralSettingEdited({name: generalSettingItem.name, isActive: Boolean(generalSettingItem.is_active), itemID: generalSettingItem.item_id});
                        setSettingItemError("");
                    } else {
                        setGeneralSettingEdited({name: "", isActive: false, itemID: 0});
                        setSettingItemError("Немає такого елемента загальних налаштувань. Ви можете додати елемент загальних налаштувань через панель адміністратора.");
                    }
                } else {
                    setGeneralSettingEdited({name: "", isActive: false, itemID: 0});
                    setSettingItemError("Помилка БД! Не вдалося завантажити елемент загальних налаштувань.");
                }
            } catch (error: any) {
                setGeneralSettingEdited({name: "", isActive: false, itemID: 0});
                setSettingItemError("Помилка БД! Не вдалося завантажити елемент загальних налаштувань.");
            }
        };

        fetchGeneralSettingItem();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const clearFields = (event) => {
        setErrorNotificationHidden(true);
        setGeneralSettingEdited({name: "", isActive: false, itemID: 0});
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const handleNameChange = (event) => {
        setGeneralSettingEdited((prev) => ({...prev, name: event.target.value}));
    };

    const handleSettingStateChange = (state) => {
        setGeneralSettingEdited((prev) => ({...prev, isActive: state}));
    };

    const handleElementIDChange = (event) => {
        setGeneralSettingEdited((prev) => ({...prev, itemID: event.target.value}));
    };

    const handleItemSubmit = async (event) => {
        event.preventDefault();

        if (editGeneralSetting.name.trim().length > 0 && editGeneralSetting.itemID > 0) {
            try {
                await dbAxiosInstance.put(`/edit-general-setting/${encodeURIComponent(settingID!)}`, editGeneralSetting);
                setSettingItemError("");
                setErrorNotificationHidden(true);
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/general-settings`);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setSettingItemError(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setSettingItemError("Будь ласка, заповніть усі поля, щоб оновити елемент загальних налаштувань!");
            setErrorNotificationHidden(false);
        }
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                    {authInProgress ?
                        <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                     :
                       <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1>Редагувати елемент загальних налаштувань</h1>
                            <div className="add-dashboard-item-form">
                                <CustomInput type="text" name="name" label="Назва" placeholder="Назва" onDataChange={handleNameChange} value={editGeneralSetting.name} />
                                <div className="switch-wrapper">
                                    <label className="switch-container">
                                        <SwitchComponent onChange={handleSettingStateChange} checked={editGeneralSetting.isActive} onColor="#1BA876" />
                                        <div className="switch-text">Включити налаштування</div>
                                    </label>
                                </div>
                                <CustomInput type="number" name="itemID" label="ID елемента" placeholder="ID елемента" onDataChange={handleElementIDChange} value={editGeneralSetting.itemID.toString()} />
                            </div>
                            <button type="submit" className="submit-btn" onClick={handleItemSubmit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                            <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                            <button type="button" className="admin-btn" onClick={() => navToGeneralSettings(dashboardNavigate, id, externalID)}>Загальні налаштування</button>
                        </div>
                        {!hideErrorNotification && settingItemError && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{settingItemError}</div>}
                      </>
                    }
                </div>
            </div>
        </div>
    );
};

export default EditGeneralSettingPage;