import React, {useState} from "react";
import CustomTooltip from "./CustomTooltip";
import ImageZoomComponent from "./ImageZoomComponent";

interface InfoSpoilerProps {
    isDefaultSpoilerTitle: boolean;
    spoilerContentVisibility: boolean;
    spoilerTitle: string;
    spoilerContent: any;
}

interface ImageZoomProps {
    id: string;
    src: string;
    title: string;
}

const InfoSpoiler = ({isDefaultSpoilerTitle, spoilerContentVisibility, spoilerTitle, spoilerContent}: InfoSpoilerProps) => {
    const [isSpoilerVisible, setSpoilerVisible] = useState(spoilerContentVisibility);

     const parseImagesFromContent = (content: string): ImageZoomProps[] => {
        const imageRegex = /<div\s+class="tooltip-wrapper"[^>]*data-title="([^"]+)"[^>]*data-src="([^"]+)"[^>]*>/g;
        const images: ImageZoomProps[] = [];
        let match: RegExpExecArray | null;
        let index = 0;

        while ((match = imageRegex.exec(content)) !== null) {
            const title = match[1];
            const src = match[2];
            images.push({id: `${index}-${src}`, title, src});
            index++;
        }

        return images;
    };

    const imagesZoomData = parseImagesFromContent(spoilerContent);

    const handleSpoilerState = (event) => {
        setSpoilerVisible(!isSpoilerVisible);
    };

    return (
        <div className="spoiler">
            <CustomTooltip msg="Натисніть, щоб відкрити/закрити">
                <p className={`${isDefaultSpoilerTitle ? "spoiler-title" : "spoiler-title-centered"}`} onClick={handleSpoilerState}><i className={`notification-icon fa-solid ${isSpoilerVisible ? "fa-minus" : "fa-plus"}`}></i>{spoilerTitle}</p>
            </CustomTooltip>
            {isSpoilerVisible && imagesZoomData.length === 0 && (
                typeof spoilerContent === "object" ? <div className="spoiler-content">{spoilerContent}</div> : <div className="spoiler-content" dangerouslySetInnerHTML={{__html: spoilerContent}} />
            )}
            {isSpoilerVisible && imagesZoomData.length > 0 && (
                <div className="spoiler-content">
                    {imagesZoomData.map((imageZoomData) => (
                        <ImageZoomComponent key={imageZoomData.id} content={imageZoomData.src} caption={imageZoomData.title} alt={imageZoomData.title} isLocalImage={false} />
                    ))}
                </div>
            )}
        </div>
    );
};

export default InfoSpoiler;