import React, {useEffect, useState, forwardRef} from "react";
import {CustomInputProps} from "../../typings/CustomInputProps";

const CustomInput = forwardRef<HTMLInputElement, CustomInputProps>(({id, className, name, label, type, value = "", placeholder, isDisabled, isReadOnly, onDataChange, min, max, isAutoFocus}, ref) => {
    const [touch, setTouch] = useState(false);
    const [errorMsg, setErrorMsg] = useState("");
    const [inputValue, setInputValue] = useState("");

    useEffect(() => {
        setInputValue(value);
    }, [value]);

    const handleBlur = (event) => {
        checkInputData(event.target.value);
    };

    const handleChange = (event) => {
        const data = event.target.value;
        setInputValue(data);
        checkInputData(data);
        onDataChange?.(event);
    };

    const checkInputData = (data) => {
        setTouch(true);

        if (data.length === 0) {
            setErrorMsg("Це поле обов'язкове");
        } else {
            setErrorMsg("");
        }
    };

    return (
        <div className={`${errorMsg && touch && !isDisabled && !isReadOnly ? "form-group form-group-error" : !errorMsg && touch && !isDisabled && !isReadOnly ? "form-group form-group-success" : "form-group"}`}>
            {label && <label className="custom-input-label">{label}</label>}
            {type !== "date" && (
                <input
                    ref={ref}
                    id={id}
                    className={className}
                    name={name}
                    type={type}
                    placeholder={placeholder}
                    onBlur={handleBlur}
                    onChange={handleChange}
                    value={inputValue}
                    disabled={isDisabled}
                    readOnly={isReadOnly}
                    min={min}
                    max={max}
                    autoFocus={isAutoFocus}
                />
            )}
            {errorMsg && !isDisabled && !isReadOnly && <p>{errorMsg}</p>}
        </div>
    );
});

export default CustomInput;