import {RootState} from "../index";
import {CreatorReducer} from "../base/base";
import {IUserActions, IUserInfoState} from "./userType";

const initState: IUserInfoState = {
  data: null,
  currentUser: null,
  isLoading: false,
  error: false,
  privilegeType: null,
  errorMsg: ""
};

const creator = new CreatorReducer<IUserActions, IUserInfoState>("user");
creator.addAction("SET_PRIVILEGE_TYPE",
(state, action) => {
  return {...state, privilegeType: action.payload}
});
creator.addAction("SET_CURRENT_USER",
(state, action) => {
  return {...state, currentUser: action.payload}
});
creator.addAction("SET_USER_LOGIN_ERROR_MSG",
(state, action) => {
  return {...state, errorMsg: action.payload}
});
creator.addAction("CLEAR_USER_PRIVILEGE_TYPE", 
  (state, action) => {
    return {...state, privilegeType: null};
});
creator.addAction("CLEAR_USER_INFO",
  (state) => {
    return {...initState};
});

const {setError, setData, setCurrentUser, setLoading, setPrivilegeType, setUserLoginErrorMsg, clearUserPrivilegeType, clearUserInfo} = creator.createActions();

const getCurrentUser = (state: RootState) => {
  return state.user.currentUser;
};

const getCurrentUserInfo = (state: RootState) => {
  return state.user.data;
};

const isUserLoginError = (state: RootState) => {
  return state.user.error;
};

const getUserLoginErrorMsg = (state: RootState) => {
  return state.user.errorMsg;
};

export {setData, setError, setPrivilegeType, setLoading, setCurrentUser, getCurrentUser, getCurrentUserInfo, setUserLoginErrorMsg, isUserLoginError, getUserLoginErrorMsg, clearUserPrivilegeType, clearUserInfo};
export default creator.createReducerFetch(initState);