import React, {useEffect, useState, useRef} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {checkValue} from "../../../utils/utilsGeneral";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import useDashboardAuth from "../../../hooks/dashboard-auth";
import CustomInput from "../../../components/MainPage/CustomInput";

const EditSocialItem = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const [editSocialItemData, setSocialItemDataEdited] = useState({
        name: "",
        url: "",
        iconName: ""
    });
    const [socialItemError, setSocialItemError] = useState("");
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const {id, externalID, itemID} = useParams<{
        id: string;
        externalID: string;
        itemID: string;
    }>(); 
    const editNameInput = useRef<HTMLInputElement>(null);
    const editUrlInput = useRef<HTMLInputElement>(null);
    const editIconNameInput = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const fetchSocialItem = async () => {
            try {
                const res = await dbAxiosInstance.get(`/social-items/${encodeURIComponent(itemID!)}`);
                const dbSocialData = res.data;

                if (dbSocialData) {
                    if (dbSocialData.length > 0) {
                        const [socialItem] = dbSocialData;
                        setSocialItemDataEdited({name: socialItem.name, url: socialItem.url, iconName: socialItem.icon_name});
                        setSocialItemError("");
                    } else {
                        setSocialItemDataEdited({name: "", url: "", iconName: ""});
                        setSocialItemError("Немає такої соціальної мережі. Ви можете додати соц. мережу через панель адміністратора.");
                    }
                } else {
                    setSocialItemDataEdited({name: "", url: "", iconName: ""});
                    setSocialItemError("Помилка БД! Не вдалося завантажити соціальну мережу.");
                }
            } catch (error: any) {
                setSocialItemDataEdited({name: "", url: "", iconName: ""});
                setSocialItemError("Помилка БД! Не вдалося завантажити соціальну мережу.");
            }
        };

        fetchSocialItem();
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification]);

    const handleEditInputChange = (event) => {
        setSocialItemDataEdited((prev) => ({...prev, [event.target.name]: checkValue(event.target.value)}));
    };

    const handleItemEdit = async (event) => {
        event.preventDefault();

        if (editSocialItemData.name.trim().length > 0 && editSocialItemData.url.trim().length > 0 && editSocialItemData.iconName.trim().length > 0) {
            try {
                await dbAxiosInstance.put(`/social-items/${encodeURIComponent(itemID!)}`, editSocialItemData);
                setSocialItemError("");
                setErrorNotificationHidden(true);
                dashboardNavigate(`/admin/dashboard/${id}/${externalID}/socials`);
            } catch (error: any) {
                const userDataError = error.toJSON();
                setSocialItemError(error.response!.data!.message || userDataError.message || "Unknown error");
                setErrorNotificationHidden(false);
            }
        } else {
            setSocialItemError("Будь ласка, заповніть усі поля, щоб оновити соціальну мережу!");
            setErrorNotificationHidden(false);
        }
    };

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const clearFields = (event) => {
        event.preventDefault();
        setSocialItemDataEdited({name: "", url: "", iconName: ""});

        if (editNameInput.current) {
            editNameInput.current.value = "";
        }

        if (editUrlInput.current) {
            editUrlInput.current.value = "";
        }

        if (editIconNameInput.current) {
            editIconNameInput.current.value = "";
        }
    };

    return (
         <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1>Редагувати соціальну мережу</h1>
                        <div className="edit-dashboard-item-form">
                            <CustomInput type="text" name="name" label="Відредагувати назву" placeholder="Відредагувати назву" value={editSocialItemData.name} onDataChange={handleEditInputChange} ref={editNameInput} />
                            <CustomInput type="url" name="url" label="Відредагувати посилання" placeholder="Відредагувати посилання" value={editSocialItemData.url} onDataChange={handleEditInputChange} ref={editUrlInput} />
                            <CustomInput type="text" name="iconName" label="Відредагувати назву зображення" placeholder="Відредагувати назву зображення" value={editSocialItemData.iconName} onDataChange={handleEditInputChange} ref={editIconNameInput} />
                        </div>
                        <button type="submit" className="edit-btn" onClick={handleItemEdit}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                        <button type="reset" className="reset-btn" onClick={clearFields}><i className="fa-sharp fa-solid fa-xmark notification-icon"></i>Очистити</button>
                    </div>
                    {!hideErrorNotification && socialItemError && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{socialItemError}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default EditSocialItem;