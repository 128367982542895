import React, {useEffect, useState} from "react";
import {useNavigate, useParams, Link} from "react-router-dom";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {BankIDAnalytics, BankIDAnalyticsEvent} from "../../../typings/IBankID";
import AnalyticsChartComponent from "../../../utils/AnalyticsChartComponent";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const AnalyticsBankIDPage = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const [analytics, setAnalytics] = useState<BankIDAnalytics[]>([]);
    const [analyticsErrorMsg, setAnalyticsErrorMsg] = useState("");

    useEffect(() => {
        const fetchAnalytics = async () => {
            try {
                const res = await dbAxiosInstance.get("/admin/bankid/analytics");
                const dbAnalyticsData = res.data;

                if (dbAnalyticsData) {
                    if (dbAnalyticsData.length > 0) {
                        setAnalytics(dbAnalyticsData);
                        setAnalyticsErrorMsg("");
                    } else {
                        setAnalytics([]);
                        setAnalyticsErrorMsg("Немає доданих даних BankID аналітики.");
                    }
                } else {
                    setAnalytics([]);
                    setAnalyticsErrorMsg("Помилка БД! Не вдалося завантажити дані BankID аналітики.");
                }
            } catch (error: any) {
                setAnalytics([]);
                setAnalyticsErrorMsg("Помилка БД! Не вдалося завантажити дані BankID аналітики.");
            }
        };

        fetchAnalytics();
    }, []);

    const chartDatasets = [
        {
            label: "Відвідування сторінки",
            key: BankIDAnalyticsEvent.PageVisits,
            backgroundColor: "rgba(206, 126, 0, 0.5)"
        },
        {
            label: "Успішні запити",
            key: BankIDAnalyticsEvent.SuccessRequests,
            backgroundColor: "rgba(33, 139, 101, 0.5)"
        },
        {
            label: "Невдалі запити",
            key: BankIDAnalyticsEvent.FailedRequests,
            backgroundColor: "rgba(255, 0, 0, 0.5)"
        }
    ];

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                    :
                    <>
                        <div className="back-icon">
                            <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                        </div>
                        <div className="dashboard-container">
                            <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo;&nbsp; 
                            <CustomTooltip msg="Перейти до Аналітики"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}/analytics`}}>Аналітика</Link></CustomTooltip> &laquo; БанкІД</h1>
                            {analytics.length > 0 ?
                                <AnalyticsChartComponent analytics={analytics} datasets={chartDatasets} mainText="Використання BankID" scalesXText="Місяць" scalesYText="Кількість" legendPosition="top" />
                            :
                                <div className="no-dashboard-item">{analyticsErrorMsg}</div>
                            }
                        </div>
                    </>
                }
                </div>
            </div>
        </div>
    );
};

export default AnalyticsBankIDPage;