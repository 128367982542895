import React, {useEffect, useState} from "react";
import {navToAdminPanel, refreshPage, navToEpolicyCompaniesPage, navToGreencardCompaniesPage, filterRecords, countRecords} from "../../../utils/utilsGeneral";
import {useNavigate, useParams, Link} from "react-router-dom";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {dbAxiosInstance} from "../../../core/configDBAxios";
import {companyImage, companyImages} from "../../../constants/companyImages";
import {IEpolicyCompany, IEpolicyFranchise} from "../../../typings/IDataOffers";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const ViewEpolicyCompany = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID, productID, epolicyID} = useParams<{
        id: string; 
        externalID: string; 
        productID: string; 
        epolicyID: string;
    }>();
    const [epolicyCompanyData, setEpolicyCompanyData] = useState<IEpolicyCompany[]>([]);
    const [epolicyCompanyErrorMsg, setEpolicyCompanyErrorMsg] = useState("");
    const [epolicyFranchisesData, setEpolicyFranchisesData] = useState<IEpolicyFranchise[]>([]);
    const [epolicyFranchisesErrorMsg, setEpolicyFranchisesErrorMsg] = useState("");
    const [epolicyFranchisesCount, setEpolicyFranchisesCount] = useState(0);
    const [hiddenState, setHiddenState] = useState<any[]>([]);
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);

    useEffect(() => {
        const fetchEpolicyCompany = async () => {
            try {
                const res = await dbAxiosInstance.get(`/epolicy/company/${encodeURIComponent(productID!)}/${encodeURIComponent(epolicyID!)}`);
                const dbEpolicyData = res.data;

                if (dbEpolicyData) {
                    if (dbEpolicyData.length > 0) {
                        const epolicyCompanies: IEpolicyCompany[] = dbEpolicyData.map((item) => {
                            return {
                                id: item.id, 
                                name: item.name, 
                                isHidden: Boolean(item.is_hidden), 
                                productID: item.product_id
                            };
                        });
                        setEpolicyCompanyData(epolicyCompanies);
                        setEpolicyCompanyErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setEpolicyCompanyData([]);
                        setEpolicyCompanyErrorMsg("Немає такої страхової компанії автоцивілки.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setEpolicyCompanyData([]);
                    setEpolicyCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію автоцивілки.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setEpolicyCompanyData([]);
                setEpolicyCompanyErrorMsg("Помилка БД! Не вдалося завантажити страхову компанію автоцивілки.");
                setErrorNotificationHidden(false);
            }
        };

        const fetchEpolicyFranchises = async () => {
            try {
                const res = await dbAxiosInstance.get(`/epolicy/fetch-franchises/${encodeURIComponent(productID!)}`);
                const dbEpolicyFranchisesData = res.data;

                if (dbEpolicyFranchisesData) {
                    if (dbEpolicyFranchisesData.length > 0) {
                        const epolicyFranchises: IEpolicyFranchise[] = dbEpolicyFranchisesData.map((item) => {
                            return {
                                id: item.id, 
                                companyName: item.company_name,
                                franchiseName: item.franchise_name,
                                productID: item.product_id,
                                franchise: item.franchise,
                                franchiseID: item.franchise_id,
                                isHidden: Boolean(item.is_hidden)
                            };
                        });
                        setEpolicyFranchisesData(epolicyFranchises);
                        const isFranchiseHidden = epolicyFranchises.map((item, index) => {
                            return item.isHidden;
                        });
                        setHiddenState(isFranchiseHidden);
                        setEpolicyFranchisesErrorMsg("");
                        setErrorNotificationHidden(true);
                    } else {
                        setEpolicyFranchisesData([]);
                        setEpolicyFranchisesErrorMsg("Немає франшиз для такої страхової компанії автоцивілки.");
                        setErrorNotificationHidden(false);
                    }
                } else {
                    setEpolicyFranchisesData([]);
                    setEpolicyFranchisesErrorMsg("Помилка БД! Не вдалося завантажити франшизи для страхової компанії автоцивілки.");
                    setErrorNotificationHidden(false);
                }
            } catch (error: any) {
                setEpolicyFranchisesData([]);
                setEpolicyFranchisesErrorMsg("Помилка БД! Не вдалося завантажити франшизи для страхової компанії автоцивілки.");
                setErrorNotificationHidden(false);
            }
        };

        const countFranchisesByProductID = async () => {
            try {
                const res = await dbAxiosInstance.get(`/epolicy/count-franchises/${encodeURIComponent(productID!)}`);
                const dbCountEpolicyFranchsiesData = res.data;

                if (dbCountEpolicyFranchsiesData) {
                    if (dbCountEpolicyFranchsiesData.length > 0) {
                        setEpolicyFranchisesCount(dbCountEpolicyFranchsiesData[0].counter);
                    } else {
                        setEpolicyFranchisesCount(0);
                    }
                } else {
                    setEpolicyFranchisesCount(0);
                }
            } catch (error: any) {
                setEpolicyFranchisesCount(0);
            }
        };
        
        fetchEpolicyCompany();
        fetchEpolicyFranchises();
        countFranchisesByProductID();
    }, []);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
    };

    const editEpolicyCompany = () => {
        dashboardNavigate(`/admin/dashboard/${id}/${externalID}/epolicy/edit-company/${productID}/${epolicyID}`);
    };

    const deleteEpolicyCompany = async (prodID, id) => {
        try {
            await dbAxiosInstance.delete(`/epolicy/company/franchises/${encodeURIComponent(prodID!)}`);
            await dbAxiosInstance.delete(`/epolicy/company/${encodeURIComponent(id)}`);
            dashboardNavigate(`/admin/dashboard/${id}/${externalID}/epolicy`);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setEpolicyCompanyErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setEpolicyCompanyData([]);
        }
    };

    const deleteEpolicyFranchise = async (prodID, id) => {
        try {
            await dbAxiosInstance.delete(`/epolicy/franchise/${encodeURIComponent(prodID)}/${encodeURIComponent(id)}`);
            setEpolicyFranchisesData(filterRecords(epolicyFranchisesData, id));
            const allRecords = countRecords(epolicyFranchisesCount);
            setEpolicyFranchisesCount(allRecords);

            if (allRecords === 0) {
                setEpolicyFranchisesErrorMsg("Немає доданих франшиз страхового продукта автоцивілки. Натисніть на кнопку придбати автоцивілку і завантажте пропозиції, після чого франшизи страхового продукту автоцивілки будуть доступні в панелі адміністратора.");
            }
        } catch (error: any) {
            const userDataError = error.toJSON();
            setEpolicyFranchisesErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setEpolicyFranchisesData([]);
        }
    };

    const handleFranchiseVisibility = async (position, prodID, id) => {
        try {
            const updatedHiddenState = hiddenState.map((item, index) => {
                return index === position ? !item : item;
            });
            await dbAxiosInstance.put(`/epolicy/edit-franchise-visibility/${encodeURIComponent(prodID)}/${encodeURIComponent(id)}`, {isHidden: updatedHiddenState[position]});
            setHiddenState(updatedHiddenState);
        } catch (error: any) {
            const userDataError = error.toJSON();
            setEpolicyFranchisesErrorMsg(error.response!.data!.message || userDataError.message || "Unknown error");
            setEpolicyFranchisesData([]);
        }
    };

    const epolicyCompanyImg = companyImages.filter((company: companyImage) => epolicyCompanyData.find((item) => company.id === item.productID));

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        {epolicyCompanyData.length > 0 && epolicyCompanyData.map((epolicyCompany) => (
                            <div className="company-info-container" key={epolicyCompany.id}>
                                <h1 className="company-title">Страхова компанія &#8212; {epolicyCompany.name}</h1>
                                <div className="company-logo">
                                    <img src={epolicyCompanyImg.length > 0 ? require(`../../../assets/img/logos/${epolicyCompanyImg[0].key}`).default : "\\img\\base.webp"} alt="" />
                                </div>
                                <p className="item-title">Назва: <span className="font-bold">{epolicyCompany.name}</span></p>
                                <p className="item-title">Ідентифікатор продукту: <span className="font-bold">{epolicyCompany.productID}</span></p>
                                <p className="item-title">Є прихованою: <span className="font-bold">{epolicyCompany.isHidden ? <span className="company-hidden">Так</span> : <span className="company-visible">Ні</span>}</span></p>
                                <p className="item-title">Всього франшиз: <span className="font-bold">{epolicyFranchisesCount}</span></p>
                                <div className="epolicy-franchises-wrapper">
                                    <p className="epolicy-franchises-title">Франшизи:</p>
                                    <ul className="epolicy-franchise-items">
                                        {epolicyFranchisesCount > 0 ? epolicyFranchisesData.map((epolicyFranchise, index) =>
                                            <li key={index}>
                                                <div className="manage-item-wrapper">
                                                    <div className="manage-item-container">
                                                        <CustomTooltip msg="Редагувати">
                                                            <Link className="edit-item" to={{pathname: `/admin/dashboard/${id}/${externalID}/epolicy/company/edit-franchise/${epolicyID}/${epolicyFranchise.productID}/${epolicyFranchise.id}`}}>
                                                                <i className="fa fa-pencil-alt"></i>
                                                            </Link>
                                                        </CustomTooltip>
                                                        <CustomTooltip msg={`${hiddenState[index] ? "Показати" : "Приховати"}`}><a className="hide-item" onClick={() => handleFranchiseVisibility(index, epolicyFranchise.productID, epolicyFranchise.id)}><i className={`fa-solid ${hiddenState[index] ? "fa-eye-slash" : "fa-eye"}`}></i></a></CustomTooltip>
                                                        <CustomTooltip msg="Видалити"><a className="delete-item" onClick={() => deleteEpolicyFranchise(epolicyFranchise.productID, epolicyFranchise.id)}><i className="fa fa-trash"></i></a></CustomTooltip>
                                                    </div>
                                                </div>
                                                <div className="dashboard-item">                     
                                                    <Link to={{pathname: `/admin/dashboard/${id}/${externalID}/epolicy/company/view-franchise/${epolicyID}/${epolicyFranchise.productID}/${epolicyFranchise.id}`}} key={epolicyFranchise.id}>{`Франшиза ${epolicyFranchise.franchise} грн (${epolicyFranchise.franchiseID})`}</Link>
                                                </div>
                                            </li>) : <li><div className="dashboard-error">{epolicyFranchisesErrorMsg}</div></li>
                                        }
                                    </ul>
                                </div>
                            </div>
                        ))}
                        <div className="manage-company">
                            <button type="submit" className="admin-btn" onClick={editEpolicyCompany}><i className="fa-regular fa-pen notification-icon"></i>Редагувати</button>
                            <button type="button" className="admin-btn" onClick={() => deleteEpolicyCompany(productID, epolicyID)}><i className="fa fa-trash notification-icon"></i>Видалити</button>
                            <button type="button" className="admin-btn" onClick={refreshPage}><i className="fa-regular fa-arrows-rotate notification-icon"></i>Оновити</button>
                            <button type="button" className="admin-btn" onClick={() => navToEpolicyCompaniesPage(dashboardNavigate, id, externalID)}>Автоцивілка</button>
                            <button type="button" className="admin-btn" onClick={() => navToGreencardCompaniesPage(dashboardNavigate, id, externalID)}>Зелена карта</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                        </div>
                    </div>
                    {!hideErrorNotification && epolicyCompanyErrorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{epolicyCompanyErrorMsg}</div>}
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default ViewEpolicyCompany;