import React, {useEffect, useState} from "react";
import {Link, useNavigate, useLocation, useParams} from "react-router-dom";
import {navToAdminPanel, navToNewletter} from "../../../utils/utilsGeneral";
import _ from "lodash";
import CustomLoader from "../../../components/Forms/common/CustomLoader";
import CustomTooltip from "../../../components/Forms/common/CustomTooltip";
import {InsuranceLocationState} from "../../../typings/InsuranceLocationState";
import useDashboardAuth from "../../../hooks/dashboard-auth";

const PolicyNewsletterSent = (props) => {
    const dashboardNavigate = useNavigate();
    const authInProgress = useDashboardAuth(dashboardNavigate);
    const {id, externalID} = useParams<{
        id: string;
        externalID: string;
    }>();
    const newsletterLocation = useLocation();
    const {isSuccess, successMsg, errorMsg} = newsletterLocation.state as InsuranceLocationState;
    const [hideErrorNotification, setErrorNotificationHidden] = useState(false);
    const [hideSuccessNotification, setSuccessNotificationHidden] = useState(false);

    useEffect(() => {
        if (isSuccess) {
            setSuccessNotificationHidden(false);
            setErrorNotificationHidden(true);
        } else {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(false);
        }
    }, []);

    useEffect(() => {
        const errorNotifTimer = setTimeout(() => {
            setSuccessNotificationHidden(true);
            setErrorNotificationHidden(true);
        }, 5000);

        return () => clearTimeout(errorNotifTimer);
    }, [hideErrorNotification, hideSuccessNotification]);

    const closeNotificationMsg = () => {
        setErrorNotificationHidden(true);
        setSuccessNotificationHidden(true);
    };

    return (
        <div>
            <div className="container">
                <div className="section-white">
                {authInProgress ?
                    <CustomLoader className="auth-request" isSquare={false} isInfoOn={true} isLoading={authInProgress} infoMsg="Триває перевірка авторизації" />
                 :
                   <>
                    <div className="back-icon">
                        <CustomTooltip msg="Назад"><a onClick={() => dashboardNavigate(-1)}><i className="fa-regular fa-arrow-left"></i></a></CustomTooltip>
                    </div>
                    <div className="dashboard-container">
                        <h1><CustomTooltip msg="Перейти на Панель керування"><Link to={{pathname: `/admin/dashboard/${id}/${externalID}`}}><i className="fa-solid fa-house-chimney-user notification-icon"></i>Панель керування</Link></CustomTooltip> &laquo; Розсилка новин</h1>
                        {!hideSuccessNotification && successMsg && <div className="admin-success-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{successMsg}</div>}
                        {!hideErrorNotification && errorMsg && <div className="admin-error-notification"><CustomTooltip msg="Закрити"><i className="fa-solid fa-xmark close-icon" onClick={closeNotificationMsg}></i></CustomTooltip>{errorMsg}</div>}
                        <div>
                            <button type="button" className="admin-btn" onClick={() => navToNewletter(dashboardNavigate, id, externalID)}>Розсилка новин</button>
                            <button type="button" className="admin-btn" onClick={() => navToAdminPanel(dashboardNavigate, id, externalID)}>Панель адміністратора</button>
                        </div>
                    </div>
                   </>
                }
                </div>
            </div>
        </div>
    );
};

export default PolicyNewsletterSent;