import React from "react";
import SquareLoader from "react-spinners/SquareLoader";
import ScaleLoader from "react-spinners/ScaleLoader";
import {SerializedStyles} from "@emotion/react";

interface CustomLoaderProps {
    className?: string;
    isSquare: boolean;
    isInfoOn: boolean;
    infoMsg?: string;
    isLoading?: boolean;
    color?: string;
    css?: string | SerializedStyles;
}

const CustomLoader = ({className = "", isSquare, isInfoOn, infoMsg = "", isLoading, color = "", css = ""}: CustomLoaderProps) => {
    const options = {
        height: 20,
        width: 20,
        radius: 0,
        margin: 10,
        color: "#1BA876",
    };

    return (
        <div className={className}>
            {isInfoOn && <p>{infoMsg}</p>}
            <>
                {isSquare ? <SquareLoader color={color ? color : options.color} size={options.width} loading={isLoading} css={css} /> : <ScaleLoader loading={isLoading} {...options} />}
            </>
        </div>
    );
};

export default CustomLoader;